import { ConnectApiGet, urls } from '../functions/connection';

export const getAllTournament = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_TOURNAMENT_AREA);
  return respostaServico.data;
};

export const getTournament = async (idTourney: string) => {
  const url = urls.URL_TOURNAMENT_ID.replace('{idTourney}', `${idTourney}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
