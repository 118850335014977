import { Statistics, TourneyStatistics } from '../../../shared/modals/statistics/Statistics';

export enum StatisticsTypes {
  SET_STATISTICS = '@statistics/SET_STATISTICS',
  SET_BEST_TOURNEYS = '@statistics/SET_BEST_TOURNEYS',
  SET_WORSE_TOURNEYS = '@statistics/SET_WORSE_TOURNEYS',
}

export interface StatisticsState {
  readonly statistics?: Statistics;
  readonly bestTourneys: TourneyStatistics[];
  readonly worseTourneys: TourneyStatistics[];
}
