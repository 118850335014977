import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootStateGlobal } from '../../store/reducer';
import * as journeysService from '../../shared/services/journeys';
import { actions as geralActions } from '../../store/reducers/geral';
import { LibraryContainer } from './styles';
import { MenuUrl } from '../../shared/enums/menuUrl';
import ContainerPage from '../../shared/components/containerPage/ContainerPage';
import LibraryCard from '../../shared/components/card/libraryCard/LibraryCard';
import { JourneyInterface } from '../journey/Journey';
import { t } from '../../i18n';

const LibraryList = () => {
  const dispatch = useDispatch();
  const setBlockScreen = async (x: boolean) => dispatch(geralActions.setBlockScreen(x));
  const [journeys, setJourneys] = useState<JourneyInterface[]>([]);
  const { blockScreen } = useSelector((state: RootStateGlobal) => state.geralReducer);

  useEffect(() => {
    (async () => {
      setBlockScreen(true);
      setJourneys(await journeysService.getAll({ onlyCompleted: true }));
      setBlockScreen(false);
    })();
  }, []);

  const renderCardRows = () => {
    if (!journeys || journeys.length <= 0) {
      return null;
    }

    return (
      <LibraryContainer>
        {journeys.map(journey => (
          <LibraryCard journey={journey} key={journey.id} />
        ))}
      </LibraryContainer>
    );
  };

  return (
    <ContainerPage
      title={t('library')}
      subText={t('subtextLibrary')}
      menuUrl={MenuUrl.home}
      blockScreen={blockScreen}
      listLength={journeys.length}
      notFoundTitle={t('noLibraryAvailable')}
      renderList={renderCardRows()}
    />
  );
};

export default LibraryList;
