import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { MenuUrl } from '../../../enums/menuUrl';
import { Container, TopData, LogoData, Name } from './styles';
import { useCustomStyle } from '../../../functions/hooks';
import { JourneyInterface } from '../../../../features/journey/Journey';
import { actions as geralActions } from '../../../../store/reducers/geral';

import { ModalData } from '../../../modals/geral/geral';
import ModalRules from './modalRules/ModalRules';
import { unifyAvailablePresentations } from '../../../functions/library';
import { Presentation } from '../../../modals/presentation/Presentation';
import Button from '../../buttons/Button';
import { colors } from '../../../functions/colors';
import { t } from '../../../../i18n';

interface PLibraryCard {
  journey: JourneyInterface;
}

const LibraryCard = ({ journey }: PLibraryCard) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { textColor, primaryColor } = useCustomStyle();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));

  const handleOpenNoContentModal = () => {
    setModalData({
      show: true,
      children: <ModalRules />,
      height: '30%',
      title: 'Jornada sem conteúdo',
    });
  };

  const handleRedirectToPresentations = (availablePresentations: Presentation[]) => {
    history.push(`${MenuUrl.presentation}/`, {
      gameable: { presentations: availablePresentations },
      isInLibraryContext: true,
    });
  };

  const handleOpenPresentations = async () => {
    const availablePresentations = await unifyAvailablePresentations(journey.id);

    if (availablePresentations.length > 0) {
      handleRedirectToPresentations(availablePresentations);
    } else {
      handleOpenNoContentModal();
    }
  };

  return (
    <Container style={{ marginBottom: '10px' }}>
      <LogoData source={journey.image} />
      <TopData>
        <Name style={textColor}>{journey.name}</Name>
      </TopData>
      <Button
        style={{
          fontSize: '15px',
          fontWeight: '600',
          height: 42,
          backgroundColor: primaryColor.color || colors.purpleRegular,
          margin: '8px',
          maxWidth: '90%',
        }}
        onClick={() => handleOpenPresentations()}
      >
        {t('study')}
      </Button>
    </Container>
  );
};

export default LibraryCard;
