import React, { ButtonHTMLAttributes, ReactNode, FC } from 'react';
import ReactLoading from 'react-loading';
import { CSSProperties } from 'styled-components';

import { colors } from '../../functions/colors';
import { useCustomStyle } from '../../functions/hooks';
import ArrowRight from '../../icons/arrows/arrowRight';
import AwardIcon2 from '../../icons/AwardIcon2';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import ExitIcon from '../../icons/ExitIcon';
import IconBlock from '../../icons/iconBlock';
import PlayIcon from '../../icons/PlayIcon';
import { Container, BoxIcon, BoxIconLeft, TextIcon } from './styles';

interface OwnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  iconLeft?: string;
  loading?: boolean;
  typeButton?: string;
  children: ReactNode;
  style?: CSSProperties;
  customBackground?: string;
  customColor?: string;
}

const Button: FC<OwnProps> = ({
  children,
  icon,
  iconLeft,
  loading,
  typeButton,
  customBackground,
  style,
  ...props
}: OwnProps) => {
  const { textColor } = useCustomStyle();

  const renderIconRight = () => {
    if (loading) {
      return (
        <BoxIcon>
          <ReactLoading
            type="bubbles"
            color={textColor.color || colors.white}
            width={44}
            height={40}
          />
        </BoxIcon>
      );
    }
    switch (icon) {
      case 'arrowRight':
        return (
          <BoxIcon>
            <ArrowRight color={textColor.color || colors.white} />
          </BoxIcon>
        );
      case 'chevronRight':
        return <ChevronRightIcon color={textColor.color || colors.white} />;
      case 'chevronTop':
        return (
          <ChevronRightIcon
            style={{ transform: 'rotate(-90deg)' }}
            color={textColor.color || colors.white}
          />
        );
      case 'chevronBottom':
        return (
          <ChevronRightIcon
            style={{ transform: 'rotate(90deg)' }}
            color={textColor.color || colors.white}
          />
        );
      default:
        return <TextIcon style={{ color: textColor.color || colors.white }}>{icon}</TextIcon>;
    }
  };

  const renderIconLeft = () => {
    switch (iconLeft) {
      case 'play':
        return (
          <BoxIconLeft>
            <PlayIcon color={textColor.color || colors.white} />
          </BoxIconLeft>
        );
      case 'award':
        return (
          <BoxIconLeft>
            <AwardIcon2 color={textColor.color || colors.white} />
          </BoxIconLeft>
        );
      case 'exit':
        return (
          <BoxIconLeft>
            <ExitIcon color={textColor.color || colors.white} />
          </BoxIconLeft>
        );
      case 'block':
        return (
          <BoxIconLeft>
            <IconBlock />
          </BoxIconLeft>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      customBackground={customBackground}
      typeButton={typeButton}
      {...props}
      style={style}
      customColor={textColor.color}
    >
      {renderIconLeft()}
      {!loading ? children : null}
      {renderIconRight()}
    </Container>
  );
};

Button.defaultProps = {
  icon: '',
  iconLeft: '',
  loading: false,
  typeButton: 'primary',
  customBackground: '',
  customColor: '',
  style: {},
};

export default Button;
