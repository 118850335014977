import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div``;

export const Description = styled.p`
  margin: 24px 0px;
  font-family: ${fonts.regular};
  color: ${colors.black};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const BoxInfoCenter = styled.div`
  margin: auto;
  display: flex;
  width: 180px;
  justify-content: space-between;
`;

export const InfoCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${colors.black};
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
  }
`;

export const ContainerMoreInfo = styled.div`
  margin: 24px 0px;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      margin-left: 8px;
      color: ${colors.black};
      font-family: ${fonts.regular};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
