import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useRollbarPerson } from '@rollbar/react';
import { pick } from 'lodash';
import Menu from '../../shared/components/menu/Menu';
import { actions as geralAction } from '../../store/reducers/geral';
import { isLogged } from '../../shared/functions/connection/auth';
import { operations as operationsUser } from '../../store/reducers/user';
import { Container, BoxMenuMain, Main } from './styles';
import { RootStateGlobal } from '../../store/reducer';
import { User } from '../../shared/modals/user/user';
import * as datadog from '../../shared/functions/datadog';
import { CompanyPreferenceStylesOperations } from '../../store/reducers/companyPreferenceStyles';
import { useCustomStyle } from '../../shared/functions/hooks';
import { actionAnalitycs } from '../../shared/functions/analytics';
import FooterMenu from '../../shared/components/menu/footerMenu/FooterMenu';
import Tutorial from '../../shared/components/tutorial/Tutorial';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { SubdomainContext } from '../../App';
import { t } from '../../i18n';
import { Box } from '../../shared/components/styles/styledBox';

interface PLogged {
  children: ReactNode;
  showMenu?: boolean;
  whiteScreen?: boolean;
}

const Logged = ({ children, showMenu, whiteScreen }: PLogged) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setBlockScreen = (x: boolean) => dispatch(geralAction.setBlockScreen(x));
  const { wallet } = useSelector((state: RootStateGlobal) => state.userReducer);
  const userData: User = useSelector((state: RootStateOrAny) => state.userReducer.userData);
  const { slug } = useContext(SubdomainContext);
  const getUserData = async () => dispatch(operationsUser.getUserData({ subdomain: slug }));
  const getWallet = async () => dispatch(operationsUser.getUserWallet());
  const getCompanyPreferenceStyles = async () =>
    dispatch(CompanyPreferenceStylesOperations.getCompanyStyle({}));
  const { backgroundPrimary } = useCustomStyle();
  const { lastSeasonResult } = useSelector((state: RootStateGlobal) => state.leagueReducer);
  const [haveLateralMenu, setHaveLateralMenu] = useState(window.innerWidth >= 900);

  useRollbarPerson({ id: userData?.idUser, email: userData?.email });

  useEffect(() => {
    const handleResize = () => {
      setHaveLateralMenu(window.innerWidth >= 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (isLogged()) {
        if (!userData) {
          setBlockScreen(true);
          await getUserData();
          setBlockScreen(false);
        }
        if (!wallet) {
          getWallet();
        }
        await getCompanyPreferenceStyles();
        return;
      }
      history.push('/');
    })();
  }, []);

  useEffect(() => {
    actionAnalitycs('', '', history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    if (userData) {
      datadog.setUser(userData);

      // @ts-expect-error: hj is accessible through `window` variable and was
      // setup by Hotjar integration within Google Tag Manager.
      if (typeof hj !== 'undefined') {
        // @ts-expect-error: TS doesn't understand the check above and thus
        // keeps complaining.
        hj('identify', userData.idUser, {
          ...pick(userData, ['name', 'email', 'cpf', 'whatsapp', 'createdAt']),
          userType: userData.typeUser.name,
          companies: userData.companies.map(company => company.name),
          source: userData.memberGetMember?.title || t('manualSignup'),
        });
      }
      if (lastSeasonResult?.newLeague && userData.experiments.leagues) {
        history.push(MenuUrl.seasonResult);
      }
    }
  }, [userData]);

  if (!userData) return null;

  return (
    <>
      <Container>
        <BoxMenuMain>
          {showMenu && <FooterMenu />}
          {showMenu && <Menu />}
          <Main style={whiteScreen ? {} : backgroundPrimary} whiteScreen={!!whiteScreen}>
            <Box
              style={{
                marginBottom: 85,
                marginLeft:
                  haveLateralMenu &&
                  !(
                    window.location.pathname.includes('/profile') ||
                    window.location.pathname.includes('/tournament')
                  )
                    ? 220
                    : 0,
                width: window.innerWidth >= 900 ? 'calc(100% - 220px)' : '100%',
              }}
            >
              {children}
            </Box>
          </Main>
        </BoxMenuMain>
      </Container>
      <Tutorial />
    </>
  );
};

Logged.defaultProps = {
  showMenu: true,
  whiteScreen: false,
};

export default Logged;
