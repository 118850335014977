import React from 'react';
import { MdFileCopy } from 'react-icons/md';
import { ShortIdWrapper } from './styles';

interface ShortIdProps {
  shortId: number;
}

const ShortId = ({ shortId }: ShortIdProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(shortId.toString());
  };

  return (
    <ShortIdWrapper>
      <span>ID: </span>
      <span>{shortId}</span>
      <span
        style={{ marginLeft: 5, cursor: 'pointer' }}
        onClick={handleCopy}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <MdFileCopy size={8} />
      </span>
    </ShortIdWrapper>
  );
};

export default ShortId;
