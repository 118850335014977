import { ConnectApiDelete, urls, ConnectApiPatch } from '../functions/connection';

export const updateNotifiedRewards = async () => {
  const respostaServico = await ConnectApiPatch(urls.URL_USER_UPDATE_REWARD_NOTIFICATION, {});
  return respostaServico.data;
};

export const deleteUserCache = async () => {
  const respostaServico = await ConnectApiDelete(urls.URL_CLEAR_USER_LEAGUE_STATUS);
  return respostaServico.data;
};
