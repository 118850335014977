import { Statistics, TourneyStatistics } from '../../../shared/modals/statistics/Statistics';
import { StatisticsTypes } from './statisticsTypes';

export const setStatistics = (statistics?: Statistics) => ({
  type: StatisticsTypes.SET_STATISTICS,
  payload: statistics,
});

export const setBestTourneys = (tourneys: TourneyStatistics[]) => ({
  type: StatisticsTypes.SET_BEST_TOURNEYS,
  payload: tourneys,
});

export const setWorseTourneys = (tourneys: TourneyStatistics[]) => ({
  type: StatisticsTypes.SET_WORSE_TOURNEYS,
  payload: tourneys,
});
