import { Reducer } from 'redux';

import { TournamentState, TournamentTypes } from './types';

const INITIAL_STATE: TournamentState = {
  tournament: undefined,
  listTournament: [],
  question: undefined,
  match: undefined,
};

const reducer: Reducer<TournamentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TournamentTypes.SET_TOURNAMENT:
      return {
        ...state,
        tournament: action.payload,
      };
    case TournamentTypes.SET_LIST_TOURNAMENT:
      return {
        ...state,
        listTournament: action.payload,
      };
    case TournamentTypes.SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case TournamentTypes.SET_MATCH:
      return {
        ...state,
        match: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
