import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
import { colors } from '../../../functions/colors';

interface PContainer {
  isTransparent?: boolean;
}

export const Container = styled.div<PContainer>`
  display: flex;
  align-items: center;
  background-color: ${props => (props.isTransparent ? 'transparent' : colors.purpleRegular)};
  border-radius: 4px;
  padding: 0px 8px;
`;

export const ImageCash = styled.img`
  height: 24px;
  z-index: 2;
  margin-right: 8px;
`;

export const BoxRadius = styled.p`
  color: ${colors.orange};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
`;
