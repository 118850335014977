import React, { useEffect } from 'react';
import { Container, Description } from '../card/tournamentCard/lastPlay/styles';
import Button from '../buttons/Button';
import { useCustomStyle } from '../../functions/hooks';
import { updateNotifiedRewards } from '../../services/serviceCache';
import { t } from '../../../i18n';

interface ExchangeCoinsModalInterface {
  handleGoToStore: () => void;
}

const ExchangeCoinsModal = ({ handleGoToStore }: ExchangeCoinsModalInterface) => {
  const { primaryColor } = useCustomStyle();

  useEffect(() => {
    updateNotifiedRewards();
  }, []);

  return (
    <Container>
      <Description>{t('enoughCash')}</Description>
      <Button customBackground={primaryColor.color} onClick={handleGoToStore}>
        {t('exchangeCoins')}
      </Button>
    </Container>
  );
};

export default ExchangeCoinsModal;
