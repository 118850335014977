/* eslint-disable import/no-cycle */
import { identity, pickBy } from 'lodash';
import {
  BodyUpdatePassword,
  BodyPasswordToken,
  BodyInsertArea,
  BodyUserLogin,
  BodyCreateMember,
  BodyEditMember,
} from '../modals/user/bodies';
import {
  ConnectApiPost,
  ConnectApiGet,
  urls,
  ConnectApiPatch,
  ConnectApiPUT,
} from '../functions/connection';
import { User } from '../modals/user/user';
import { LeagueConfig } from '../../store/reducers/league/types';
import generateQueryString from '../functions/queryString';

export const login = async (body: BodyUserLogin, subdomain?: string) => {
  const url = subdomain ? `${urls.URL_AUTH}?subdomain=${subdomain}` : urls.URL_AUTH;
  const respostaServico = await ConnectApiPost(url, body);
  return respostaServico.data;
};

export const refreshToken = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_REFRESH_TOKEN);
  return respostaServico.data;
};

export const getUserData = async (
  ssuHash?: string,
  subdomain?: string,
): Promise<{
  user: User;
  seasonConfig: LeagueConfig;
  token: string;
  newAssociationToCompany: string;
}> => {
  // Removes all undefined and null values from object
  const cleanParams = pickBy({ ssuHash, subdomain }, identity);
  const url = generateQueryString(urls.URL_AUTH, cleanParams);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getAllUsers = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_USER);
  return respostaServico.data;
};

export const insertMember = async (body: BodyCreateMember) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER, body);
  return respostaServico.data;
};

export const editUser = async (body: BodyCreateMember, idUser: string) => {
  const url = urls.URL_USER_ID.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};

export const editProfile = async (body: BodyEditMember, idUser: string) => {
  const url = urls.URL_USER_EDIT_PROFILE.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};

export const blockUser = async (idUser: string) => {
  const url = urls.URL_USER_BLOCK.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const unlockUser = async (idUser: string) => {
  const url = urls.URL_USER_UNLOCK.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const getAllAreas = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_AREA);
  return respostaServico.data;
};

export const insertArea = async (body: BodyInsertArea) => {
  const respostaServico = await ConnectApiPost(urls.URL_AREA, body);
  return respostaServico.data;
};

export const sendPassCode = async (body: BodyPasswordToken) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_PASS_CODE, body);
  return respostaServico.data;
};

export const verifyPassCode = async (body: BodyPasswordToken) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_VERIFY_CODE, body);
  return respostaServico.data;
};

export const redefinePass = async (body: BodyPasswordToken) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_REDEFINE_PASS, body);
  return respostaServico.data;
};

export const updatePassword = async (body: BodyUpdatePassword) => {
  const respostaServico = await ConnectApiPatch(urls.URL_USER_UPDATE_PASSWORD, body);
  return respostaServico.data;
};

export const getUserWallet = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_USER_WALLET);
  return respostaServico.data;
};
