import { css } from 'styled-components';
import { fonts } from '../../functions/fonts';

import { colors } from '../../functions/colors';

interface PUseTypeContainer {
  typeButton?: string;
  disabled?: boolean;
  customBackground?: string;
  customColor?: string;
}

export const useTypeContainer = ({
  typeButton = 'primary',
  disabled = false,
  customBackground,
  customColor,
}: PUseTypeContainer) => {
  switch (typeButton) {
    case `card-purple`:
      return css`
        width: 100%;
        height: 48px;
        background-color: ${disabled ? colors.gray : customBackground || colors.purpleRegular};
        border-radius: 4px;
        cursor: pointer;
        border: none;

        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: ${customColor || colors.white};
      `;
    case `card-green`:
      return css`
        width: 100%;
        height: 48px;
        background-color: ${disabled ? colors.gray : colors.green};
        border-radius: 4px;
        cursor: pointer;
        border: none;

        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: ${customColor || colors.white};
      `;
    case `card-yellow`:
      return css`
        width: 100%;
        height: 48px;
        background-color: ${disabled ? colors.gray : colors.orange};
        border-radius: 4px;
        cursor: pointer;
        border: none;

        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: ${customColor || colors.white};
      `;
    case 'primary-white':
      return css`
        width: 100%;
        height: 48px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.gray : colors.white};
        border: 1px solid ${customBackground || colors.purpleRegular};
        border-radius: 4px;

        color: ${customBackground || colors.purpleRegular};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      `;
    case 'primary-pink':
      return css`
        width: 100%;
        height: 32px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.gray : customBackground || colors.purpleRegular};
        border-radius: 4px;
        border: none;

        color: ${customColor || colors.white};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
      `;
    case 'line-gray':
      return css`
        margin-top: -1px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0px 24px;
        border: none;
        border-top: 1px solid ${customColor || colors.white};
        border-bottom: 1px solid ${customColor || colors.white};
        width: 100%;
        height: 56px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${customBackground || colors.black};

        color: ${customColor || colors.white};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'line-black-background':
      return css`
        margin-top: -1px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0px 24px 0px 40px;
        border: none;
        border-top: 1px solid ${customColor || colors.white};
        border-bottom: 1px solid ${customColor || colors.white};
        width: 100%;
        height: 56px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${customBackground || colors.background};

        color: ${customColor || colors.white};
        font-family: ${fonts.regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'tab-selected':
      return css`
        width: 100%;
        height: 48px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: transparent;
        border: none;
        border-bottom: 3px solid ${customBackground || colors.purpleRegular};

        color: ${customBackground || colors.purpleRegular};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'tab-not-selected':
      return css`
        margin-top: -2px;
        width: 100%;
        height: 48px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: transparent;
        border: none;
        border-bottom: 1px solid ${colors.grey100};

        color: ${colors.grey100};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'primary-black':
      return css`
        width: 100%;
        height: 48px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.gray : colors.background};
        border: 1px solid ${customBackground || colors.purpleRegular};
        border-radius: 4px;

        color: ${customBackground || colors.purpleRegular};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      `;
    case 'smooth-gray':
      return css`
        width: 100%;
        height: 48px;
        background-color: ${colors.background};
        cursor: pointer;
        border: 1px solid ${colors.gray};
        border-radius: 4px;

        color: ${colors.gray};
        font-family: ${fonts.regular};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 27px;
        padding-left: 16px;
        justify-content: flex-start;
      `;
    case 'primary':
    default:
      return css`
        width: 100%;
        height: 48px;
        cursor: ${disabled ? 'default' : 'pointer'};
        ${
          disabled
            ? `background-color: ${colors.black}`
            : `background: ${customBackground || colors.purpleRegular};`
        }
        color: ${disabled ? colors.grey100 : customColor || colors.white};
        font-family: ${fonts.semiBold};
        font-size: 18px;
        border: none;
        border-radius: 4px;
        font-weight: 400;
      `;
  }
};
