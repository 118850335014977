import { FullScreen, ModalData, SnackbarData } from '../../../shared/modals/geral/geral';

import { GeralTypes } from './types';

export const setSnackbarData = (snackbarData: SnackbarData) => ({
  type: GeralTypes.SET_SNACKBAR,
  payload: snackbarData,
});

export const setBlockScreen = (blockScreen: boolean) => ({
  type: GeralTypes.SET_BLOCK_SCREEN,
  payload: blockScreen,
});

export const setModal = (modalData: ModalData) => ({
  type: GeralTypes.SET_MODAL,
  payload: modalData,
});

export const closeModal = () => ({
  type: GeralTypes.CLOSE_MODAL,
});

export const setFullScreen = (fullScreen?: FullScreen) => ({
  type: GeralTypes.SET_FULL_SCREEN,
  payload: fullScreen,
});

export const setCoinsEarned = (coinsEarned: number) => ({
  type: GeralTypes.SET_COINS_EARNED,
  payload: coinsEarned,
});

export const setHighlightElement = (
  highlightElement?: 'coins' | 'menuEntries' | 'menuEntryLibrary',
) => ({
  type: GeralTypes.SET_HIGHLIGHT_ELEMENT,
  payload: highlightElement,
});

export const setCanPurchaseSomethingNew = (canPurchaseSomethingNew: boolean) => ({
  type: GeralTypes.SET_SHOW_NOTIFICATION,
  payload: canPurchaseSomethingNew,
});
