import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import Button from '../buttons/Button';
import { actions as geralActions } from '../../../store/reducers/geral';
import { useCustomStyle } from '../../functions/hooks';
import { t } from '../../../i18n';

interface ModalInterface {
  phone: string;
}

const Modal = ({ phone }: ModalInterface) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { primaryColor } = useCustomStyle();
  const identifier = userData ? userData.email || userData.whatsapp || userData.cpf : '';
  const companyName = userData?.company.name;
  const message = userData ? t('helpMessage1', { identifier, companyName }) : t('helpMessage2');

  const redirectToWhatsapp = () => {
    let validPhone = phone?.trim();
    if (!phone?.includes('+')) {
      validPhone = `+55${phone}`; //TODO: A hotfix for the brazilian phone code, but it should be dynamic later on.
    }

    window.open(`https://wa.me/${validPhone}?text=${message}`, '_blank');
    dispatch(geralActions.closeModal());
  };

  return (
    <>
      <div style={{ marginBottom: 32 }}>{t('helpInfo')}</div>
      <Button customBackground={primaryColor.color} onClick={redirectToWhatsapp}>
        {t('helpStart')}
      </Button>
    </>
  );
};

export default Modal;
