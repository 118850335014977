import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

interface PImage {
  source: string;
}

export const Container = styled.div`
  position: relative;
  background-color: ${colors.black};
  border-radius: 4px;
  width: 100%;
  max-width: 372px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
`;

export const TopData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
`;

export const LogoData = styled.div<PImage>`
  background-color: ${colors.black};
  ${props =>
    props.source !== ''
      ? `
  background-image:
            url('${props.source}')
  ;`
      : ''}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  height: 151px;
  width: 100%;
  max-width: 372px;
  border-radius: 24px 24px 0 0;
`;

export const Name = styled.h2`
  text-align: center;
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TimerText = styled.p`
  text-align: right;
  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  align-self: center;
  width: 50%;
`;

export const PrizeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  color: #ffc043;
`;
