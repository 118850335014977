import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  text-align: left;
  padding: 72px 0px 24px 0px;
`;

export const Title = styled.h1`
  margin: 16px 24px;
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.white};
`;

export const ReferralContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  flex-direction: 'column';
  background: none;
`;

export const QrContainer = styled.div`
  display: 'flex';
  flex-direction: column;
  margin: 24px;
  padding: 20px;
  background-color: #262824;
  text-align: center;
`;

export const QrTitle = styled.text`
  color: ${colors.white};
  font-family: 'Poppins-SemiBold';
  font-weight: 700;
  font-size: 24px;
`;

export const QrBackground = styled.div`
  display: flex;
  background-color: ${colors.white};
  width: fit-content;
  padding: 10px;
  margin: auto;
  margin-top: 10px;
`;

export const MissionDescription = styled.div`
  margin: 16px 0;
  color: ${colors.white};
`;

export const ShareButton = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 12px;
  width: 100%;
  justify-content: space-evenly;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  border-radius: 5px;
  background: #7500e5;
  color: ${colors.white};
`;

export const Text = styled.p`
  margin: 24px;
  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.17px;
`;
