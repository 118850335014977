import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { setFullScreen } from '../../../store/reducers/geral/actions';
import { colors } from '../../functions/colors';
import { useCustomStyle } from '../../functions/hooks';
import ChevronLeftIcon from '../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import FullScreenIcon from '../../icons/FullScreenIcon';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';

import { Container, BoxPagination, Text } from './styles';
import { t } from '../../../i18n';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js`;

interface PPdfViewer {
  urlImage: string;
  canDownload?: boolean;
  style?: CSSProperties;
  width?: number;
  height?: number;
  showButtonFullScreen?: boolean;
}

const PdfViewer = ({
  urlImage,
  style,
  width,
  height,
  showButtonFullScreen,
  canDownload,
}: PPdfViewer) => {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const { primaryColor } = useCustomStyle();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const handleChangePage = (newPage: number) => {
    if (newPage > 0 && newPage <= numPages) {
      setPageNumber(newPage);
    }
  };

  const handleOpenFullScreen = () => {
    dispatch(
      setFullScreen({
        mediaUrl: urlImage,
        mediaType: 'pdf',
      }),
    );
  };

  return (
    <Container style={style}>
      <Document file={urlImage} onLoadSuccess={onDocumentLoadSuccess}>
        <div role="button" aria-hidden="true" onClick={handleOpenFullScreen}>
          <Page height={height} width={width} pageNumber={pageNumber} />
        </div>
      </Document>
      <BoxPagination>
        <ButtonIcon onClick={() => handleChangePage(pageNumber - 1)}>
          <ChevronLeftIcon color={primaryColor.color || colors.purpleRegular} />
        </ButtonIcon>
        <Text>{t('resumePage', { pageNumber, numPages })}</Text>
        <ButtonIcon onClick={() => handleChangePage(pageNumber + 1)}>
          <ChevronRightIcon color={primaryColor.color || colors.purpleRegular} />
        </ButtonIcon>
        {showButtonFullScreen && (
          <ButtonIcon onClick={handleOpenFullScreen}>
            <FullScreenIcon color={primaryColor.color || colors.purpleRegular} />
          </ButtonIcon>
        )}
        {canDownload && (
          <ButtonIcon>
            <a href={urlImage} target="_blank" download rel="noreferrer">
              <DownloadIcon color={primaryColor.color || colors.purpleRegular} />
            </a>
          </ButtonIcon>
        )}
      </BoxPagination>
    </Container>
  );
};

PdfViewer.defaultProps = {
  style: {},
  width: undefined,
  height: undefined,
  showButtonFullScreen: true,
  canDownload: false,
};

export default PdfViewer;
