import styled from 'styled-components';
import { colors } from '../../../../shared/functions/colors';
import { fonts } from '../../../../shared/functions/fonts';

export const Text = styled.p`
  margin-top: 16px;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.black};
`;

export const TextBold = styled.p`
  margin-top: 16px;
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.black};
`;
