import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PChevronRightIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const ChevronRightIcon = ({ width, height, color, style }: PChevronRightIcon) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask
      id="prefix__ChevronRightIcon"
      maskUnits="userSpaceOnUse"
      x={8}
      y={6}
      width={8}
      height={12}
    >
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" fill={colors.white} />
    </mask>
    <g mask="url(#prefix__ChevronRightIcon)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

ChevronRightIcon.defaultProps = {
  width: 24,
  height: 24,
  color: colors.purpleRegular,
  style: {},
};

export default ChevronRightIcon;
