import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootStateGlobal } from '../../../../store/reducer';
import { MenuUrl } from '../../../enums/menuUrl';
import { colors } from '../../../functions/colors';
import { doLogout } from '../../../functions/connection/auth';
import ExitIcon from '../../../icons/ExitIcon';
import StarIcon from '../../../icons/StarIcon';
import FolderFilled from '../../../icons/FolderFilled';
import { actions as geralActions } from '../../../../store/reducers/geral';

import ButtonMenu from '../../buttons/buttonMenu/ButtonMenu';
import { useCustomStyle, useLibrary } from '../../../functions/hooks';
import { t } from '../../../../i18n';
import { ButtonList, Container, Content, Divider } from './styles';
import PersonOutline from '../../../icons/PersonOutline';
import OutlinedFlag from '../../../icons/OutlinedFlag';
import PlaceIcon from '../../../icons/Place';
import GiftIcon from '../../../icons/GiftIcon';

interface LateralMenuProps {
  switcherContent: ReactNode;
}

const LateralMenu = ({ switcherContent }: LateralMenuProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { backgroundSecondary, backgroundPrimary, primaryColor, textColor } = useCustomStyle();
  const isLibraryEnabled = useLibrary();
  const { highlightElement } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const homeAndStoreHighlightClass = highlightElement === 'menuEntries' ? 'heart-beat' : undefined;
  const libraryHighlightClass = highlightElement === 'menuEntryLibrary' ? 'heart-beat' : undefined;

  const verifyPath = (menuUrl: string) => window.location.pathname.includes(menuUrl);

  const handleGoTo = (menuUrl: string) => {
    // if an element is being highlighted, changing the page will stop the
    // animation/highlighting.
    dispatch(geralActions.setHighlightElement());

    history.push(menuUrl);
  };

  if (!userData) {
    return null;
  }

  return (
    <Container id="menu" style={backgroundPrimary}>
      <Content>
        <div style={{ position: 'relative' }}>{switcherContent}</div>

        <ButtonList>
          <ButtonMenu
            primaryColor={primaryColor.color}
            isSelected={verifyPath(MenuUrl.journeysList) || verifyPath(MenuUrl.home)}
            textColor={textColor.color || colors.white}
            icon={<PlaceIcon color={textColor.color || colors.white} />}
            text={t('journeys')}
            onClick={() => handleGoTo(MenuUrl.home)}
            color={textColor.color || colors.white}
            customBackground={backgroundSecondary.backgroundColor}
            className={homeAndStoreHighlightClass}
          />
          {userData.experiments.leagues && (
            <ButtonMenu
              primaryColor={primaryColor.color}
              isSelected={verifyPath(MenuUrl.league)}
              textColor={textColor.color || colors.white}
              icon={<OutlinedFlag color={textColor.color || colors.white} />}
              text={t('leagues')}
              onClick={() => handleGoTo(MenuUrl.league)}
              color={textColor.color || colors.white}
              customBackground={backgroundSecondary.backgroundColor}
            />
          )}
          {userData.experiments.referAndWin && (
            <ButtonMenu
              primaryColor={primaryColor.color}
              isSelected={verifyPath(MenuUrl.referrals)}
              textColor={textColor.color || colors.white}
              icon={<StarIcon color={textColor.color || colors.white} />}
              text={t('referAndWin')}
              onClick={() => handleGoTo(MenuUrl.referrals)}
              color={textColor.color || colors.white}
              customBackground={backgroundSecondary.backgroundColor}
            />
          )}
          {userData.experiments.rewards && (
            <ButtonMenu
              primaryColor={primaryColor.color}
              isSelected={verifyPath(MenuUrl.award)}
              textColor={textColor.color || colors.white}
              icon={<GiftIcon color={textColor.color || colors.white} />}
              text={t('rewards')}
              onClick={() => handleGoTo(MenuUrl.award)}
              color={textColor.color || colors.white}
              customBackground={backgroundSecondary.backgroundColor}
              className={homeAndStoreHighlightClass}
            />
          )}
          {isLibraryEnabled && (
            <ButtonMenu
              primaryColor={primaryColor.color}
              isSelected={verifyPath(MenuUrl.libraryList)}
              icon={<FolderFilled color={textColor.color || colors.white} />}
              text={t('library')}
              textColor={textColor.color || colors.white}
              customBackground={backgroundSecondary.backgroundColor}
              className={libraryHighlightClass}
              onClick={() => {
                handleGoTo(MenuUrl.libraryList);
              }}
            />
          )}
          <ButtonMenu
            primaryColor={primaryColor.color}
            isSelected={verifyPath(MenuUrl.profile)}
            textColor={textColor.color || colors.white}
            icon={<PersonOutline color={textColor.color || colors.white} />}
            text={t('profile')}
            onClick={() => handleGoTo(MenuUrl.profile)}
            color={textColor.color || colors.white}
            customBackground={backgroundSecondary.backgroundColor}
          />
          <ButtonMenu
            primaryColor={primaryColor.color}
            icon={<ExitIcon color={primaryColor.color || colors.purpleRegular} />}
            text={t('logout')}
            textColor={primaryColor.color || colors.purpleRegular}
            style={{
              background: 'none',
              alignSelf: 'center',
              margin: '0',
              padding: '4px',
            }}
            onClick={() => {
              doLogout();
            }}
          />
        </ButtonList>
      </Content>
      <Divider primaryColor={primaryColor.color} />
    </Container>
  );
};

export default LateralMenu;
