import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { pickBy } from 'lodash';
import { SnackbarData } from '../../shared/modals/geral/geral';
import { actions as geralAction } from '../../store/reducers/geral';
import { Box } from '../../shared/components/styles/styledBox';
import { RootStateGlobal } from '../../store/reducer';
import * as selfSignUpService from '../../shared/services/selfSignUp';
import UserCampaignForm, { newUser, SelfSignUpCampaignInterface, User } from './UserCampaignForm';
import { t } from '../../i18n';

const UpdateInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [selfSignUpCampaign, setSelfSignUpCampaign] = useState<SelfSignUpCampaignInterface>();
  const [user, setUser] = useState<User>(newUser);
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const [error, setError] = useState<Partial<User>>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!userData) return;

    (async () => {
      setLoading(true);

      try {
        const campaign = await selfSignUpService.getSourceCampaign();
        const formattedUser = {
          areaIds: `${userData.areaIds[0]}`,
          ...pickBy(userData, (_, key) =>
            [
              'name',
              'cpf',
              'whatsapp',
              'email',
              'rules',
              'privacyPolicy',
              'termsOfUse',
              'password',
            ].includes(key),
          ),
        } as User;

        setSelfSignUpCampaign(campaign);
        setUser(formattedUser);
      } catch (error) {
        history.push('/home');
      }
      setLoading(false);
    })();
  }, [userData]);

  const handleUpdate = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      await dispatch(selfSignUpService.update(user));
      history.push('/home');
    } catch (e) {
      if (e instanceof Error) {
        try {
          setError(JSON.parse(e.message));
        } catch {
          setSnackBar({
            message: e.message,
            show: true,
            type: 'error',
          });
        }
      }
    }
    setLoading(false);
  };

  const renderForm = (campaign: SelfSignUpCampaignInterface) => {
    if (!userData) return null;

    return (
      <div style={{ width: '100%' }}>
        <h1 style={{ marginBottom: 24, color: 'white' }}>ATUALIZE SEU CADASTRO</h1>
        <UserCampaignForm
          handleSubmission={handleUpdate}
          campaign={campaign}
          ignoreFields={['password']}
          error={error}
          setUser={setUser}
          loading={loading}
          user={user}
          submitBtnText={t('updateRegistration')}
        />
      </div>
    );
  };

  return (
    <div style={{ width: '100%', textAlign: 'left', padding: '72px 24px 24px 24px' }}>
      <Box>{selfSignUpCampaign && renderForm(selfSignUpCampaign)}</Box>
    </div>
  );
};

export default UpdateInfo;
