import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const ContainerFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 68px;
  display: none;
  align-items: center;
  justify-content: space-around;
  z-index: -100;
  background-color: ${colors.background};
  border-top: 1px solid;

  @media only screen and (max-width: 900px) {
    z-index: 9;
    display: flex;
  }
`;

export const ButtonContainer = styled.div<{ verifyPath: boolean; backgroundColor: string }>`
  background-color: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
  align-self: flex-end;
  border: none;
  cursor: pointer;

  ::after {
    width: 100%;
    height: 8px;
    border-radius: 5px 5px 0 0;
    background-color: ${props => (props.verifyPath ? props.backgroundColor : 'none')};
    border-color: ${props => (props.verifyPath ? props.backgroundColor : 'none')};
    content: '';
    padding: 0 20px;
  }
`;

export const ButtonText = styled.p<{ verifyPath: boolean; activeColor: string }>`
  font-size: 11px;
  color: ${props => (props.verifyPath ? props.activeColor : 'white')};
  font-weight: 600;
`;
