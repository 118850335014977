import styled from 'styled-components';

export const ImageFlag = styled.img``;

export const MenuWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-top: 2px;
`;

export const ItemText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
`;
