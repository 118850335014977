import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import BoxAwardAvailable from '../boxAwardAvailable/BoxAwardAvailable';
import { Container, ContainerList, Text } from './styles';
import { t } from '../../../i18n';
import { useCustomStyle } from '../../../shared/functions/hooks';

const AvailableAward = () => {
  const { textColor } = useCustomStyle();
  const { availableAward } = useSelector((state: RootStateGlobal) => state.awardReducer);
  const helperText = availableAward.length;

  return (
    <Container>
      {!helperText && <Text style={textColor}>{t('noAvailableRewards')}</Text>}
      <ContainerList>
        {availableAward.map(award => (
          <BoxAwardAvailable award={award} key={`award_${award.idAward}`} />
        ))}
      </ContainerList>
    </Container>
  );
};

AvailableAward.defaultProps = {
  supressHelpText: false,
};

export default AvailableAward;
