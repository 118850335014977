import styled from 'styled-components';
import Button from '../../../shared/components/buttons/Button';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  text-align: left;
  padding: 72px 0px 24px 0px;
`;

export const ContainerInfo = styled.div`
  padding: 0px 24px;
`;

export const Title = styled.h1`
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.white};
`;

export const Text = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: ${colors.white};

  strong {
    font-family: ${fonts.semiBold};
  }
`;

export const TextSmall = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: ${colors.white};
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media (min-width: 650px) {
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.white};
  margin: 8px 0px;
`;

export const FilterButtonsContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  justify-content: space-evenly;
  margin: 24px 0px;
`;

export const FilterButton = styled(Button)<{ filterId: number; currentFilterId: number }>`
  height: 25px;
  width: auto;
  padding: 4px 8px;
  border-radius: 12px;
  font-family: unset;
  font-size: 14px;
  font-weight: unset;
  background-color: ${({ filterId, currentFilterId, customBackground }) =>
    filterId === currentFilterId ? customBackground || colors.purpleRegular : colors.grey100};
`;
