import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import { colors } from '../../shared/functions/colors';
import CloseIcon from '../../shared/icons/CloseIcon';
import { t } from '../../i18n';

const Alert = () => {
  const cookies = new Cookies();
  const [showAlert, setShowAlert] = useState(!cookies.get('leaguesAlertDismissed'));

  const handleDismissAlert = () => {
    cookies.set('leaguesAlertDismissed', true);
    setShowAlert(false);
  };

  return showAlert ? (
    <div
      style={{
        fontSize: 10,
        textAlign: 'center',
        marginTop: 8,
        backgroundColor: 'rgba(117, 0, 229, 0.35)',
        borderRadius: 5,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ position: 'absolute', marginTop: -10, marginRight: -10 }}>
          <ButtonIcon
            style={{
              backgroundColor: colors.purpleRegular,
              width: 20,
              height: 20,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: -10,
            }}
            onClick={handleDismissAlert}
          >
            <CloseIcon height={10} width={10} color="white" />
          </ButtonIcon>
        </div>
      </div>
      <div style={{ padding: 8 }}>{t('rankingAlert')}</div>
    </div>
  ) : null;
};

export default Alert;
