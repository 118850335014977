import styled from 'styled-components';
import { colors } from '../../functions/colors';

export const Container = styled.div`
  background-color: ${colors.background}EE;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  div {
    max-width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.react-pdf__Page {
    flex-direction: column;
  }

  .annotationLayer {
    display: none;
  }

  div > div {
    justify-content: space-between;
    left: auto;
  }

  canvas {
    max-width: 98% !important;
    height: auto !important;
  }
`;

export const Image = styled.img`
  width: 100%;
`;
