import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { actions as geralAction } from '../../store/reducers/geral';
import { ModalData, SnackbarData } from '../../shared/modals/geral/geral';
import { QuestionData } from '../../shared/modals/socket/Socket';
import {
  Alternative,
  Tournament as TournamentObj,
} from '../../shared/modals/tournament/Tournament';
import { RootStateGlobal } from '../../store/reducer';
import { tournamentOperations, tournamentActions } from '../../store/reducers/tournament';
import Question from './question/Question';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { Container, Title, TitleBox } from './styles';
import ModalLeave from './modalLeave/ModalLeave';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import CloseIcon from '../../shared/icons/CloseIcon';
import { useCustomStyle } from '../../shared/functions/hooks';
import { colors } from '../../shared/functions/colors';
import { t } from '../../i18n';
import Justification from '../../shared/components/modal/justification/Justification';

interface ParamTypes {
  idTourney: string;
}

interface TournamentLocationState {
  exitToPath: string;
}

const Tournament = () => {
  const { idTourney } = useParams<ParamTypes>();
  const {
    state: { exitToPath },
  } = useLocation<TournamentLocationState>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [blockChangeAlternative, setBlockChangeAlternative] = useState<boolean>(false);
  const [isShowButtonProgress, setIsShowButtonProgress] = useState<boolean>(false);
  const [showingAnswer, setShowingAnswer] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [currentQuestion, setCurrentQuestion] = useState<QuestionData>({
    idQuestion: '',
    text: '',
    time: 0,
    alternatives: [],
  });
  const [rightAnswer, setRightAnswer] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(false);
  const setModalData = (x: ModalData) => dispatch(geralAction.setModal(x));
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const setBlockScreen = (x: boolean) => dispatch(geralAction.setBlockScreen(x));
  const getTournament = async () => dispatch(tournamentOperations.getTournament(idTourney));
  const setTournament = (x?: TournamentObj) => dispatch(tournamentActions.setTournament(x));
  const createMatch = async () => dispatch(tournamentOperations.createMatch(idTourney));
  const { question, match, tournament } = useSelector(
    (state: RootStateGlobal) => state.tournamentReducer,
  );
  const { textColor, backgroundPrimary } = useCustomStyle();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [previousQuestion, setPreviousQuestion] = useState<QuestionData | undefined>();

  const handleLeaveMatch = () => {
    setModalData({
      show: true,
      children: <ModalLeave history={history} exitToPath={exitToPath} />,
      height: '280px',
      title: t('attention'),
    });
  };

  // This effect ensures that the user is warned if he tries to leave the
  // match. To do so, it:
  // 1. pushes again the current path to the history because it's not possible
  // to disable the back behaviour. Thus, by pushing again the current path to
  // the history, once the back-button is pressed, it'll land in the same URL.
  // The practical result is that the user keeps seeing the question that is
  // currently being presented for the match because the component is not
  // unmounted. If it wasn't done, the list of contests (/tournamentList) would
  // be shown briefly, and once the user was sent back to the match, it would
  // create a new one.
  // 2. calls the handleLeaveMatch() method which handles properly this
  // scenario.
  useEffect(() => {
    history.push(history.location.pathname, { exitToPath }); // Pushes again the current path to the history

    return history.listen(() => {
      if (history.action === 'POP') {
        // At this point the user was already sent back
        history.go(1); // Keeps user in the same page/URL. It's like user pressing forward button
        handleLeaveMatch();
      }
    });
  }, []);

  useEffect(() => {
    const handleGetTournament = async () => {
      setBlockScreen(true);
      try {
        await createMatch();
      } catch (e) {
        if (e instanceof Error) {
          setSnackBar({
            message: e.message,
            show: true,
            type: 'error',
          });
        }
        history.push(exitToPath);
      }
      await getTournament();
      setBlockScreen(false);
    };
    setTournament();
    handleGetTournament();
  }, []);

  useEffect(() => {
    if (!currentQuestion.idQuestion && question) {
      setCurrentQuestion(question);
    }
    if (question?.rightAnswer) {
      setRightAnswer(question.rightAnswer);
    }
    setLoading(false);
  }, [question]);

  useEffect(() => {
    if (!showingAnswer && question) {
      setCurrentQuestion(question);
    }
  }, [showingAnswer]);

  if (!(question && tournament && match && userData)) {
    return null;
  }

  const handleInsertAnswer = async (questionAnswered: QuestionData, alternative: Alternative) => {
    if (blockChangeAlternative) {
      return;
    }
    setBlockChangeAlternative(true);
    setPreviousQuestion(questionAnswered);
    setSelectedAnswer(alternative.idAlternative);
    setShowingAnswer(true);

    setLoading(true);
    await tournamentOperations.verifyAnswer(
      questionAnswered.idQuestion,
      alternative.idAlternative,
      match.idMatch,
      currentIndex,
      idTourney,
    )(dispatch);

    setIsShowButtonProgress(true);
  };

  const handleNextQuestion = async () => {
    if (currentIndex < tournament.questionAmount) {
      setCurrentIndex(currentIndex + 1);
      setShowingAnswer(false);
      setSelectedAnswer('');
      setRightAnswer('');
    } else {
      setBlockScreen(true);
      setTimeout(() => {
        setBlockScreen(false);
        dispatch(tournamentActions.setQuestion(undefined));
        if (userData.company.migrated && !tournament.rankingEnabled) {
          history.push(`${MenuUrl.result}/`, {
            idTourney,
            idMatch: match.idMatch,
            tournament,
            exitToPath,
          });
        } else {
          history.push(`${MenuUrl.ranking}/`, {
            idTourney,
            idMatch: match.idMatch,
            tournament,
            exitToPath,
          });
        }
      }, 3000);
    }
    setBlockChangeAlternative(false);
    setIsShowButtonProgress(false);
    setPreviousQuestion(undefined);
  };

  const handleOpenJustification = (description?: string) => {
    if (description) {
      setModalData({
        show: true,
        height: '320px',
        children: <Justification description={description || ''} />,
        title: t('justification'),
      });
    }
  };

  return (
    <Container style={backgroundPrimary}>
      <TitleBox>
        <Title style={textColor}>{`${tournament.name} `}</Title>
        <ButtonIcon onClick={handleLeaveMatch}>
          <CloseIcon color={textColor.color || colors.white} />
        </ButtonIcon>
      </TitleBox>
      <Question
        question={currentQuestion}
        handleInsertAnswer={handleInsertAnswer}
        showingAnswer={showingAnswer}
        selectedAnswer={selectedAnswer}
        indexQuestion={currentIndex}
        rightAnswer={rightAnswer}
        isRight={question.isRight}
        questionAmount={tournament.questionAmount}
        loading={loading}
        isShowButtonProgress={isShowButtonProgress}
        shouldShowRightAnswer={tournament.showRightAnswer}
        onClickNextQuestion={handleNextQuestion}
        onClickShowJustify={
          previousQuestion?.justification && tournament.showRightAnswer
            ? () => handleOpenJustification(previousQuestion.justification)
            : undefined
        }
      />
    </Container>
  );
};

export default Tournament;
