import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PFolderFilled {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}
const FolderFilled = ({ width, height, color, style }: PFolderFilled) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M3.85364 7.94515V7.94432C3.85364 7.18745 4.46637 6.57593 5.21019 6.57593H11.9011L13.9766 8.65143L14.2695 8.94432H14.6838H24.1573C24.9076 8.94432 25.5257 9.56238 25.5257 10.3127V22.1547C25.5257 22.905 24.9076 23.5231 24.1573 23.5231H5.21019C4.45997 23.5231 3.84199 22.9052 3.8418 22.155C3.8418 22.1549 3.8418 22.1548 3.8418 22.1547L3.85364 7.94515Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

FolderFilled.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default FolderFilled;
