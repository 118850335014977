import React from 'react';
import { useDispatch } from 'react-redux';
import { Award } from '../../../../shared/modals/award/award';
import Button from '../../../../shared/components/buttons/Button';
import { actions as geralActions } from '../../../../store/reducers/geral';
import { TextBold } from './styles';
import { useCustomStyle } from '../../../../shared/functions/hooks';
import RichTextEditor from '../../../richEditor/RichTextEditor';
import { t } from '../../../../i18n';

interface PRulesAward {
  award: Award;
}

const RulesAward = ({ award }: PRulesAward) => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(geralActions.closeModal());
  const { primaryColor } = useCustomStyle();
  const { value } = award;
  return (
    <div>
      {award.description && (
        <div style={{ fontSize: '14px' }}>
          <TextBold>{`${t('description')}:`}</TextBold>
          <RichTextEditor value={award.description} />
        </div>
      )}
      <TextBold>{t('rewardValueWithCoins', { value })}</TextBold>
      <Button style={{ marginTop: 16 }} onClick={closeModal} customBackground={primaryColor.color}>
        {t('okUnderstand')}
      </Button>
    </div>
  );
};

export default RulesAward;
