import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import { FieldHookConfig, useField } from 'formik';

import { colors } from '../../shared/functions/colors';

interface CustomTextFieldProps {
  label: string;
  placeholder: string;
}

export const TextField = ({
  label,
  placeholder,
  ...props
}: CustomTextFieldProps & TextFieldProps) => {
  const [field, meta] = useField(props as FieldHookConfig<string>);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <MuiTextField
      label={label}
      placeholder={placeholder}
      style={{
        width: '100%',
        backgroundColor: colors.greyf0,
        borderRadius: '4px 4px 0 0',
        padding: '10px',
        marginBottom: '20px',
      }}
      InputLabelProps={{
        style: {
          paddingTop: '10px',
          paddingLeft: '10px',
          color: colors.black1f,
        },
      }}
      {...field}
      {...props}
      error={!!errorText}
      helperText={errorText}
    />
  );
};
