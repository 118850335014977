/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect } from 'react';
import { deburr } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, CrownImg, PositionInfo, ResultHeader, TextResult } from './styles';
import Button from '../../../shared/components/buttons/Button';
import { RootStateGlobal } from '../../../store/reducer';
import { deleteUserCache } from '../../../shared/services/serviceCache';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { t } from '../../../i18n';

const SeasonResult = () => {
  const { lastSeasonResult } = useSelector((state: RootStateGlobal) => state.leagueReducer);
  const history = useHistory();

  if (!lastSeasonResult) return null;
  const { newLeague, position, status } = lastSeasonResult;

  const [seasonResult, seasonResultText, resultColor] = (() => {
    if (status === 'promoted') {
      return [t('congrats'), t('leaguePromoted', { position, newLeague }), '#32C56D'];
    }
    if (status === 'relegated') {
      return [t('betterLuck'), t('leagueRelegated', { position, newLeague }), '#F95D4A'];
    }
    return [t('veryWell'), t('leagueUnchanged', { position, newLeague }), '#EAA237'];
  })();

  useEffect(() => {
    deleteUserCache();
  }, []);

  return (
    <Container>
      <PositionInfo resultColor={resultColor}>{`#${position}`}</PositionInfo>
      {position && (
        <CrownImg src={require(`../../../shared/icons/leagues/${deburr(newLeague)}.svg`)} />
      )}
      <ResultHeader>{seasonResult}</ResultHeader>
      <TextResult>{seasonResultText}</TextResult>
      <Button onClick={() => history.push(MenuUrl.home)}>{t('continue').toUpperCase()}</Button>
    </Container>
  );
};

export default SeasonResult;
