import styled from 'styled-components';
import { fonts } from '../../shared/functions/fonts';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  height: 100%;
  width: 100%;
  .annotationLayer {
    display: none;
  }

  @media (min-width: 900px) {
    width: calc(100% - 220px);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  flex-direction: row;
  width: 90%;
`;

export const Title = styled.h1`
  color: ${colors.purpleRegular};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  line-height: 36px;
  margin-left: 10px;
`;

export const BoxButton = styled.div`
  padding: 0px 16px;
  margin-top: 15px;
  margin-bottom: 24px;
  max-width: 900px;
  bottom: 0;
  align-self: center;
  width: 100%;
`;

interface DotContainerInterface {
  customBackground: string;
}

export const DotContainer = styled.div<DotContainerInterface>`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ul.BrainhubCarousel__dots {
    align-items: center;
  }

  li {
    display: flex;
    align-content: center;
  }

  button.BrainhubCarousel__dot {
    background: none;
    padding: 5px;
  }

  button.BrainhubCarousel__dot::before {
    background: ${({ customBackground }) => customBackground || colors.purpleRegular};
    width: 10px;
    height: 10px;
  }

  button.BrainhubCarousel__dot--selected::before {
    background: ${colors.white};
    border: 3px solid ${({ customBackground }) => customBackground || colors.purpleRegular};
  }
`;

export const Slide = styled.div`
  width: 80%;
`;

export const ContainerImage = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 16px;

  img {
    max-width: 100%;
    max-height: 380px;
  }
`;

export const DownloadLink = styled.a`
  text-decoration: none;
`;

export const StepButtonText = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;
