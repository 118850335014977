import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { Container as Ctnr } from '../../shared/components/containerPage/styles';
import { fonts } from '../../shared/functions/fonts';

interface LeanInterface {
  leanLayout: boolean;
  backgroundPrimary?: string;
}

interface UserInterface {
  currentUser: boolean;
  backgroundPrimary?: string;
}

export const Container = styled(Ctnr)<LeanInterface>`
  font-size: 15px;
  line-height: 22px;
  color: ${colors.white};
  width: 100%;
  padding: ${({ leanLayout }) => (leanLayout ? '8px' : '')};
  margin-top: ${({ leanLayout }) => (leanLayout ? '64px' : '240px')};
`;

export const Header = styled.div<LeanInterface>`
  position: ${({ leanLayout }) => (leanLayout ? 'inherit' : 'fixed')};
  background: ${({ backgroundPrimary }) => backgroundPrimary || colors.background};
  padding-top: 12px;
  padding-bottom: 5px;
  z-index: 2;
  width: ${({ leanLayout }) => (leanLayout ? '100%' : 'calc(100% - 36px);')};
  max-width: ${({ leanLayout }) => (leanLayout ? '' : '500px')};
  top: ${({ leanLayout }) => (leanLayout ? 0 : '50px')};

  @media (min-width: 900px) {
    max-width: ${({ leanLayout }) => (leanLayout ? '' : '1002px')};
    width: ${({ leanLayout }) => (leanLayout ? '100%' : 'calc(100% - 220px);')};
  }
`;

export const Wrapper = styled.div<LeanInterface>`
  display: flex;
  flex-direction: column;
  height: ${({ leanLayout }) => (leanLayout ? 'calc(100vh - 48px)' : '')};
`;

export const CrownsContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: '100%';
  max-width: fit-content;
  margin: 0 auto;
`;

export const CrownWrapper = styled.div`
  max-width: 184px;
`;

export const CrownImg = styled.img`
  width: 100%;
  height: 104px;
  max-width: 184px;
  padding: 0 4px 8px 4px;
`;

export const RemainingTime = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${colors.orange};
`;

export const RankingContainer = styled.div<LeanInterface>`
  flex-shrink: 1;
  padding: 0 8px;
  overflow-y: ${({ leanLayout }) => (leanLayout ? 'scroll' : 'visible')};
  border: ${({ leanLayout }) => (leanLayout ? '1px solid' : '')};
  border-bottom: ${({ leanLayout }) => (leanLayout ? '1px solid' : '')};
  border-radius: ${({ leanLayout }) => (leanLayout ? '6px' : 0)};
  margin-top: 16px;
`;

export const RankingRow = styled.div<UserInterface>`
  display: flex;
  align-items: center;
  padding: 8px 0;
  background: ${({ currentUser, backgroundPrimary }) =>
    currentUser ? backgroundPrimary || colors.black : ''};
`;

export const Position = styled.div`
  min-width: 23px;
  height: 23px;
  margin-right: 16px;
  text-align: center;
`;

export const Name = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`;

export const Points = styled.div`
  font-family: ${fonts.bold};
  font-size: 16px;
`;

export const ProfileImg = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 16px;
  border: 1px solid ${colors.purpleRegular};
`;

export const ZoneDivider = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ZoneText = styled.span`
  margin: 8px;
  font-family: ${fonts.semiBold};
  font-size: 16px;
`;
