import { Reducer } from 'redux';

import { LeagueTypes, LeagueConfig, Position } from './types';

const INITIAL_STATE: LeagueConfig = {
  enabled: false,
  leagues: [],
  seasonRanking: [],
  roseUp: false,
  timeLeft: '',
  lastSeasonResult: { position: '', status: '', newLeague: '' },
};

const findCurrentUserPlace = (seasonRanking: Position[]) =>
  seasonRanking.findIndex(place => place.currentUser);

const reducer: Reducer<LeagueConfig> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LeagueTypes.SET_SEASON:
      return {
        ...state,
        ...action.payload,
      };
    case LeagueTypes.SET_UPDATED_RANKING: {
      if (!action.payload || !action.payload.enabled || !action.payload.seasonRanking) return state;

      const previousPlace = findCurrentUserPlace(state.seasonRanking);
      const newPlace = findCurrentUserPlace(action.payload.seasonRanking);
      const newState = { ...state, ...action.payload };
      newState.roseUp = previousPlace === -1 || newPlace < previousPlace;

      return newState;
    }
    case LeagueTypes.DISABLE_LEAGUE:
      return {
        ...state,
        leagues: [],
        seasonRanking: [],
        timeLeft: null,
        enabled: false,
        roseUp: false,
      };
    default:
      return state;
  }
};

export default reducer;
