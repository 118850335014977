import styled from 'styled-components';
import { colors } from '../../../functions/colors';

interface PLine {
  isPlayerLogged: boolean;
  isPair?: boolean;
  customBackground?: string;
  customBackgroundSecondary?: string;
}

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-bottom: 240px;
`;

export const Line = styled.tr<PLine>`
  background-color: ${props =>
    props.isPlayerLogged
      ? `${colors.grey100}`
      : `${
          props.isPair
            ? props.customBackgroundSecondary || colors.black
            : props.customBackground || colors.background
        }`};
  height: 44px;
`;

export const Column = styled.td`
  padding: 4px 8px;
  color: ${colors.white};

  font-family: ${colors.white};
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

export const TextLimited = styled.p`
  height: 20px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;
