import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { MenuUrl } from '../../shared/enums/menuUrl';
import Button from '../../shared/components/buttons/Button';
import { ModalData } from '../../shared/modals/geral/geral';
import { actions as geralActions } from '../../store/reducers/geral';
import {
  Container,
  Title,
  BoxButton,
  Content,
  ImageProfile,
  ImageIcon,
  Header,
  BoxLogout,
  ImageFlagContainer,
} from './styles';
import { RootStateGlobal } from '../../store/reducer';
import { operations as userOperations } from '../../store/reducers/user';
import Input from '../../shared/components/input/Input';
import { BodyEditMember } from '../../shared/modals/user/bodies';
import { uploadImage } from '../../shared/services/serviceFile';
import CloseIcon from '../../shared/icons/CloseIcon';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import LeaveProfile from './leaveProfile/LeaveProfile';
import ExitIcon from '../../shared/icons/ExitIcon';
import { doLogout } from '../../shared/functions/connection/auth';
import { useCustomStyle } from '../../shared/functions/hooks';
import { colors } from '../../shared/functions/colors';
import ButtonText from '../../shared/components/buttons/buttonText/ButtonText';
import TutoText from '../../shared/components/other/tutoText/TutoText';
import { t } from '../../i18n';
import { LanguageSelector } from '../../shared/components/languageSelector/LanguageSelector';
import ShortId from './ShortId';

const INITIAL_BODY = {
  name: '',
  shortId: 0,
};

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [bodyEditMember, setBodyEditMember] = useState<BodyEditMember>(INITIAL_BODY);
  const [changed, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const editProfile = async (x: BodyEditMember, y: string) =>
    dispatch(userOperations.editProfile(x, y));
  const { primaryColor } = useCustomStyle();

  useEffect(() => {
    if (userData) {
      setBodyEditMember({
        ...bodyEditMember,
        name: userData.name,
        image: userData.image,
        shortId: userData.shortId,
      });
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setBodyEditMember({
        ...bodyEditMember,
        name: userData.name,
        image: userData.image,
        shortId: userData.shortId,
      });
    }
  }, [userData]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyEditMember({
      ...bodyEditMember,
      name: event.target.value,
    });
    setChanged(true);
  };

  const handleEditProfile = async () => {
    if (userData) {
      setLoading(true);
      await editProfile(bodyEditMember, userData.idUser);
      setLoading(false);
      setChanged(false);
    }
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      setLoading(true);
      const returnUploadImage = await uploadImage(files[0]);
      setLoading(false);
      setBodyEditMember({
        ...bodyEditMember,
        image: returnUploadImage.locationFile,
      });
      setChanged(true);
    }
  };

  const handleLeaveProfile = () => {
    if (changed) {
      setModalData({
        show: true,
        children: <LeaveProfile history={history} />,
        height: '200px',
        title: t('attention'),
      });
    } else {
      history.push(MenuUrl.home);
    }
  };

  return (
    <Container>
      <Header>
        <Title>{t('editProfile')}</Title>
        <ButtonIcon onClick={handleLeaveProfile}>
          <CloseIcon />
        </ButtonIcon>
      </Header>
      <Content>
        <div style={{ position: 'relative' }}>
          <ImageIcon htmlFor="photo_select">
            <ImageProfile
              src={bodyEditMember.image ? bodyEditMember.image : '/images/profile_add.png'}
            />
          </ImageIcon>
          <ShortId shortId={bodyEditMember.shortId} />
          <Input type="file" onChange={handleChangeFile} size={104} hidden id="photo_select" />
          <ImageFlagContainer>
            <LanguageSelector />
          </ImageFlagContainer>
        </div>
        <Input
          title={`${t('name')}*`}
          placeholder=""
          onChange={handleChangeName}
          type="text"
          value={bodyEditMember.name}
          style={{ marginBottom: 16, width: '90%' }}
        />

        <Input
          title={`Login (${t('nonEditable')})`}
          placeholder=""
          onChange={handleChangeName}
          type="text"
          disabled
          value={userData ? userData.email : ''}
          style={{ marginBottom: 16, width: '90%' }}
        />
      </Content>
      <BoxLogout
        onClick={() => {
          doLogout();
        }}
      >
        <ButtonText color={primaryColor.color || colors.white}>
          <ExitIcon color={primaryColor.color || colors.white} />
          <p style={{ marginLeft: '10px' }}>{t('endSession')}</p>
        </ButtonText>
      </BoxLogout>
      <BoxButton>
        <Button
          onClick={() => handleEditProfile()}
          disabled={!changed}
          loading={loading}
          style={{
            marginBottom: 20,
            width: '90%',
          }}
        >
          {t('saveChanges')}
        </Button>
        <TutoText style={{ marginBottom: '10px' }} />
      </BoxButton>
    </Container>
  );
};

export default Profile;
