import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Container } from './styles';
import { t } from '../../../../i18n';

interface PStageDone {
  style?: CSSProperties;
}

const StageDone = ({ style }: PStageDone) => <Container style={style}>{t('done')}</Container>;

StageDone.defaultProps = {
  style: {},
};

export default StageDone;
