import styled from 'styled-components';
import { colors } from '../../../functions/colors';

interface PProgress {
  width: number;
}

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${colors.gray};
  border: 1px solid ${colors.white};
  height: 8px;
`;

export const Progress = styled.div<PProgress>`
  transition: 1s ease-out;
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props =>
    props.width > 30 ? `${props.width > 70 ? colors.green : colors.orange}` : colors.red};
  border-radius: 4px;
`;
