import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';

interface MainBackground {
  whiteScreen: boolean;
}

export const Container = styled.div`
  height: 100%;
`;

export const BoxMenuMain = styled.div`
  display: flex;
`;

export const Main = styled.main<MainBackground>`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => (props.whiteScreen ? colors.white : colors.background)};
  display: flex;
  justify-content: center;
`;
