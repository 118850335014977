import styled from 'styled-components';
import { colors } from '../../../../shared/functions/colors';
import { fonts } from '../../../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  padding: 72px 24px 24px 24px;
`;

export const Image = styled.img`
  margin: auto;
`;

export const Title = styled.h1`
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  color: ${colors.white};
`;

export const Text = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: ${colors.white};
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.white};
  margin: 8px 0px;
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media (min-width: 650px) {
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
`;
