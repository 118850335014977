import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PAwardIcon2 {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const AwardIcon2 = ({ width, height, color, style }: PAwardIcon2) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="prefix_AwardIcon2"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={1}
      width={14}
      height={13}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.334 4h-1.453c.073-.207.12-.434.12-.667a1.997 1.997 0 00-3.667-1.1l-.333.447-.334-.454a2.008 2.008 0 00-1.666-.893c-1.107 0-2 .893-2 2 0 .233.046.46.12.667H2.667c-.74 0-1.326.593-1.326 1.333l-.007 7.333c0 .74.593 1.334 1.333 1.334h10.667c.74 0 1.333-.594 1.333-1.334V5.333c0-.74-.593-1.333-1.333-1.333zm-3.333-1.334c.366 0 .666.3.666.667 0 .367-.3.667-.666.667a.669.669 0 01-.667-.667c0-.367.3-.667.667-.667zm-3.334.667c0-.367-.3-.667-.666-.667-.367 0-.667.3-.667.667 0 .367.3.667.667.667.366 0 .666-.3.666-.667zm6.667 9.333H2.667v-1.333h10.667v1.333zM2.667 9.333h10.667v-4H9.947l1.387 1.887-1.08.78-2.253-3.067L5.747 8l-1.08-.78 1.387-1.887H2.667v4z"
        fill={color}
      />
    </mask>
    <g mask="url(#prefix_AwardIcon2)">
      <path fill={color} d="M0 0h16v16H0z" />
    </g>
  </svg>
);

AwardIcon2.defaultProps = {
  width: 16,
  height: 16,
  color: colors.white,
  style: {},
};

export default AwardIcon2;
