export enum MenuUrl {
  home = '/home',
  loginCompany = '/loginCompany',
  tournament = '/tournament',
  ranking = '/ranking',
  result = '/result',
  updatePassword = '/updatePassword',
  updateInfo = '/updateInfo',
  presentation = '/presentation',
  profile = '/profile',
  feedback = '/feedback',
  award = '/award',
  journeysList = '/journeys',
  referrals = '/referrals',
  league = '/league',
  libraryList = '/library',
  seasonResult = '/seasonResult',
  feed = '/feed',
}

export default MenuUrl;
