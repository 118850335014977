import React from 'react';
import { useCustomStyle } from '../../../../functions/hooks';
import Button from '../../../buttons/Button';

import { Container, Description } from './styles';
import { t } from '../../../../../i18n';

interface PLastPlay {
  playTourney: () => void;
  closeModal: () => void;
}

const LastPlay = ({ playTourney, closeModal }: PLastPlay) => {
  const { primaryColor } = useCustomStyle();

  return (
    <Container>
      <Description dangerouslySetInnerHTML={{ __html: t('lastChance') }} />
      <Button onClick={playTourney} customBackground={primaryColor.color}>
        {t('yesContinue')}
      </Button>
      <Button
        typeButton="primary-white"
        style={{ marginTop: '16px' }}
        customBackground={primaryColor.color}
        onClick={closeModal}
      >
        {t('noTryLater')}
      </Button>
    </Container>
  );
};

export default LastPlay;
