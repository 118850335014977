import { Reducer } from 'redux';

import { MatchState, MatchTypes } from './matchTypes';

const INITIAL_STATE: MatchState = {
  feedback: undefined,
};

const reducer: Reducer<MatchState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MatchTypes.SET_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
