import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

interface PShadow {
  show: boolean;
  visibility: string;
}

interface PContainer {
  show: boolean;
  height?: string;
}

export const Shadow = styled.div<PShadow>`
  transition: visibility 0.5s;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2147483647;
  background-color: ${props => (props.show ? `${colors.background}EE;` : `${colors.black}00;`)};
  width: 100%;
  visibility: ${props => props.visibility};
`;

export const Container = styled.div<PContainer>`
  border-radius: ${props => (props.height ? '24px 24px 0px 0px;' : '0px')};
  transition: 0.5s;
  background: ${colors.white};
  position: fixed;
  bottom: 0;
  left: ${props => (props.show ? '0' : '100%')};
  right: 0;
  padding: 24px;
  width: 100%;
  height: ${props => (props.height ? props.height : '100%')};
`;

export const Header = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.purpleRegular};
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 0px 10px 20px 10px;
`;
