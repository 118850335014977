import React, { InputHTMLAttributes, FC, CSSProperties } from 'react';
import {
  Container,
  StyledInput,
  StyledInputMask,
  TextPlaceHolder,
  TextErrorMessage,
} from './styles';
import IconSearch from '../../icons/iconSearch';
import { useCustomStyle } from '../../functions/hooks';
import { colors } from '../../functions/colors';

interface PInput extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  title?: string;
  customBackground?: string;
  customTextColor?: string;
  messageError?: string;
  typeStyle?: string;
  style?: CSSProperties;
  mask?: string | (string | RegExp)[];
  icon?: string;
}

const Input: FC<PInput> = ({
  error,
  title,
  messageError,
  style,
  typeStyle,
  mask,
  customBackground,
  customTextColor,
  icon,
  ...props
}: PInput) => {
  const { primaryColor } = useCustomStyle();

  const styleIcon = (): CSSProperties => {
    if (icon) {
      return {
        margin: '10px -10px 0 10px',
        zIndex: 2,
      };
    }
    return {
      position: 'absolute',
      right: '8px',
      bottom: '8px',
    };
  };

  const renderIcon = (currentIcon: string) => {
    switch (currentIcon) {
      case 'search':
        return (
          <IconSearch style={styleIcon()} color={primaryColor.color || colors.purpleRegular} />
        );
      default:
        return null;
    }
  };

  return (
    <Container icon={!!icon} style={style}>
      {title && (
        <TextPlaceHolder customTextColor={customTextColor} typeStyle={typeStyle}>
          {title}
        </TextPlaceHolder>
      )}
      {(mask && (
        <StyledInputMask
          mask={mask}
          maskPlaceholder={null}
          onChange={props.onChange}
          value={props.value}
          error={error}
          typeStyle={typeStyle}
          name={props.name}
          customBackground={customBackground}
        />
      )) || (
        <>
          {icon && renderIcon(icon)}

          <StyledInput
            customBackground={customBackground}
            customTextColor={customTextColor}
            icon={!!icon}
            error={error}
            {...props}
            typeStyle={typeStyle}
          />
        </>
      )}
      <TextErrorMessage>{error && messageError}</TextErrorMessage>
    </Container>
  );
};

Input.defaultProps = {
  error: false,
  title: '',
  messageError: '',
  typeStyle: 'primary',
  style: {},
  mask: undefined,
  customBackground: '',
  customTextColor: '',
  icon: '',
};

export default Input;
