import styled from 'styled-components';
import { fonts } from '../../shared/functions/fonts';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${colors.background};
  padding: 24px;
`;

export const ContainerDone = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const ImgLogo = styled.img`
  margin: 60px auto 32px;
`;

export const Text = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const TitleDone = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  font-family: ${fonts.bold};
  color: ${colors.green};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;
