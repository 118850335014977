import React, { ChangeEvent } from 'react';
import BaseForm from './BaseForm';

class OpenQuestionForm extends BaseForm {
  buildBody = () => {
    return (
      <textarea
        name="answer"
        style={{ width: '100%', height: '100px' }}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => this.handleChangeValue(event.target)}
      />
    );
  };
}

export default OpenQuestionForm;
