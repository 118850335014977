import { Reducer } from 'redux';

import { AwardState, AwardTypes } from './awardTypes';

const INITIAL_STATE: AwardState = {
  acquiredAward: [],
  availableAward: [],
  award: undefined,
};

const reducer: Reducer<AwardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AwardTypes.SET_ACQUIRED_AWARD:
      return {
        ...state,
        acquiredAward: action.payload,
      };
    case AwardTypes.SET_AVAILABLE_AWARD:
      return {
        ...state,
        availableAward: action.payload,
      };
    case AwardTypes.SET_AWARD:
      return {
        ...state,
        award: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
