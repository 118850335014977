import { FullScreen, ModalData, SnackbarData } from '../../../shared/modals/geral/geral';

export enum GeralTypes {
  SET_SNACKBAR = '@geral/SET_SNACKBAR',
  SET_BLOCK_SCREEN = '@geral/SET_BLOCK_SCREEN',
  SET_MODAL = '@geral/SET_MODAL',
  CLOSE_MODAL = '@geral/CLOSE_MODAL',
  SET_CURRENT_ON_BOARDING = '@general/SET_CURRENT_ON_BOARDING',
  SET_FULL_SCREEN = '@general/SET_FULL_SCREEN',
  SET_COINS_EARNED = '@general/SET_COINS_EARNED',
  SET_HIGHLIGHT_ELEMENT = '@general/SET_HIGHLIGHT_ELEMENT',
  SET_SHOW_NOTIFICATION = '@general/SET_SHOW_NOTIFICATION',
}

export interface GeralState {
  readonly snackbarData: SnackbarData;
  readonly blockScreen: boolean;
  readonly modalData: ModalData;
  readonly fullScreen?: FullScreen;
  readonly coinsEarned: number;
  readonly highlightElement?: 'coins' | 'menuEntries' | 'menuEntryLibrary';
  readonly canPurchaseSomethingNew: boolean;
}
