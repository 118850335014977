/* eslint-disable react/jsx-one-expression-per-line */
import { Box, styled, useTheme } from '@material-ui/core';
import { Avatar, Button, Stack, Typography } from '@mui/material';

type Props = {
  redirectTo: string;
  companyName: string;
};

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: 14,
});

export const AzureADSSO = ({ redirectTo, companyName }: Props) => {
  const theme = useTheme();
  return (
    <Box sx={{ pt: 2 }}>
      <StyledButton href={redirectTo} variant="outlined" color="primary">
        <Stack gap={1} alignItems="center">
          <Avatar sx={{ background: theme.palette.primary.main }}>{companyName[0]}</Avatar>
          <Typography variant="body1">Entrar com AzureAd</Typography>
          <Typography variant="body2">{companyName}</Typography>
        </Stack>
      </StyledButton>
    </Box>
  );
};
