import { combineReducers } from 'redux';
import { RootStateOrAny } from 'react-redux';

import { RankingState } from './reducers/ranking/rankingTypes';
import { TournamentState } from './reducers/tournament/types';
import { AwardState } from './reducers/award/awardTypes';
import { UserState } from './reducers/user/types';
import { userReducer } from './reducers/user';
import { rankingReducer } from './reducers/ranking';
import { geralReducer } from './reducers/geral';
import { tournamentReducer } from './reducers/tournament';
import { GeralState } from './reducers/geral/types';
import { companyPreferenceStylesReducer } from './reducers/companyPreferenceStyles';
import { matchReducer } from './reducers/match';
import { MatchState } from './reducers/match/matchTypes';
import { StatisticsState } from './reducers/statistics/statisticsTypes';
import { statisticsReducer } from './reducers/statistics';
import { awardReducer } from './reducers/award';
import { CompanyState } from './reducers/companyPreferenceStyles/types';
import { PreviewState } from './reducers/preview/types';
import { previewReducer } from './reducers/preview';
import { LeagueConfig } from './reducers/league/types';
import { leagueReducer } from './reducers/league';

export interface RootStateGlobal extends RootStateOrAny {
  userReducer: UserState;
  geralReducer: GeralState;
  tournamentReducer: TournamentState;
  rankingReducer: RankingState;
  companyPreferenceStylesReducer: CompanyState;
  matchReducer: MatchState;
  statisticsReducer: StatisticsState;
  awardReducer: AwardState;
  previewReducer: PreviewState;
  leagueReducer: LeagueConfig;
}

export default combineReducers<RootStateGlobal>({
  userReducer,
  geralReducer,
  tournamentReducer,
  rankingReducer,
  companyPreferenceStylesReducer,
  matchReducer,
  statisticsReducer,
  awardReducer,
  previewReducer,
  leagueReducer,
});
