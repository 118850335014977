import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { WhatsappShareButton } from 'react-share';
import { BsFillShareFill } from 'react-icons/bs';
import { User } from '../../shared/modals/user/user';
import { useCustomStyle } from '../../shared/functions/hooks';
import {
  MissionDescription,
  QrBackground,
  QrContainer,
  QrTitle,
  ReferralContainer,
  ShareButton,
  Text,
} from './styles';
import { colors } from '../../shared/functions/colors';
import { t } from '../../i18n';

const ReferralLink = ({ memberGetMember }: User) => {
  const { backgroundSecondary, primaryColor, textColor } = useCustomStyle();

  return (
    (memberGetMember?.enabled && (
      <ReferralContainer>
        <QrContainer style={backgroundSecondary}>
          <QrTitle style={textColor}>QR CODE</QrTitle>
          <QrBackground>
            <QRCodeSVG
              value={memberGetMember.url}
              size={180}
              fgColor={primaryColor.color || colors.purpleRegular}
            />
          </QrBackground>
        </QrContainer>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <WhatsappShareButton
            style={{
              display: 'flex',
              width: `-webkit-fill-available`,
              margin: '0 24px',
              maxWidth: '300px',
            }}
            url={memberGetMember.url}
            onAuxClick={undefined}
            onAuxClickCapture={undefined}
            translate={undefined}
          >
            <ShareButton
              style={{
                background: primaryColor.color,
                color: textColor.color,
              }}
            >
              {t('shareLink')}
              <BsFillShareFill size={24} />
            </ShareButton>
          </WhatsappShareButton>
        </div>
      </ReferralContainer>
    )) || (
      <MissionDescription style={textColor}>
        <Text>{t('noCampaigns')}</Text>
      </MissionDescription>
    )
  );
};

export default ReferralLink;
