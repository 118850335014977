import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootStateGlobal } from '../../store/reducer';
import * as feedService from '../../shared/services/feed';
import { actions as geralActions } from '../../store/reducers/geral';
import { MenuUrl } from '../../shared/enums/menuUrl';
import ContainerPage from '../../shared/components/containerPage/ContainerPage';
import { t } from '../../i18n';
import Post, { PostInterface } from './Post';
import { FeedContainer } from './style';
import * as userActions from '../../store/reducers/user/actions';

const Feed = () => {
  const dispatch = useDispatch();
  const setBlockScreen = async (x: boolean) => dispatch(geralActions.setBlockScreen(x));
  const { blockScreen } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const [feed, setFeed] = useState<PostInterface[]>([]);
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  useEffect(() => {
    (async () => {
      setBlockScreen(true);
      setFeed(await feedService.getAll());
      setBlockScreen(false);
    })();
  }, []);

  useEffect(() => {
    if (userData) {
      // When user opens the feed, the backend makes sure to "clean up"
      // numbers. Thus, we reset the alertsCount.
      dispatch(userActions.setUserData({ ...userData, alertsCount: 0 }));
    }
  }, [userData?.alertsCount]);

  const renderCardRows = () => {
    if (!feed || feed.length <= 0) return null;

    return (
      <FeedContainer>
        {feed.map(post => (
          <Post {...post} key={post.id} />
        ))}
      </FeedContainer>
    );
  };

  return (
    <ContainerPage
      title={t('feed')}
      menuUrl={MenuUrl.feed}
      blockScreen={blockScreen}
      listLength={feed.length}
      notFoundTitle={t('noLibraryAvailable')}
      renderList={renderCardRows()}
    />
  );
};

export default Feed;
