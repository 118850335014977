import React, { forwardRef, Ref } from 'react';
import { useCustomStyle } from '../../shared/functions/hooks';
import { JOURNEY_GRID_COLUMNS, StepInterface } from './Journey';
import Step from './step/Step';
import { t } from '../../i18n';

interface JourneyGridParamsInterface {
  steps: StepInterface[];
  registerStepButton: (index: number, ref: HTMLButtonElement) => void;
  handleStepClicked: (step: StepInterface, i: number) => void;
  background: string;
}

const JourneyGrid = forwardRef((params: JourneyGridParamsInterface, ref: Ref<HTMLDivElement>) => {
  const { steps, registerStepButton, handleStepClicked, background } = params;
  const { journeysBackground } = useCustomStyle();

  const backgroundImage =
    (background && `url(${background})`) ||
    (journeysBackground.urlImage && `url(${journeysBackground.urlImage})`);

  return (
    <>
      <div
        className="journey__grid-container"
        style={{
          gridTemplateColumns: `repeat(${JOURNEY_GRID_COLUMNS}, 1fr)`,
          backgroundImage,
        }}
        ref={ref}
      >
        {steps.map((step, i) => (
          <Step
            key={i}
            step={step}
            i={i}
            registerStepButton={registerStepButton}
            onClick={() => handleStepClicked(step, i)}
          />
        ))}
      </div>
      <div className="journey__start-indicator-container">
        <div className="journey__start-indicator">{t('start')}</div>
      </div>
    </>
  );
});

export default JourneyGrid;
