import { ConnectApiPost, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

export interface ChallengeParamsInterface {
  answer: string;
  comment?: string;
}

export const submitAnswer = async (challengeId: string, params: ChallengeParamsInterface) => {
  const { answer: text, comment } = params;

  try {
    return await ConnectApiPost(
      urls.ANSWER_CHALLENGE_URL.replace(':challengeId', challengeId.toString()),
      { text, comment },
    );
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }

  return null;
};
