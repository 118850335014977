import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';

import { colors } from '../../shared/functions/colors';
import { TextField } from './TextField';
import { PaymentCard } from './PaymentCard';
import { PaymentType } from '../../shared/enums/paymentTypes';
import { FormCardFields } from './FormCardFields';
import Button from '../../shared/components/buttons/Button';
import { t } from '../../i18n';

interface FormProps {
  companyImageUrl: string;
}

const commonInitialValues = {
  name: '',
  email: '',
  confirmEmail: '',
  cpfCnpj: '',
  cellPhone: '',
};

const commonValidationSchema = Yup.object({
  name: Yup.string().required(t('requiredField')),
  email: Yup.string().required(t('requiredField')),
  confirmEmail: Yup.string()
    .required(t('requiredField'))
    .oneOf([Yup.ref('email')], t('emailsDoNotMatch')),
  cpfCnpj: Yup.string().required(t('requiredField')),
  cellPhone: Yup.string().required(t('requiredField')),
});

export const Form = ({ companyImageUrl }: FormProps) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(
    PaymentType.CREDIT_CARD,
  );

  const initialValues = useMemo(() => {
    switch (selectedPaymentType) {
      case PaymentType.CREDIT_CARD:
        return {
          ...commonInitialValues,
          cardNumber: '',
          cardName: '',
          cardExpirationDate: '',
          cardSecurityCode: '',
          cardNumberOfInstallments: '',
        };
      case PaymentType.PIX:
        return commonInitialValues;
      default:
        return commonInitialValues;
    }
  }, [selectedPaymentType]);

  const validationSchema = useMemo(() => {
    switch (selectedPaymentType) {
      case PaymentType.CREDIT_CARD:
        return commonValidationSchema.concat(
          Yup.object({
            cardNumber: Yup.string().required(t('requiredField')),
            cardName: Yup.string().required(t('requiredField')),
            cardExpirationDate: Yup.string().required(t('requiredField')),
            cardSecurityCode: Yup.string().required(t('requiredField')),
            cardNumberOfInstallments: Yup.string().required(t('requiredField')),
          }),
        );
      case PaymentType.PIX:
        return commonValidationSchema;
      default:
        return commonValidationSchema;
    }
  }, [selectedPaymentType]);

  return (
    <div
      style={{
        backgroundColor: colors.white,
        borderRadius: '8px',
        padding: '20px',
      }}
    >
      <img
        src={companyImageUrl}
        alt="Company logo"
        style={{
          maxWidth: '25%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '20px',
        }}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          // ToDo: Implement this later
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextField name="name" label={t('name')} placeholder={t('namePlaceholder')} />
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <TextField
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder={t('emailPlaceholder')}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  type="email"
                  name="confirmEmail"
                  label={t('confirmEmailLabel')}
                  placeholder={t('confirmEmailPlaceholder')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <TextField name="cpfCnpj" label="CPF/CNPJ" placeholder={t('genericPlaceholder')} />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="cellPhone"
                  label={t('cellphoneLabel')}
                  placeholder={t('cellphonePlaceholder')}
                />
              </Grid>
            </Grid>
            <hr style={{ marginBottom: '20px', color: colors.gray }} />
            <Grid container spacing={2}>
              <Grid item sm={6} md={6}>
                <PaymentCard
                  type={PaymentType.CREDIT_CARD}
                  selected={selectedPaymentType === PaymentType.CREDIT_CARD}
                  onClick={() => setSelectedPaymentType(PaymentType.CREDIT_CARD)}
                />
              </Grid>
              <Grid item sm={6} md={6}>
                <PaymentCard
                  type={PaymentType.PIX}
                  selected={selectedPaymentType === PaymentType.PIX}
                  onClick={() => setSelectedPaymentType(PaymentType.PIX)}
                />
              </Grid>
            </Grid>
            {selectedPaymentType === PaymentType.CREDIT_CARD && <FormCardFields />}
            {selectedPaymentType === PaymentType.PIX && <p>{t('underDevelopment')}</p>}
            <Button type="submit">{t('buy')}</Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
