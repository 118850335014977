import { get } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { PreferenceStyle } from '../../../../business/src/shared/modals/preferences/preferences';
import { RootStateGlobal } from '../../store/reducer';
import { StepLeftInterface } from '../modals/user/user';
import { TutorialContext } from '../../App';
import * as LocalStorageProxy from './connection/localStorageProxy';
import { STYLES_COMPANY_KEY, WHATSAPP_COMPANY_KEY } from '../constants/keysLocalStorage';

interface CustomStyleInterface {
  showWhatsapp: boolean;
  backgroundPrimary: { backgroundColor: string };
  backgroundSecondary: { backgroundColor: string };
  primaryColor: { color: string };
  secondaryColor: { color: string };
  textColor: { color: string };
  title: { text: string; display: string };
  displayLogo: { display: string };
  displayCoins: { display: string };
  journeysBackground: { urlImage: string };
}

export const useCustomStyle = () => {
  const [customStyles, setCustomStyles] = useState<PreferenceStyle>();
  const [showCompanyWhatsapp, setShowCompanyWhatsapp] = useState(false);
  const { companyStyles } = useSelector(
    (state: RootStateGlobal) => state.companyPreferenceStylesReducer,
  );
  const { previewParam } = useSelector((state: RootStateGlobal) => state.previewReducer);
  const cachedCustomStyles = JSON.parse(LocalStorageProxy.getItem(STYLES_COMPANY_KEY) || '{}');
  const cachedWhatsappStyles = JSON.parse(LocalStorageProxy.getItem(WHATSAPP_COMPANY_KEY) || '{}');

  useEffect(() => {
    if (previewParam && companyStyles.preview) {
      setCustomStyles(companyStyles.preview);
      setShowCompanyWhatsapp(companyStyles.showWhatsapp);
    } else {
      setCustomStyles(companyStyles.live || cachedCustomStyles);

      const showWhatsapp = companyStyles.showWhatsapp ?? cachedWhatsappStyles.showWhatsapp ?? true;
      setShowCompanyWhatsapp(showWhatsapp);
    }
  }, [companyStyles, previewParam]);

  const formattedCustomStyles = {
    showWhatsapp: showCompanyWhatsapp,
    backgroundPrimary: get(customStyles, 'colorsStyles.background.backgroundPrimary', {}),
    backgroundSecondary: get(customStyles, 'colorsStyles.background.backgroundSecondary', {}),
    primaryColor: get(customStyles, 'colorsStyles.primaryColor', {}),
    secondaryColor: get(customStyles, 'colorsStyles.secondaryColor', {}),
    textColor: get(customStyles, 'colorsStyles.textColor', {}),
    title: get(customStyles, 'home.title', {}),
    displayLogo: get(customStyles, 'home.logo', {}),
    displayCoins: get(customStyles, 'home.coins', {}),
    journeysBackground: get(customStyles, 'journeys.background', {}),
  } as CustomStyleInterface;
  return formattedCustomStyles;
};

export const useJourneysHome = () => {
  return window.innerWidth <= 720;
};

export const useTutorialRefForStep = (step: StepLeftInterface) => {
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const stepsLeft = userData?.tutorial.stepsLeft;
  const tutorialAnchor = useContext(TutorialContext).ref;

  if (
    (step === 'homeCoins' && stepsLeft?.includes(step)) ||
    (step === 'homeStore' && !stepsLeft?.includes('homeCoins') && stepsLeft?.includes(step))
  )
    return tutorialAnchor;

  return undefined;
};

export const useLibrary = () => {
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  if (!userData?.experiments?.library) return false;

  const { highlightElement } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const { stepsLeft } = userData.tutorial;
  const isLibraryEnabled = !stepsLeft?.includes('library');
  const isEnablingLibrary = highlightElement === 'menuEntryLibrary';
  return isLibraryEnabled || isEnablingLibrary;
};
