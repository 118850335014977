import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import ReactLoading from 'react-loading';
import { colors } from '../../../functions/colors';
import { useCustomStyle } from '../../../functions/hooks';
import CheckIcon from '../../../icons/CheckIcon';
import CloseIcon from '../../../icons/CloseIcon';

import { Container, TextAlternative, BoxAlternative } from './styles';

interface PButtonAlternative extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  selected?: boolean;
  loading?: boolean;
  typeButton?: 'correct' | 'default' | 'wrong' | 'correctNotSelected';
}

const ButtonAlternative: FC<PButtonAlternative> = ({
  children,
  selected,
  typeButton,
  loading,
  ...props
}: PButtonAlternative) => {
  const { textColor, backgroundSecondary } = useCustomStyle();

  const backgroundColor = () => {
    switch (typeButton) {
      case 'correct':
        return colors.green;
      case 'wrong':
        return colors.red;
      case 'correctNotSelected':
        return colors.green;
      case 'default':
      default:
        return backgroundSecondary.backgroundColor || colors.black;
    }
  };

  const border = () => {
    switch (typeButton) {
      case 'correct':
        return `2px solid ${colors.white}`;
      case 'wrong':
        return `2px solid ${colors.white}`;
      case 'correctNotSelected':
        return `2px solid ${colors.white}`;
      case 'default':
      default:
        return 'none';
    }
  };

  const icon = () => {
    if (loading) {
      return (
        <ReactLoading
          type="bubbles"
          color={textColor.color || colors.white}
          width={24}
          height={20}
        />
      );
    }
    switch (typeButton) {
      case 'correct':
        return <CheckIcon color={textColor.color || colors.white} />;
      case 'wrong':
        return <CloseIcon color={textColor.color || colors.white} />;
      case 'correctNotSelected':
      case 'default':
      default:
        return '';
    }
  };

  return (
    <Container border={border()} backgroundColor={backgroundColor()} disabled={selected} {...props}>
      <BoxAlternative>
        <TextAlternative style={textColor}>{children}</TextAlternative>
        {icon()}
      </BoxAlternative>
    </Container>
  );
};

ButtonAlternative.defaultProps = {
  typeButton: 'default',
  selected: false,
  loading: false,
};

export default ButtonAlternative;
