import React from 'react';
import { useSelector } from 'react-redux';
import ButtonBack from '../../shared/components/buttons/buttonBack/ButtonBack';
import { RootStateGlobal } from '../../store/reducer';
import Tabs from '../../shared/components/tabs/Tabs';
import ReferralsList from './ReferralsList';
import ReferralLink from './ReferralLink';
import { useCustomStyle } from '../../shared/functions/hooks';
import { Container, Title, MissionDescription, Text } from './styles';
import { t } from '../../i18n';

const Referral = () => {
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { textColor, backgroundPrimary } = useCustomStyle();

  if (!userData) return null;

  const listTabs = [
    {
      id: 1,
      nameButton: t('disclosureLink'),
      children: <ReferralLink {...userData} />,
    },
    {
      id: 2,
      nameButton: t('registration'),
      children: <ReferralsList {...userData} />,
    },
  ];

  return (
    <Container style={backgroundPrimary}>
      <ButtonBack style={{ marginLeft: 24 }} />
      <Title style={textColor}>{t('referAndWin').toUpperCase()}</Title>
      <MissionDescription style={textColor}>
        <Text>{t('inviteText', { companyName: userData.company.name })}</Text>
      </MissionDescription>
      <Tabs listTabs={listTabs} />
    </Container>
  );
};

export default Referral;
