import { ConnectApiGet, ConnectApiPost, ConnectApiPUT, urls } from '../functions/connection';

export const createMatch = async (idTourney: string) => {
  const respostaServico = await ConnectApiPost(urls.URL_MATCH, { idTourney });
  return respostaServico.data;
};

export const verifyAnswer = async (
  idQuestion: string,
  idAlternative: string,
  idMatch: string,
  currentIndex: number,
  isTourney: boolean,
  idEvent: string,
) => {
  const body = {
    idQuestion,
    idAlternative,
    idMatch,
    questionIndex: currentIndex,
    idTourney: idEvent,
  };

  const respostaServico = await ConnectApiPUT(urls.URL_ANSWER, body);
  return respostaServico.data;
};

export const endMatch = async (idMatch: string, idTourney: string) => {
  const respostaServico = await ConnectApiPUT(urls.URL_MATCH, {
    idMatch,
    idTourney,
  });
  return respostaServico.data;
};

export const getRanking = async (idTourney: string) => {
  const url = urls.URL_MATCH_RANKING.replace('{idTourney}', `${idTourney}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getTournament = async (idTourney: string) => {
  const url = urls.URL_TOURNAMENT_ID.replace('{idTourney}', `${idTourney}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getFeedback = async (idMatch: string) => {
  const url = urls.URL_FEEDBACK.replace('{idMatch}', `${idMatch}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getAllMatch = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_MATCH);
  return respostaServico.data;
};
