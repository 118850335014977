import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import { animate } from 'motion';
import { setCoinsEarned, setModal } from '../../../store/reducers/geral/actions';
import challengeCompleted from '../../../audios/challenge_completed.mp3';
import creditEarned from '../../../audios/credit_earned.mp3';
import { operations as operationsUser } from '../../../store/reducers/user';
import { RootStateGlobal } from '../../../store/reducer';
import { BoxCash, ImageCash } from './styles';
import { useCustomStyle, useTutorialRefForStep } from '../../functions/hooks';
import { MenuUrl } from '../../enums/menuUrl';
import { playSound } from '../../functions/audio';
import ExchangeCoinsModal from './ExchangeCoinsModal';
import { t } from '../../../i18n';

const Coins = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { coinsEarned, highlightElement, canPurchaseSomethingNew } = useSelector(
    (state: RootStateGlobal) => state.geralReducer,
  );
  const { wallet } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { displayCoins, textColor, primaryColor } = useCustomStyle();
  const ref = useTutorialRefForStep('homeCoins');

  const redirectToRewardsStore = () => {
    history.push(MenuUrl.award);
  };

  const playVictorySound = () => {
    playSound(challengeCompleted);
  };

  const playCoinsSound = () => {
    playSound(creditEarned);
  };

  const eraseEarnedCoins = () => {
    dispatch(setCoinsEarned(0));
  };

  const animateCoins = (newBalance: number) => {
    animate(
      '#coins',
      { transform: ['none', 'rotate(45deg) scale(2)', 'rotate(0deg) scale(1)'] },
      { duration: 1, repeat: 3 },
    ).finished.then(() => {
      const coins = document.getElementById('coins');

      if (coins) {
        playCoinsSound();

        animate(
          progress => {
            coins.innerHTML = `${Math.round(progress * newBalance)}`;
          },
          {
            duration: 1,
            easing: 'ease-out',
          },
        );
      }
    });
  };

  const refreshCoinsBalance = () => {
    return operationsUser.getUserWallet()(dispatch);
  };

  const modalData = () => {
    return {
      show: true,
      height: 'inherit',
      children: <ExchangeCoinsModal handleGoToStore={handleGoToStore} />,
      title: t('timeToExchangeCoins'),
    };
  };

  const handleGoToStore = () => {
    dispatch(
      setModal({
        ...modalData(),
        show: false,
      }),
    );
    history.push(MenuUrl.award);
  };

  const showExchangeCoinsModal = () => {
    if (canPurchaseSomethingNew) {
      setTimeout(() => dispatch(setModal(modalData())), 1000);
    }
  };

  useEffect(() => {
    (async () => {
      if (coinsEarned) {
        const newWallet = await refreshCoinsBalance();

        if (newWallet?.magicashAmount) {
          playVictorySound();
          animateCoins(newWallet.magicashAmount);
          eraseEarnedCoins();
          showExchangeCoinsModal();
        }
      }
    })();
  }, [coinsEarned]);

  return (
    <BoxCash
      style={{
        backgroundColor: primaryColor.color,
        display: displayCoins.display === 'block' ? 'flex' : displayCoins.display,
        ...textColor,
        cursor: 'pointer',
      }}
      onClick={() => redirectToRewardsStore()}
      ref={ref}
      className={highlightElement === 'coins' ? 'heart-beat' : undefined}
    >
      <ImageCash
        src={window.innerWidth > 650 ? '/images/magicashicon2.png' : '/images/magicashCash.png'}
        alt="MS"
      />
      <div style={{ fontSize: '13px', paddingRight: '4px' }} id="coins">
        {wallet ? ` ${wallet.magicashAmount}` : '0'}
      </div>
    </BoxCash>
  );
};

export default Coins;
