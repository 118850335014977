import React, { ReactNode } from 'react';
import { useCustomStyle } from '../../../functions/hooks';

import { Container, Title, Info } from './styles';
import { t } from '../../../../i18n';

interface PNotFound {
  children: ReactNode;
}

const NotFound = ({ children }: PNotFound) => {
  const { textColor } = useCustomStyle();

  return (
    <Container>
      <img src="/images/not-found.svg" alt="Image_not_found" />
      <Title style={textColor}>{t('notFound')}</Title>
      <Info style={textColor}>{children}</Info>
    </Container>
  );
};

export default NotFound;
