import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

export const Container = styled.menu`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 64px;
  display: flex;
  padding: 0px 32px;
  z-index: 5;
  background-color: ${colors.background};
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 16px;
  cursor: pointer;
`;

export const Icon = styled.img`
  height: 32px;
  width: auto;
`;

export const BoxCashAndMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: 0;
  height: 56px;
`;

export const BoxCash = styled.div`
  background-color: ${colors.purpleRegular};
  border-radius: 8px;
  display: flex;
  align-self: center;
  padding: 4px 8px;
  align-items: center;

  font-family: ${fonts.semiBold};
  color: ${colors.orange};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  height: 38px;
`;

export const ImageCash = styled.img`
  margin-left: 0;
  margin-right: 10px;
  height: 24px;
`;

export const MenuContainer = styled.div`
  @media only screen and (max-width: 720px) {
    display: none;
  }
`;
