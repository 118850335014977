import React from 'react';

import { Container, BoxRadius, ImageCash } from './styles';

interface PPrizeCard {
  value: number;
  isTransparent?: boolean;
}

const PrizeCard = ({ value, isTransparent }: PPrizeCard) => (
  <Container isTransparent={isTransparent}>
    <ImageCash src="/images/magicashCash.png" alt="MC" />
    <BoxRadius>{value}</BoxRadius>
  </Container>
);

PrizeCard.defaultProps = {
  isTransparent: false,
};

export default PrizeCard;
