import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import ButtonBack from '../../shared/components/buttons/buttonBack/ButtonBack';
import Tabs from '../../shared/components/tabs/Tabs';
import AcquiredAward from './acquiredAward/AcquiredAward';
import AvailableAward from './availableAward/AvailableAward';
import { Container, Text, Title } from './styles';
import { awardOperations } from '../../store/reducers/award';
import { actions as geralActions } from '../../store/reducers/geral';
import BuyAward from './buyAward/BuyAward';
import { useCustomStyle, useJourneysHome } from '../../shared/functions/hooks';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { t } from '../../i18n';

interface ParamTypes {
  idAward: string;
}

const AVAILABLE_FILTER_ID = 1;
const PURCHASED_FILTER_ID = 2;

const Award = () => {
  const dispatch = useDispatch();
  const { idAward } = useParams<ParamTypes>();
  const { textColor } = useCustomStyle();
  const location = useLocation();
  const [filterId, setFilterId] = useState(AVAILABLE_FILTER_ID);
  const journeysAsHome = useJourneysHome();

  useEffect(() => {
    const getAwards = async () => {
      dispatch(geralActions.setBlockScreen(true));
      await awardOperations.getAwards()(dispatch);
      dispatch(geralActions.setBlockScreen(false));
    };
    getAwards();
  }, []);

  useEffect(() => {
    const selectedTabId = location.state?.received ? PURCHASED_FILTER_ID : AVAILABLE_FILTER_ID;
    setFilterId(selectedTabId);
  }, [location.state]);

  const listTabs = [
    {
      id: AVAILABLE_FILTER_ID,
      nameButton: t('available').toUpperCase(),
      children: <AvailableAward />,
    },
    {
      id: PURCHASED_FILTER_ID,
      nameButton: t('acquired').toUpperCase(),
      children: <AcquiredAward />,
    },
  ];

  if (idAward) {
    return <BuyAward idAward={idAward} />;
  }

  return (
    <Container>
      {!journeysAsHome && <ButtonBack style={{ marginLeft: 24 }} urlBack={MenuUrl.home} />}
      <Title style={textColor}>{t('store')}</Title>
      <Text style={textColor}>{filterId === 1 ? t('availableRewards') : t('acquiredReward')}</Text>
      <Tabs listTabs={listTabs} selectedTabId={filterId} setPropTab={setFilterId} />
    </Container>
  );
};

Award.defaultProps = {
  displayMode: 'standalone',
};

export default Award;
