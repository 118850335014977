import styled from 'styled-components';
import { colors } from '../../functions/colors';

interface PContainer {
  position: number;
}

interface PBoxMessage {
  type: string;
}

export const Container = styled.div<PContainer>`
  position: absolute;
  transition: 0.5s;
  bottom: 80px;
  left: ${props => props.position}px;
  display: flex;
  width: 97%;
  justify-content: center;
  z-index: 1000;
`;

export const BoxMessage = styled.div<PBoxMessage>`
  min-height: 64px;
  background-color: ${props => (props.type === 'success' ? colors.green : colors.red)};
  border: 1px solid ${colors.white};
  color: ${colors.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const Close = styled.div`
  color: ${colors.white};
  margin-left: -15px;
  cursor: pointer;
`;
