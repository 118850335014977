import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MenuItem, Select, SelectProps, styled } from '@material-ui/core';

import { ItemText, MenuWrapper } from './styles';
import { colors } from '../../functions/colors';
import { RootStateGlobal } from '../../../store/reducer';

const SelectFlag = styled(Select)<SelectProps>(() => ({
  height: 29,
  padding: '4px',
  borderRadius: 8,
  color: '#262824',
  textAlign: 'center',
  '& .MuiSelect-select.MuiSelect-select': {
    padding: 0,
  },
}));

const MenuFlag = styled(MenuItem)<SelectProps>(() => ({}));

export const LanguageSelector = () => {
  const storedLanguage = localStorage.getItem('i18nextLng') || '';
  const flags = [
    { text: 'Português - BRA', url: '/images/flagBr.svg', language: 'pt' },
    { text: 'Español - ES', url: '/images/flagEs.svg', language: 'es' },
    { text: 'English - USA', url: '/images/flagEn.svg', language: 'en' },
  ];
  const [activeItem, setActiveItem] = useState<string | unknown>(
    flags.find(e => e.language === storedLanguage) || 'pt',
  );
  const { companyStyles } = useSelector(
    (state: RootStateGlobal) => state.companyPreferenceStylesReducer,
  );
  const acceptedLng = ['pt', 'en', 'es'];

  const { i18n } = useTranslation();

  useEffect(() => {
    if (acceptedLng.includes(storedLanguage.slice(0, 2))) {
      setActiveItem(flags.find(flag => flag.language === storedLanguage.slice(0, 2))?.url);
    } else {
      setActiveItem('/images/flagBr.svg');
    }
  }, []);

  useEffect(() => {
    if (
      (companyStyles.live?.home && !companyStyles.live?.home?.language) ||
      companyStyles.live?.home?.language?.display === 'none'
    ) {
      i18n.changeLanguage('pt');
    }
  }, [companyStyles.live?.home]);

  const handleFlagClick = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setActiveItem(event.target.value);
    const selectedLanguage = flags.find(flag => flag.url === event.target.value)?.language;
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  };

  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const handleMouseEnter = (url: string) => {
    setHoveredItem(url);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <MenuWrapper style={companyStyles.live?.home?.language || { display: 'none' }}>
      <SelectFlag
        inputProps={{ IconComponent: () => null }}
        disableUnderline
        value={activeItem}
        renderValue={() => (
          <img
            src={typeof activeItem === 'string' ? activeItem : '/images/flagBr.svg'}
            alt="selected-flag"
            style={{ width: '32px', height: '21px' }}
          />
        )}
        onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
          handleFlagClick(event)
        }
        MenuProps={{
          MenuListProps: { disablePadding: true },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        displayEmpty
      >
        {flags.map(flag => {
          return (
            <MenuFlag
              key={flag.text}
              value={flag.url}
              onMouseEnter={() => handleMouseEnter(flag.url)}
              onMouseLeave={handleMouseLeave}
              style={{
                backgroundColor: hoveredItem === flag.url ? colors.purpleRegular : 'transparent',
              }}
            >
              <ItemText
                style={{
                  color: hoveredItem === flag.url ? colors.white : '#929898',
                }}
              >
                {flag.text}
              </ItemText>
            </MenuFlag>
          );
        })}
      </SelectFlag>
    </MenuWrapper>
  );
};
