import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { fonts } from '../../functions/fonts';

import { colors } from '../../functions/colors';

const TYPE_WHITE = 'white';

interface PContainer {
  error?: boolean;
  typeStyle?: string;
  customBackground?: string;
  customTextColor?: string;
  icon?: boolean;
}

export const Container = styled.div<PContainer>`
  width: 100%;
  ${props => (props.icon ? 'display: flex;' : '')};
`;

export const StyledInput = styled.input<PContainer>`
  text-align: left;
  border: ${props =>
    props.error
      ? `1px solid ${colors.orange}`
      : `${
          props.typeStyle === TYPE_WHITE
            ? `1px solid ${props.customBackground || colors.grey80}`
            : 'none'
        }`};
  border-radius: 4px;
  padding: ${props => (props.icon ? '0px 8px 0px 40px' : '0px 16px')};
  font-size: 18px;
  width: 100%;
  outline: none;
  height: 48px;
  background-color: ${props =>
    props.typeStyle === TYPE_WHITE ? colors.white : props.customBackground || colors.black};
  color: ${props =>
    props.typeStyle === TYPE_WHITE ? colors.black : props.customTextColor || colors.white};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: ${props => (props.icon ? '0px 0px 0px -22px' : '0px')};
`;

export const StyledInputMask = styled(InputMask)<PContainer>`
  text-align: left;
  border: ${props =>
    props.error
      ? `1px solid ${colors.orange}`
      : `${
          props.typeStyle === TYPE_WHITE
            ? `1px solid ${props.customBackground || colors.grey80}`
            : 'none'
        }`};
  border-radius: 4px;
  padding: 0px 16px;
  font-size: 18px;
  width: 100%;
  outline: none;
  height: 48px;
  background-color: ${props =>
    props.typeStyle === TYPE_WHITE ? colors.white : props.customBackground || colors.black};
  color: ${props =>
    props.typeStyle === TYPE_WHITE ? colors.black : props.customTextColor || colors.white};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const TextPlaceHolder = styled.p<PContainer>`
  font-family: ${fonts.semiBold};
  color: ${props =>
    props.typeStyle === TYPE_WHITE ? colors.grey100 : props.customTextColor || colors.white};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  text-align: left;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export const TextErrorMessage = styled.p`
  height: 16px;
  text-align: left;
  font-family: ${fonts.semiBold};
  color: ${colors.orange};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 4px;
  margin-left: 4px;
`;

export const InputFile = styled.input`
  position: absolute;
  width: 100%;
  z-index: 2;
  opacity: 0;
`;

export const Title = styled.h2`
  font-family: ${fonts.semiBold};
  color: ${colors.grey100};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  margin-left: 8px;
`;

export const SubTitle = styled.h2`
  font-family: ${fonts.regular};
  color: ${colors.grey100};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  margin: 8px;
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InfoLocalFile = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: calc(100% - 40px);

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxInput = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  background-color: transparent;
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  padding: 8px;
  color: ${colors.black};
  height: 40px;
  width: calc(100% - 40px);

  z-index: 1;
`;
