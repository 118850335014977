import styled from 'styled-components';

interface IResultColor {
  resultColor: string;
}

export const Container = styled.div`
  display: flex;
  padding: 24px;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PositionInfo = styled.p<IResultColor>`
  font-style: normal;
  font-weight: 700;
  font-size: 123px;
  line-height: 154px;
  text-align: center;
  color: white;
  width: 100%;
  margin-bottom: 16px;
  color: ${props => props.resultColor};
`;

export const CrownImg = styled.img`
  width: 80%;
  padding: 0 4px 8px 4px;
  margin-bottom: 40px;
`;

export const ResultHeader = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  width: 100%;
  color: white;
`;

export const TextResult = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
  color: white;
`;
