import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { isEmpty, values } from 'lodash';
import ButtonBack from '../../shared/components/buttons/buttonBack/ButtonBack';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { actions } from '../../store/reducers/geral';
import * as stepsService from '../../shared/services/steps';
import * as journeysService from '../../shared/services/journeys';
import { ModalData } from '../../shared/modals/geral/geral';
import ModalStepDescription from './ModalStepDescription';
import { Tournament } from '../../shared/modals/tournament/Tournament';
import { PlayableChallenge } from '../../shared/constants/types';
import JourneyPath from './JourneyPath';
import JourneyGrid from './JourneyGrid';
import { useCustomStyle, useJourneysHome } from '../../shared/functions/hooks';
import { milestoneReached } from '../../shared/services/tutorial';
import { RootStateGlobal } from '../../store/reducer';
import { actions as userActions } from '../../store/reducers/user';
import { t } from '../../i18n';
import { ERROR_NOT_FOUND } from '../../shared/constants/messages';

export interface JourneyInterface {
  id: string;
  name: string;
  image: string;
  background: string;
  isSingleJourney: boolean;
  blocked: boolean;
}

interface BaseStepInterface {
  id: string;
  title: string;
  description: string;
  status: 'disabled' | 'waiting' | 'enabled' | 'expired' | 'unlocked';
}

export const JOURNEY_GRID_COLUMNS = 3;

export type StepInterface = BaseStepInterface &
  (
    | {
        type: 'Contest';
        target: Tournament;
      }
    | {
        type: 'Challenge';
        target: PlayableChallenge;
      }
  );

const Journey = () => {
  const history = useHistory();
  const location = useLocation();
  const { exitToPath } = location.state || {};
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());
  const { journeyId } = useParams<{ journeyId: string }>();
  const setBlockScreen = async (x: boolean) => dispatch(actions.setBlockScreen(x));
  const setModalData = (x: ModalData) => dispatch(actions.setModal(x));
  const [journey, setJourney] = useState<JourneyInterface>();
  const [steps, setSteps] = useState<StepInterface[]>([]);
  const [stepButtons, setStepButtons] = useState<Record<number, HTMLButtonElement>>({});
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);
  const { textColor } = useCustomStyle();
  const journeysAsHome = useJourneysHome();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  // Reference: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const gridRef = useCallback(
    (node: HTMLElement | null) => {
      if (node && !isEmpty(stepButtons)) {
        setCanvasWidth(node.getBoundingClientRect().width);
        setCanvasHeight(node.getBoundingClientRect().height);
      }
    },
    [stepButtons],
  );

  const smoothlyScrollTo = (offsetTop: number) => {
    for (let index = 0; index < offsetTop; index += 50) {
      setTimeout(() => {
        window.scrollTo(0, index);
      }, index + 300);
    }
  };

  // Scrolls to the further-enabled step
  useEffect(() => {
    values(stepButtons).some(stepButton => {
      if (stepButton.classList.contains('journey__step--enabled')) {
        smoothlyScrollTo(stepButton.offsetTop);
        return true; // breaks the loop
      }

      return false;
    });
  }, [stepButtons]);

  // Due to how the CSS grid works (and how this journey grid was architected),
  // the number of steps has to be multiple of JOURNEY_GRID_COLUMNS so that the
  // first step is always placed in the same position (i.e., bottom-right).
  //
  // Below function ensures the number of steps is multiple of
  // JOURNEY_GRID_COLUMNS by adding `undefined` elements to the array steps
  // list.
  const withFilledEmptySpaces = (stepList: StepInterface[]) => {
    const stepsDisplaced = stepList.length % JOURNEY_GRID_COLUMNS;
    const extraStepsCount = stepsDisplaced ? JOURNEY_GRID_COLUMNS - stepsDisplaced : 0;
    const extraSteps = new Array(extraStepsCount).fill(undefined);

    return stepList.concat(extraSteps);
  };

  // If this is the first journey completed by user, we record the milestone so
  // that we can show proper content such as the tutorial for library unlocked.
  const recordMilestones = (stepList: StepInterface[]) => {
    if (!userData) return;
    if (userData.tutorial.milestones.includes('journeyCompleted')) return;

    const lastStepStatus = `${stepList[stepList.length - 1]?.status}`;
    const isJourneyCompleted = ['unlocked', 'expired'].includes(lastStepStatus);
    if (isJourneyCompleted)
      milestoneReached({ milestoneReached: 'journeyCompleted' }).then(data => {
        dispatch(
          userActions.setUserData({
            ...userData,
            tutorial: data.tutorial,
          }),
        );
      });
  };

  const refreshJourney = async () => {
    setBlockScreen(true);
    try {
      setJourney(await journeysService.get(journeyId));
      const stepList = await stepsService.getAll(journeyId);
      setSteps(withFilledEmptySpaces(stepList));
      recordMilestones(stepList);
    } catch (e) {
      if (e instanceof Error && e.message === ERROR_NOT_FOUND) {
        history.push(MenuUrl.journeysList);
      }
    }
    setBlockScreen(false);
    closeModal();
  };

  useEffect(() => {
    refreshJourney();
  }, []);

  const registerStepButton = (index: number, ref: HTMLButtonElement) => {
    setStepButtons(oldRefs => ({ ...oldRefs, ...{ [index]: ref } }));
  };

  const handleStepClicked = (step: StepInterface, i: number) => {
    setModalData({
      show: true,
      children: (
        <ModalStepDescription
          step={step}
          history={history}
          i={i}
          exitToPath={`${MenuUrl.journeysList}/${journeyId}/play`}
          refreshJourney={refreshJourney}
        />
      ),
      height: 'inherit',
      title: t('more'),
    });
  };

  if (!journey || !steps) {
    return null;
  }

  return (
    <div id="container" style={{ position: 'relative' }}>
      {!(journeysAsHome && journey.isSingleJourney) && (
        <ButtonBack urlBack={exitToPath || MenuUrl.home} />
      )}
      <div id="page-title">{journey.name}</div>
      <div className="journey__title" style={textColor}>
        {t('journeyText')}
      </div>
      <div id="container" style={{ maxWidth: '420px', margin: '0 auto' }}>
        <JourneyPath
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          stepButtons={stepButtons}
        />
        <JourneyGrid
          steps={steps}
          registerStepButton={registerStepButton}
          handleStepClicked={handleStepClicked}
          ref={gridRef}
          background={journey.background}
        />
      </div>
    </div>
  );
};

export default Journey;
