import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

export interface ChallengeParamsInterface {
  answer: string;
  comment?: string;
}

export const getAll = async (journeyId: string) => {
  try {
    const response = await ConnectApiGet(urls.JOURNEY_STEPS_URL.replace(':journeyId', journeyId));
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }

  return [];
};
