import React, { ElementType, useMemo, useState } from 'react';
import { includes } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { IconButton, InputAdornment, InputBaseComponentProps } from '@material-ui/core';
import InputMask from 'react-input-mask';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '../../shared/components/buttons/Button';
import { handleChangeFieldValue } from '../../shared/functions/handlers';
import { colors } from '../../shared/functions/colors';
import { passiveAcceptancesRenderer } from './SelfSignUp';
import { t } from '../../i18n';
import { ImageFlagContainer } from '../login/styles';
import { LanguageSelector } from '../../shared/components/languageSelector/LanguageSelector';

export interface User {
  name: string;
  cpf?: string;
  whatsapp?: string;
  email?: string;
  image?: string;
  password: string;
  areaIds: string;
  referrerId?: string;
  termsOfUse?: boolean;
  privacyPolicy?: boolean;
  rules?: boolean;
  googleTokenId?: string;
}

export const newUser: User = {
  name: '',
  cpf: undefined,
  whatsapp: undefined,
  email: undefined,
  password: '',
  areaIds: '',
  referrerId: '',
  termsOfUse: false,
  privacyPolicy: false,
  rules: false,
};

export interface SelfSignUpCampaignInterface {
  name: string;
  imageUrl: string;
  areas: {
    id: string;
    name: string;
  }[];
  fields: {
    id: string;
    name: keyof User;
    label: string;
    required: boolean;
    fieldType: string;
    mask: string;
    url?: string;
  }[];
  companyId: string;
  companyName: string;
}

interface UserCampaignFormInterface {
  handleSubmission: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>;
  campaign: SelfSignUpCampaignInterface;
  error: Partial<User>;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  loading: boolean;
  user: User;
  submitBtnText: string;
  ignoreFields?: string[];
}

const PHONE_MASK = [
  '(',
  /\d/i,
  /\d/i,
  ')',
  ' ',
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
  /\d/i,
];

const FIELD_TYPE_MASK_MAP: Record<string, string | (string | RegExp)[]> = {
  phone: PHONE_MASK,
  cpf: '999.999.999-99',
};

const useStyles = makeStyles(() => ({
  input: {
    color: colors.black,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0.15px',
    '& label': {
      color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: '0.15px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-root': {
      color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',

      '&.Mui-focused': {
        color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
      },
    },
  },
}));

const UserCampaignForm = ({
  campaign,
  ignoreFields,
  error,
  loading,
  user,
  submitBtnText,
  setUser,
  handleSubmission,
}: UserCampaignFormInterface) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const areaOptions = useMemo(
    () =>
      campaign.areas.map(area => ({
        value: area.id,
        id: area.id,
        name: area.name,
      })),
    [campaign.areas],
  );

  const isSubmissionDisabled = () => {
    const isSomeOpenFieldBlank = campaign.fields
      .filter(field => field.fieldType !== 'acceptance')
      .filter(field => !includes(ignoreFields, field.name))
      .some(field => !user[field.name as keyof User]);
    const isSelectBlank = !user.areaIds;
    const emailField = campaign.fields.find(field => field.name === 'email');
    const isEmailInvalid = emailField && !user.email?.includes('@');

    return isSomeOpenFieldBlank || isSelectBlank || isEmailInvalid;
  };

  const useSelectStoreLabel = [
    //EMPRESAS NA QUAL O LABEL É ALTERADO
    'aeaddfe0-93f4-451f-b3d7-42689ec20d51',
    '2dd01885-9b63-4a8a-8a24-dfe9ca263450',
    '74d4dc32-9e7d-43e4-807a-0f26ee363287',
    '43fed9b6-0fbd-4a40-9361-6a2af0de0e67',
    '209e21a5-9fe7-44f1-89f9-42b0658d8145',
  ].includes(campaign.companyId);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <>
      <ImageFlagContainer
        style={{ position: 'absolute', display: window.innerWidth <= 650 ? 'none' : 'flex' }}
      >
        <LanguageSelector />
      </ImageFlagContainer>
      <form onSubmit={handleSubmission}>
        {campaign.fields
          .filter(field => field.fieldType !== 'acceptance' && !includes(ignoreFields, field.name))
          .map(field => {
            let endAdornmentIcon = null;
            if (field.name === 'email') {
              endAdornmentIcon = <EmailOutlinedIcon sx={{ color: colors.purpleRegular }} />;
            } else if (field.name === 'whatsapp') {
              endAdornmentIcon = <WhatsAppIcon sx={{ color: colors.purpleRegular }} />;
            } else if (field.name === 'password') {
              endAdornmentIcon = (
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ color: colors.purpleRegular }} />
                  ) : (
                    <VisibilityIcon sx={{ color: colors.purpleRegular }} />
                  )}
                </IconButton>
              );
            }
            return (
              <TextField
                label={field.label}
                id={field.name}
                name={field.name}
                type={field.fieldType}
                error={!!error[field.name]}
                helperText={error[field.name] || ''}
                style={{
                  marginBottom: 16,
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '4px 4px 0 0',
                }}
                key={field.id}
                variant="filled"
                InputProps={{
                  endAdornment: endAdornmentIcon ? (
                    <InputAdornment position="end">{endAdornmentIcon}</InputAdornment>
                  ) : (
                    undefined
                  ),
                  type: field.fieldType === 'password' && !showPassword ? 'password' : 'text',
                  inputComponent: (InputMask as unknown) as
                    | ElementType<InputBaseComponentProps>
                    | undefined,
                  inputProps: {
                    mask: FIELD_TYPE_MASK_MAP[field.fieldType],
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                onChange={event => handleChangeFieldValue(event, setUser)}
                value={user[field.name] as string}
              />
            );
          })}

        {campaign.areas.length > 1 && (
          <>
            <AutoComplete
              options={areaOptions}
              getOptionLabel={option => option.name}
              onChange={(event, value) => {
                setUser(prevValue => ({ ...prevValue, areaIds: value?.id || '' }));
              }}
              classes={{
                input: classes.input,
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="areaIds"
                  label={
                    <span
                      style={{
                        color: colors.black,
                        fontFamily: 'Poppins-Regular',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 14,
                      }}
                    >
                      {useSelectStoreLabel ? t('selectStore') : t('area')}
                    </span>
                  }
                  variant="filled"
                  key={params.id}
                  style={{
                    border: 'none',
                    borderRadius: 4,
                    backgroundColor: colors.white,
                  }}
                />
              )}
            />
            <br />
          </>
        )}

        <Button loading={loading} disabled={isSubmissionDisabled()} style={{ marginTop: 36 }}>
          {submitBtnText}
        </Button>
      </form>
      {passiveAcceptancesRenderer(campaign)}
    </>
  );
};

UserCampaignForm.defaultProps = {
  ignoreFields: [],
};

export default UserCampaignForm;
