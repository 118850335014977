export const secondToStringClock = (seconds: number) => {
  let returnHours = 0;
  let returnMinutes = 0;
  let returnSeconds = 0;

  if (seconds >= 3600) {
    returnHours = parseInt(`${seconds / 3600}`, 10);
    returnMinutes = parseInt(`${(seconds % 3600) / 60}`, 10);
    returnSeconds = seconds % 60;
  } else if (seconds >= 60) {
    returnMinutes = parseInt(`${seconds / 60}`, 10);
    returnSeconds = seconds % 60;
  } else {
    returnSeconds = seconds;
  }
  return `${returnHours > 9 ? returnHours : `0${returnHours}`}:${
    returnMinutes > 9 ? returnMinutes : `0${returnMinutes}`
  }:${returnSeconds > 9 ? returnSeconds : `0${returnSeconds}`}`;
};

export const verifyDateStringBeforeNow = (dateString?: string) => {
  if (!dateString) return false;
  const date = new Date(dateString);

  return date.getTime() < new Date().getTime();
};

export const dateTimeStampToStringDate = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const newDate = new Date(date);
  const day = newDate.getDate().toString();
  const dayF = day.length === 1 ? `0${day}` : day;
  const month = (newDate.getMonth() + 1).toString();
  const monthF = month.length === 1 ? `0${month}` : month;
  const yearF = newDate.getFullYear();

  return `${dayF}/${monthF}/${yearF}`;
};

export const dateTimeStampToString = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const newDate = new Date(date);
  const noTimeDate = dateTimeStampToStringDate(date);

  const hours = `0${newDate.getHours()}`;
  const minutes = `0${newDate.getMinutes()}`;

  return `${noTimeDate} - ${hours.substr(-2)}:${minutes.substr(-2)}`;
};
