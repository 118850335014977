export type MediaType =
  | 'video'
  | 'website'
  | 'image'
  | 'pdf'
  | 'downloadable'
  | 'self-hosted-video'
  | 'scorm';

export const isVideo = (mediaType: MediaType) => mediaType === 'video';
export const isSelfHostedVideo = (mediaType: MediaType) => mediaType === 'self-hosted-video';
export const isWebsite = (mediaType: MediaType) => mediaType === 'website';
export const isImage = (mediaType: MediaType) => mediaType === 'image';
export const isPdf = (mediaType: MediaType) => mediaType === 'pdf';
export const isDownloadable = (mediaType: MediaType) => mediaType === 'downloadable';
export const isScorm = (mediaType: MediaType) => mediaType === 'scorm';
