import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

interface SupportButtonInterface {
  isLoggedIn: boolean;
}

export const SupportButton = styled.button<SupportButtonInterface>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  color: ${colors.white};
  font-family: ${fonts.regular};
  z-index: 1;

  @media only screen and (max-width: 720px) {
    width: 35px;
    font-size: 12px;
    bottom: ${({ isLoggedIn }) => (isLoggedIn ? 70 : 10)}px;
    right: 10px;
  }

  @media only screen and (min-width: 650px) {
    width: 41px;
    bottom: 70px;
    right: 20px;
  }

  @media only screen and (min-width: 1200px) {
    width: 41px;
    bottom: 40px;
    right: 40px;
  }
`;
