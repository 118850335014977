import { t } from '../../i18n';

export const CATEGORIES = {
  LOGIN: 'Login',
  SELF_SIGN_UP: t('sso'),
};

export const ACTIONS_SELF_SIGN_UP = {
  OPEN: t('ssoOpen'),
};

export const ACTIONS_LOGIN = {
  OPEN: t('loginOpen'),
};
