/* eslint-disable import/no-cycle */
import { doLogout, getTokenValue } from './connection/auth';

const objetoJwt = () => {
  let tokenValor = null;
  try {
    tokenValor = getTokenValue().split('.');
  } catch (e) {
    return null;
  }
  if (!tokenValor || tokenValor.length <= 1) return null;
  return JSON.parse(atob(tokenValor[1]));
};

export const userNameJwt = () => {
  try {
    const corpoJwt = objetoJwt();
    if (corpoJwt?.idUser) return corpoJwt.idUser;
    return '';
  } catch (e) {
    doLogout();
    return '';
  }
};

export const expiresTokenJwt = () => {
  const corpoJwt = objetoJwt();
  if (corpoJwt?.expiresToken) return corpoJwt.expiresToken;
  return null;
};
