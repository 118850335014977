/* eslint-disable react/no-danger */
import { History } from 'history';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { useCustomStyle } from '../../../shared/functions/hooks';
import { actions } from '../../../store/reducers/geral';
import { tournamentActions } from '../../../store/reducers/tournament';

import { Container, Description } from './styles';
import { t } from '../../../i18n';

interface PModalLeave {
  history: History;
  exitToPath: string;
}

const ModalLeave = ({ history, exitToPath }: PModalLeave) => {
  const dispatch = useDispatch();
  const { primaryColor } = useCustomStyle();

  const closeModal = () => {
    dispatch(actions.closeModal());
  };

  const closeModalAndLeave = () => {
    history.push(exitToPath);
    dispatch(actions.closeModal());
    dispatch(tournamentActions.clearQuestion());
    dispatch(tournamentActions.clearMatch());
    dispatch(tournamentActions.clearTournament());
  };

  return (
    <Container>
      <Description>
        <p>{t('modalLeaveMessage1')}</p>
        <p />
        <p dangerouslySetInnerHTML={{ __html: t('modalLeaveMessage2') }} />
      </Description>
      <Button onClick={closeModal} customBackground={primaryColor.color}>
        {t('continuePlaying')}
      </Button>
      <Button
        typeButton="primary-white"
        onClick={closeModalAndLeave}
        customBackground={primaryColor.color}
        style={{ marginTop: '16px' }}
      >
        {t('abandonMatch')}
      </Button>
    </Container>
  );
};

export default ModalLeave;
