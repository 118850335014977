import React from 'react';
import { useSelector } from 'react-redux';
import { PlayableChallenge } from '../../shared/constants/types';
import OpenQuestionForm from './forms/OpenQuestionForm';
import UploadFileForm from './forms/UploadFileForm';
import NPS from './forms/NPS';
import { ChallengeParamsInterface } from '../../shared/services/challenges';
import RichTextEditor from '../richEditor/RichTextEditor';
import { t } from '../../i18n';
import { RootStateGlobal } from '../../store/reducer';

interface ChallengeProps {
  challenge: PlayableChallenge;
  title: string;
  customBackground?: string;
  handleSubmission: (params: ChallengeParamsInterface, challengeId: string) => void;
}

const Challenge = ({
  challenge,
  title,
  customBackground = '',
  handleSubmission,
}: ChallengeProps) => {
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  const buildForm = (challenge: PlayableChallenge) => {
    switch (challenge.mechanics.id) {
      case 1:
        return (
          <OpenQuestionForm
            challenge={challenge}
            handleSubmission={(params: ChallengeParamsInterface) =>
              handleSubmission(params, challenge.id)
            }
            customBackground={customBackground}
          />
        );
      case 2:
        return (
          <UploadFileForm
            challenge={challenge}
            handleSubmission={(params: ChallengeParamsInterface) =>
              handleSubmission(params, challenge.id)
            }
            customBackground={customBackground}
          />
        );
      case 3:
        return (
          <NPS
            challenge={challenge}
            handleSubmission={(params: ChallengeParamsInterface) =>
              handleSubmission(params, challenge.id)
            }
            customBackground={customBackground}
          />
        );
      default:
        return null;
    }
  };

  if (!userData) return null;

  // TODO: remove this check once all companies are migrated
  return userData.company.migrated ? (
    <div className="challenge-form">
      <div className="challenge-form__title">{title}</div>
      <div className="challenge-form__description">
        <p className="journey__modal journey__modal-description-label">{t('description')}</p>
        <RichTextEditor value={challenge.instructions} />
      </div>
      <div className="challenge-form__instructions">
        <RichTextEditor value={challenge.question.text} />
      </div>
      {buildForm(challenge)}
    </div>
  ) : (
    <div className="challenge-form">
      <div className="challenge-form__title">{title}</div>
      <div className="challenge-form__description">
        <p className="journey__modal journey__modal-description-label">{t('description')}</p>
        <RichTextEditor value={challenge.question.text} />
      </div>
      <div className="challenge-form__instructions">
        <RichTextEditor value={challenge.instructions} />
      </div>
      {buildForm(challenge)}
    </div>
  );
};

Challenge.defaultProps = {
  customBackground: '',
};

export default Challenge;
