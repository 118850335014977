import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { CredentialResponse, GoogleLogin, GsiButtonConfiguration } from '@react-oauth/google';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { noop } from 'lodash';
import { SSODataInterface } from '../self_sign_up/SelfSignUp';

interface GoogleSSOInterface {
  text: GsiButtonConfiguration['text'];
  loading: boolean;
  callback: (ssoData: SSODataInterface) => void;
}

interface GoogleSSOTokenData {
  // aud: process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // azp: process.env.REACT_APP_GOOGLE_CLIENT_ID;
  iss: 'https://accounts.google.com';
  nbf: number;
  sub: string;
  email: string;
  email_verified: boolean;
  name: string;
  picture?: string;
  given_name: string;
  family_name: string;
  iat: number;
  exp: number;
  jti: string;
}

// TODO: Reimplement the loading logic
const GoogleSSO = ({ text, loading, callback }: GoogleSSOInterface) => {
  const onSuccess = (credentialResponse: CredentialResponse) => {
    const { credential: googleTokenId } = credentialResponse;

    if (googleTokenId) {
      const { name, email, picture: image }: GoogleSSOTokenData = jwt_decode(googleTokenId);
      callback({ name, email, image, googleTokenId });
    }
  };

  const ErrorFallback = () => null;

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={error => {
          console.log('ErrorBoundary', error);
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleLogin text={text} onSuccess={onSuccess} onError={noop} />
        </div>
      </ErrorBoundary>
    </>
  );
};

export default GoogleSSO;
