import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router';
import { noop } from 'lodash';
import { JourneyInterface } from '../../../features/journey/Journey';
import * as journeysService from '../../services/journeys';
import { colors } from '../../functions/colors';
import Button from '../buttons/Button';
import { useCustomStyle, useJourneysHome } from '../../functions/hooks';
import { MenuUrl } from '../../enums/menuUrl';
import { t } from '../../../i18n';

interface PJourneyCard {
  journey: JourneyInterface;
  setShowDependencies: (x: boolean) => void;
}

const JourneyDependencies = ({ journey, setShowDependencies }: PJourneyCard) => {
  const [dependencies, setDependencies] = useState<JourneyInterface[]>([]);
  const { primaryColor } = useCustomStyle();
  const history = useHistory();
  const journeyAsHome = useJourneysHome();

  useEffect(() => {
    (async () => {
      setDependencies(await journeysService.getJourneyDependencies(journey.id));
    })();
  }, []);

  const handleOpenJourney = (id: string) => {
    history.push(`${MenuUrl.journeysList}/${id}/play`, {
      exitToPath: journeyAsHome ? MenuUrl.home : MenuUrl.journeysList,
    });
  };

  return (
    <Modal open style={{ transition: 'opacity .5s ease-out', opacity: 1 }}>
      <>
        <div
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'relative',
            maxWidth: 720,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              borderRadius: 10,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                style={{
                  textAlign: 'right',
                  cursor: 'pointer',
                  background: colors.white,
                  width: 31,
                  height: 31,
                  marginBottom: -23,
                  borderRadius: '50%',
                  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setShowDependencies(false)}
              >
                x
              </div>
            </div>
            <div
              style={{
                backgroundColor: colors.white,
                color: colors.black,
                borderRadius: 10,
                padding: 24,
                margin: '0px 8px',
              }}
            >
              <div style={{ fontSize: 16, marginBottom: 24 }}>{t('unlockJourneys')}</div>
              {dependencies
                .sort(a => (a.blocked ? 1 : -1)) // sort by blocked
                .map(dependency => (
                  <Button
                    key={dependency.id}
                    style={{
                      fontSize: '10px',
                      fontWeight: '600',
                      height: 32,
                      marginBottom: 8,
                      backgroundColor: dependency.blocked
                        ? colors.grey100
                        : primaryColor.color || colors.purpleRegular,
                    }}
                    onClick={() => (dependency.blocked ? noop : handleOpenJourney(dependency.id))}
                    icon="chevronRight"
                  >
                    <div
                      style={{
                        padding: 8,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {dependency.name}
                    </div>
                  </Button>
                ))}
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default JourneyDependencies;
