import React from 'react';
import { colors } from '../../shared/functions/colors';
import { CheckoutProduct } from '../../shared/modals/checkoutProduct/checkoutProduct';

interface ProductDetailProps {
  checkoutProduct: CheckoutProduct;
}

export const ProductDetail = ({ checkoutProduct }: ProductDetailProps) => {
  return (
    <div>
      <h1 style={{ color: colors.white }}>{checkoutProduct.name}</h1>
      <h2 style={{ color: colors.white }}>12 x R$ 59,90</h2>
      {/* ToDo: adjust below to proper value from backend */}
      <p style={{ color: colors.white }}>ou R$ 699,00 à vista</p>
      <img src={checkoutProduct.imageUrl} alt="Product" style={{ maxWidth: '100%' }} />
      <p style={{ color: colors.white }}>{checkoutProduct.description}</p>
      {/* ToDo: make sure below is within proper margins */}
      <div style={{ position: 'absolute', bottom: 0 }}>
        <p style={{ color: colors.white }}>O valor do parcelamento possui acréscimos.</p>
        {/* ToDo: adjust below to proper value */}
        <p style={{ color: colors.white }}>Termos de compra e politicas de privacidades...</p>
      </div>
    </div>
  );
};
