import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { FaChevronDown } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { RootStateGlobal } from '../../../store/reducer';
import { MenuUrl } from '../../enums/menuUrl';
import { colors } from '../../functions/colors';
import { setAuthorizationToken } from '../../functions/connection/auth';
import { useCustomStyle } from '../../functions/hooks';
import { getImageCompany } from '../../functions/imageCompany';
import Coins from './Coins';
import { actions as geralActions } from '../../../store/reducers/geral';

import { Container, BoxCashAndMenu, Icon, Wrapper } from './styles';
import LateralMenu from './lateralMenu/LateralMenu';
import Alerts from './Alerts';

const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isPresentationPage = location.pathname.includes('/presentation');
  const [openCompanySwitcher, setOpenCompanySwitcher] = useState(false);
  const { displayLogo, backgroundPrimary } = useCustomStyle();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  if (!userData) return null;

  const { companies } = userData;
  const isCompanySwitchEnabled = companies.length > 0;

  const switchCompany = (token: string) => {
    dispatch(geralActions.setBlockScreen(true));
    setAuthorizationToken(token);
    window.location.href = '/home';
    setOpenCompanySwitcher(false);
  };

  const renderCompanySwitcherList = () => {
    const companiesCount = companies.length;

    return (
      <div
        id="switcher-list"
        style={{
          border: `1px solid ${colors.grey100}`,
          borderRadius: 6,
          marginTop: 8,
          display: openCompanySwitcher ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 8,
          backgroundColor: backgroundPrimary.backgroundColor || colors.background,
        }}
      >
        {companies.map(({ logo, name, token }, i) => (
          <>
            <div
              style={{
                display: 'flex',
                placeContent: 'center',
                cursor: 'pointer',
              }}
            >
              <img
                style={{
                  height: 32,
                  width: 'auto',
                  maxWidth: '170px',
                }}
                key={logo}
                onClick={() => switchCompany(token)}
                src={logo}
                alt={name}
                aria-hidden
              />
            </div>
            {i + 1 < companiesCount && (
              <div
                style={{
                  height: 1,
                  width: '100%',
                  margin: '12px auto',
                  background: colors.grey100,
                }}
              />
            )}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <LateralMenu
        switcherContent={
          <div
            id="menuSwitcher"
            style={{ display: 'flex', flexDirection: 'column', margin: '28px 0 70px 0' }}
          >
            <Wrapper
              style={{ alignSelf: 'center' }}
              onClick={() =>
                isCompanySwitchEnabled
                  ? setOpenCompanySwitcher(!openCompanySwitcher)
                  : history.push(MenuUrl.home)
              }
            >
              <Icon
                src={getImageCompany()}
                alt=""
                style={{
                  display: displayLogo.display,
                  maxHeight: '55px',
                  maxWidth: '190px',
                  height: 'auto',
                }}
              />
              {isCompanySwitchEnabled && (
                <IconContext.Provider
                  value={{
                    color: 'white',
                    style: { marginLeft: 8, height: 'auto' },
                  }}
                >
                  <FaChevronDown />
                </IconContext.Provider>
              )}
            </Wrapper>
            {renderCompanySwitcherList()}
          </div>
        }
      />
      <Container style={isPresentationPage ? { display: 'none' } : backgroundPrimary}>
        <div id="switcher" style={{ display: 'flex', flexDirection: 'column' }}>
          <Wrapper
            style={{ display: window.innerWidth >= 900 ? 'none' : 'flex' }}
            onClick={() =>
              isCompanySwitchEnabled
                ? setOpenCompanySwitcher(!openCompanySwitcher)
                : history.push(MenuUrl.home)
            }
          >
            <Icon src={getImageCompany()} alt="" style={displayLogo} />
            {isCompanySwitchEnabled && (
              <IconContext.Provider
                value={{
                  color: 'white',
                  style: { marginLeft: 8, height: 'auto' },
                }}
              >
                <FaChevronDown />
              </IconContext.Provider>
            )}
          </Wrapper>
          {renderCompanySwitcherList()}
        </div>
        <BoxCashAndMenu>
          <Alerts />
          <Coins />
        </BoxCashAndMenu>
      </Container>
    </>
  );
};

export default Menu;
