import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';

import { SnackbarData } from '../../../shared/modals/geral/geral';
import * as serviceUser from '../../../shared/services/serviceUser';
import { actions, actions as geralAction } from '../../../store/reducers/geral';
import { Box } from '../../../shared/components/styles/styledBox';
import { BodyPasswordToken } from '../../../shared/modals/user/bodies';
import { Container, ImgLogo, Text, Title } from './styles';
import { ERROR_NOT_FOUND } from '../../../shared/constants/messages';
import { t } from '../../../i18n';

const INITIAL_BODY = {
  email: '',
  passwordToken: '',
  password: '',
};

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());
  const setSnackbarData = (x: SnackbarData) => dispatch(actions.setSnackbarData(x));
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const [bodyPasswordToken, setBodyPasswordToken] = useState<BodyPasswordToken>(INITIAL_BODY);
  const [confirmPassword, setConfirmParssword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setBodyPasswordToken({
      ...bodyPasswordToken,
      email: event.target.value,
    });
  };

  const handleChangePasswordToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setBodyPasswordToken({
      ...bodyPasswordToken,
      passwordToken: event.target.value,
    });
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setBodyPasswordToken({
      ...bodyPasswordToken,
      password: event.target.value,
    });
  };

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setConfirmParssword(event.target.value);
  };

  const handleSendNewPassword = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await serviceUser.redefinePass(bodyPasswordToken);
      closeModal();
      setSnackbarData({
        message: t('changePasswordSuccess'),
        show: true,
        type: 'success',
      });
      setBodyPasswordToken(INITIAL_BODY);
      setStep(0);
    } catch (error) {
      setErrorCode(true);
    }
    setLoading(false);
  };

  const handleVerifyToken = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await serviceUser.verifyPassCode(bodyPasswordToken);
      setStep(2);
    } catch (error) {
      setErrorCode(true);
    }
    setLoading(false);
  };

  const handleSendPass = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await serviceUser.sendPassCode(bodyPasswordToken);
      setStep(1);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === ERROR_NOT_FOUND) {
          dispatch(
            geralAction.setSnackbarData({
              message: t('mailNotFound'),
              show: true,
              type: 'error',
            }),
          );
        }
      }
      setErrorCode(true);
    }
    setLoading(false);
  };

  const renderNewPassword = () => (
    <>
      <form onSubmit={handleSendNewPassword}>
        <Title>{t('forgotMyPassword')}</Title>
        <Text>{t('newPasswordText')}</Text>
        <Input
          title={t('newPassword')}
          placeholder={t('placeholderType')}
          typeStyle="white"
          onChange={handleChangePassword}
          value={bodyPasswordToken.password}
          style={{ marginBottom: 16 }}
          error={errorCode}
          type="password"
        />
        <Input
          title={t('confirmNewPassword')}
          placeholder={t('placeholderType')}
          typeStyle="white"
          onChange={handleConfirmPassword}
          value={confirmPassword}
          style={{ marginBottom: 16 }}
          error={errorCode}
          type="password"
        />

        <Button
          loading={loading}
          disabled={confirmPassword !== bodyPasswordToken.password || confirmPassword.length <= 3}
          style={{ marginBottom: 16 }}
        >
          {t('confirm')}
        </Button>
      </form>
      <Button onClick={() => setStep(1)} typeButton="primary-white" type="button">
        {t('back')}
      </Button>
    </>
  );

  const renderVerifyCode = () => (
    <>
      <form onSubmit={handleVerifyToken}>
        <Title>{t('forgotMyPassword')}</Title>
        <Text>{t('passwordToken', { token: bodyPasswordToken.email })}</Text>
        <Input
          title={t('verifyCode')}
          placeholder={t('placeholderType')}
          typeStyle="white"
          onChange={handleChangePasswordToken}
          value={bodyPasswordToken.passwordToken}
          type="number"
          style={{ marginBottom: 16 }}
          messageError={t('invalidCode')}
          error={errorCode}
        />

        <Button loading={loading} style={{ marginBottom: 16 }}>
          {t('validate')}
        </Button>
      </form>
      <Button onClick={() => setStep(0)} typeButton="primary-white" type="button">
        {t('back').toUpperCase()}
      </Button>
    </>
  );

  const renderSendCode = () => (
    <form onSubmit={handleSendPass}>
      <Title>{t('forgotMyPassword')}</Title>
      <Text>{t('infoMail')}</Text>
      <Input
        title="E-mail:"
        placeholder={t('placeholderType')}
        typeStyle="white"
        onChange={handleChangeEmail}
        value={bodyPasswordToken.email}
        type="email"
        style={{ marginBottom: 16 }}
        error={errorCode}
      />
      <Button loading={loading}>{t('send')}</Button>
      <Button
        style={{ marginTop: 16 }}
        typeButton="primary-white"
        type="button"
        onClick={closeModal}
      >
        {t('back').toUpperCase()}
      </Button>
    </form>
  );

  const renderSteps = () => {
    switch (step) {
      case 0:
        return renderSendCode();
      case 1:
        return renderVerifyCode();
      case 2:
        return renderNewPassword();
      default:
        return null;
    }
  };

  return (
    <Container>
      <Box>
        <ImgLogo src="/images/forgetPassword.png" />
        {renderSteps()}
      </Box>
    </Container>
  );
};

export default ForgetPassword;
