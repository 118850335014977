export enum LeagueTypes {
  SET_SEASON = '@league/SET_SEASON',
  SET_UPDATED_RANKING = '@league/SET_UPDATED_RANKING',
  DISABLE_LEAGUE = '@league/DISABLE_LEAGUE',
}

interface League {
  id: number;
  name: string;
  promotable: number;
  relegatable: number;
  passed: boolean;
  current: boolean;
}

export interface SeasonResult {
  status: string;
  position: string;
  newLeague: string;
}

export interface Position {
  name: string;
  image: string;
  score: number;
  time: number;
  currentUser: boolean;
}

export interface LeagueConfig {
  readonly enabled: boolean;
  readonly leagues: League[];
  readonly seasonRanking: Position[];
  readonly roseUp: boolean;
  readonly timeLeft: string;
  readonly lastSeasonResult?: SeasonResult;
}
