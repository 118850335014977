import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import '@brainhubeu/react-carousel/lib/style.css';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { actions as geralActions } from '../../store/reducers/geral';
import { RootStateGlobal } from '../../store/reducer';
import { actions as userActions } from '../../store/reducers/user';
import { JourneyInterface } from '../journey/Journey';
import * as journeysService from '../../shared/services/journeys';
import JourneyList from '../journey/JourneyList';

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const setBlockScreen = async (x: boolean) => dispatch(geralActions.setBlockScreen(x));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [, setJourneys] = useState<JourneyInterface[]>([]);

  useEffect(() => {
    if (userData?.firstAccess && userData.email.split('@').length > 1) {
      history.push(MenuUrl.updatePassword);
    } else if (userData?.firstAccess) {
      dispatch(
        userActions.setUserData({
          ...userData,
          firstAccess: false,
        }),
      );
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      setBlockScreen(true);
      setJourneys(await journeysService.getAll({}));
      setBlockScreen(false);
    })();
  }, []);

  return (
    <>
      <JourneyList />
    </>
  );
};

export default Home;
