import { History } from 'history';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../shared/components/buttons/Button';
import { useCustomStyle } from '../../shared/functions/hooks';
import { updateProfileVisibility } from '../../shared/services/leagues';
import { actions } from '../../store/reducers/geral';
import * as leagueActions from '../../store/reducers/league/actions';

import { Container, Description } from '../tournament/modalLeave/styles';
import { t } from '../../i18n';

interface PModalLeave {
  history: History;
  exitToPath: string;
}

const ModalLeave = ({ history, exitToPath }: PModalLeave) => {
  const dispatch = useDispatch();
  const { primaryColor } = useCustomStyle();

  const closeModal = () => {
    dispatch(actions.closeModal());
  };

  const closeModalAndLeave = async () => {
    await updateProfileVisibility(false);
    dispatch(leagueActions.disableLeague());
    dispatch(actions.closeModal());
    history.push(exitToPath);
  };

  return (
    <Container>
      <Description>
        <p>{t('confirmLeaguesExit')}</p>
        <p />
        <p>{t('pointsInfo')}</p>
      </Description>
      <Button customBackground={primaryColor.color} onClick={closeModal}>
        {t('keepLeagues')}
      </Button>
      <Button
        typeButton="primary-white"
        onClick={closeModalAndLeave}
        style={{ marginTop: '16px' }}
        customBackground={primaryColor.color}
      >
        {t('exitLeagues')}
      </Button>
    </Container>
  );
};

export default ModalLeave;
