import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 420px;
  width: 100%;
  text-align: center;

  @media (min-width: 650px) {
    max-width: 500px;
  }

  @media (min-width: 1024px) {
    max-width: 1002px;
    padding: 0 28px;
  }
`;
