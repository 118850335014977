import * as React from 'react';
import { colors } from '../functions/colors';

interface PLockIcon {
  width?: number;
  height?: number;
}

const LockIcon = ({ width, height }: PLockIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2786_89160"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="0"
      width="12"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3337 5.33317H12.0003C12.7337 5.33317 13.3337 5.93317 13.3337 6.6665V13.3332C13.3337 14.0665 12.7337 14.6665 12.0003 14.6665H4.00033C3.26699 14.6665 2.66699 14.0665 2.66699 13.3332V6.6665C2.66699 5.93317 3.26699 5.33317 4.00033 5.33317H4.66699V3.99984C4.66699 2.15984 6.16033 0.666504 8.00033 0.666504C9.84032 0.666504 11.3337 2.15984 11.3337 3.99984V5.33317ZM8.00033 1.99984C6.89366 1.99984 6.00033 2.89317 6.00033 3.99984V5.33317H10.0003V3.99984C10.0003 2.89317 9.10699 1.99984 8.00033 1.99984ZM4.00033 13.3332V6.6665H12.0003V13.3332H4.00033ZM9.33366 9.99984C9.33366 10.7332 8.73366 11.3332 8.00033 11.3332C7.26699 11.3332 6.66699 10.7332 6.66699 9.99984C6.66699 9.2665 7.26699 8.6665 8.00033 8.6665C8.73366 8.6665 9.33366 9.2665 9.33366 9.99984Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2786_89160)">
      <rect width="16" height="16" fill={colors.grey100} />
    </g>
  </svg>
);

LockIcon.defaultProps = {
  width: 16,
  height: 16,
};

export default LockIcon;
