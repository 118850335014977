import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PFullScreenIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const FullScreenIcon = ({ width, height, style, color }: PFullScreenIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <mask
      id="fullscreen-icon"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="20"
      height="20"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        d="M1 1v6h2V3h4V1H1zm2 12H1v6h6v-2H3v-4zm14 4h-4v2h6v-6h-2v4zm0-16h-4v2h4v4h2V1h-2z"
        fill="#8c29b0"
      />
    </mask>
    <g mask="url(#fullscreen-icon)">
      <rect width="20" height="20" fill={color} />
    </g>
  </svg>
);

FullScreenIcon.defaultProps = {
  width: 20,
  height: 20,
  color: colors.white,
  style: {},
};

export default FullScreenIcon;
