export enum colors {
  white = '#FFFFFF',
  green = '#05A357',
  orange = '#FFC043',
  red = '#E11900',
  gray = '#CCC',
  grey80 = '#B1BBC2',
  grey100 = '#929898',
  greyf0 = '#F0F0F0',
  grey60 = '#606060',
  black = '#262824',
  black1f = '#1F1F1F',
  background = '#181818',
  purpleRegular = '#7500E5',
  link = '#3b5998',
}

export default colors;
