import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import BoxAwardAcquired from '../boxAwardAcquired/BoxAwardAcquired';
import { Container, ContainerList } from './styles';

const AcquiredAward = () => {
  const { acquiredAward } = useSelector((state: RootStateGlobal) => state.awardReducer);

  return (
    <Container>
      <ContainerList>
        {acquiredAward.map(award => (
          <BoxAwardAcquired award={award} key={`award_${award.idAward}_${award.dateAcquired}`} />
        ))}
      </ContainerList>
    </Container>
  );
};

AcquiredAward.defaultProps = {
  supressHelpText: false,
};

export default AcquiredAward;
