import { Dispatch } from 'redux';

import * as serviceCompany from '../../../shared/services/companyPreferenceStyles';
import * as companyActions from './actions';

import { ERROR_MAINTENANCE } from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setImageCompany } from '../../../shared/functions/imageCompany';
import { setStylesCompany, setShowWhatsappIcon } from '../../../shared/functions/stylesCompany';

export const getCompanyStyle = ({ subdomain = '' }) => async (dispatch: Dispatch) => {
  try {
    const companyStyles = await serviceCompany.getCompanyPreferenceStyles({ subdomain });
    dispatch(companyActions.setCompanyPreferenceStyles(companyStyles));
    setImageCompany(companyStyles?.logo);
    setStylesCompany(JSON.stringify(companyStyles?.live));
    if (companyStyles.showWhatsapp === true || companyStyles.showWhatsapp === false) {
      setShowWhatsappIcon(JSON.stringify(companyStyles));
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};
