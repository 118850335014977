import { Award } from '../../../shared/modals/award/award';
import { AwardTypes } from './awardTypes';

export const setAcquiredAward = (acquiredAward: Award[]) => ({
  type: AwardTypes.SET_ACQUIRED_AWARD,
  payload: acquiredAward,
});

export const setAvailableAward = (availableAward: Award[]) => ({
  type: AwardTypes.SET_AVAILABLE_AWARD,
  payload: availableAward,
});

export const setAward = (award: Award) => ({
  type: AwardTypes.SET_AWARD,
  payload: award,
});
