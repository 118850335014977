import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// TODO: A ugly solution to deal quiclky with a partner, see more: https://trello.com/c/jKFs857p
const urlParams = new URLSearchParams(window.location.search);
const partnerBrokersDigitales = urlParams.get('slug');
const defaultLanguage = partnerBrokersDigitales === 'brokers-digitales' ? 'es' : i18n.options.lng;

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: defaultLanguage,
    debug: true,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    resources: {
      pt: {
        translation: {
          timeToExchangeCoins: 'Hora de trocar suas moedas 💰🤑',
          noFileSelected: 'Nenhum arquivo selecionado.',
          done: 'Concluída!',
          feed: 'Feed de notícias',
          forgotPassword: 'Esqueceu a senha?',
          or: 'ou',
          password: 'Senha',
          placeholderType: 'Digite',
          back: 'Voltar',
          contentNotEnabled: 'Conteúdo não habilitado',
          okUnderstand: 'OK, ENTENDI!',
          lastChance:
            'Ao iniciar essa partida você deve concluí-la, pois <strong>será sua última ou única tentativa.</strong> Caso você não finalize ou perca a partida, <strong>não poderá jogar novamente.</strong> Deseja continuar?',
          yesContinue: 'SIM, CONTINUAR',
          noTryLater: 'NÃO, TENTAR MAIS TARDE',
          unlockJourneys: 'Você precisa concluir as seguintes jornadas para desbloquear:',
          journeys: 'Jornadas',
          home: 'Início',
          leagues: 'Ligas',
          rewards: 'Prêmios',
          reward: 'Prêmio',
          library: 'Biblioteca',
          subtextLibrary:
            'Quer reforçar o conhecimento adquirido? Abaixo você pode revisar todo o conteúdo das jornadas concluídas!',
          noLibraryAvailable: 'Nenhum conteúdo disponível.',
          profile: 'Perfil',
          referAndWin: 'Indique e Ganhe',
          eventSchedule: 'Programação do evento',
          eventMap: 'Mapa do evento',
          logout: 'Sair',
          enoughCash: 'Você já possui saldo suficiente para trocar por prêmios!',
          exchangeCoins: 'TROCAR MOEDAS',
          okContinue: 'OK, CONTINUAR',
          continue: 'Continuar',
          notFound: 'Ops, nada encontrado!',
          tutoText: 'Criado com // pela',
          resumePage: 'Pág. {{pageNumber}} de {{numPages}}',
          needHelp: 'Precisa de ajuda?',
          support: 'Suporte',
          helpMessage1:
            'Olá! Preciso de suporte para {{identifier}} {{companyName}}. Minha dúvida: ',
          helpMessage2: 'Olá! Preciso de suporte para o email/cpf/whatsapp: ...',
          helpInfo: 'Ao clicar no botão abaixo, você será redirecionado para o WhatsApp.',
          helpStart: 'Começar atendimento',
          hello: 'Olá, {{userName}}!',
          yourCoins: 'Suas Moedas',
          menu: 'Menu',
          tWelcomeRewards: 'Bem vindo à Loja de Prêmios',
          tGameStart: 'Que comecem os jogos!',
          tTimeToLearn: 'Hora de aprender',
          tLeagues: 'Apresentamos as ligas!',
          textLeagues:
            'Continue jogando para subir no ranking. Quanto mais perguntas certas, mais pontos!',
          tLibrary: 'Jornada concluída! Biblioteca liberada!',
          textLibrary:
            'A biblioteca contém os materiais de estudo das jornadas concluídas. Dessa forma, você pode revisá-los facilmente quando quiser.',
          textLibrarySplit: 'Basta clicar no ícone // presente no menu abaixo',
          tCoins: 'Seu saldo de moedas é exibido no topo da tela.',
          tAccess1Mobile: 'No menu abaixo, acesse a Loja de Prêmios clicando em ',
          tAccess1Desktop: 'No menu ao lado, acesse a Loja de Prêmios clicando em ',
          tAccess2: 'Ou acesse as Jornadas de Conhecimento clicando em ',
          tEnoughCash:
            'Quando seu saldo for suficiente, clique em "Comprar" e siga as instruções para obter seu prêmio.',
          tShowValue: 'O valor do produto é mostrado ao lado do botão de compra.',
          tutorialText:
            'Neste tutorial você aprenderá a trocar suas moedas conquistadas por prêmios e/ou benefícios.',
          okShowMe: 'OK! Mostre-me como!',
          tutorialSplitText:
            ' Acumule moedas completando as <strong>Jornadas de Conhecimento </strong>e troque por <strong>prêmios e/ou benefícios</strong> quando tiver saldo suficiente.',
          understood: 'Entendi!',
          tFirstJourney: 'Pronto para se divertir e aprender?',
          okLetsGo: 'OK! Vamos lá!',
          tPresentation:
            'Estude o conteúdo para preparar-se para o desafio. Quando estiver pronto(a), clique em "JOGAR" para iniciar o quiz.',
          sso: 'Auto cadastro',
          ssoOpen: 'Abrir página auto cadastro.',
          loginOpen: 'Abrir página login.',
          journeyBlock: 'JORNADA BLOQUEADA',
          play: 'JOGAR',
          errorAuth: 'Valor usado para autorização é inválido.',
          errorConnect:
            'Não foi possível conectar ao servidor, verifique sua conexão com a internet.',
          errorGeneric: 'Ops, ocorreu um erro! Tente novamente mais tarde.',
          errorInternal: 'Houve um erro interno no servidor.',
          errorMaintenance: 'O servidor encontra-se em manutenção. Tente novamente mais tarde.',
          errorAccessDenied: 'Sem permissão para acesso.',
          errorNotFound: 'Nenhum retorno encontrado.',
          errorInvalid: 'Usuário e/ou senha inválidos',
          errorUserBlocked: 'Usuário bloqueado',
          errorLimit: 'Limite de partidas máximas atingido.',
          more: 'Saiba mais',
          attention: 'Atenção!',
          acquiredReward:
            'Aqui você encontra os seus prêmios já adquiridos. Para usar qualquer prêmio abaixo basta copiar o código do cupom de desconto e aplicá-lo no momento da compra.',
          noAcquireRewards: 'Você não possui nenhum prêmio resgatado.',
          availableRewards:
            'Confira abaixo os prêmios disponíveis para você comprar usando seu saldo de moedas.',
          noAvailableRewards: 'Nenhum prêmio disponível para resgate',
          approved: 'Aprovado',
          review: 'Em análise',
          rejected: 'Rejeitado',
          accessLink: 'Link de acesso: ',
          accessReward: 'Clique aqui para acessar o prêmio',
          description: 'Descrição',
          // eslint-disable-next-line no-template-curly-in-string
          rewardValueWithCoins: 'Valor do prêmio em moedas: ${{value}}',
          rewardValue: 'Valor do prêmio',
          availableUntil: 'Produto disponível até: {{endDateString}}',
          availableAfter: 'Disponível a partir de: {{startDateString}}',
          quantity: 'QUANTIDADE',
          checkRules: 'Confira as regras',
          buy: 'COMPRAR',
          soldOff: 'ESGOTADO',
          finishedPurchase:
            'Agora é só aproveitar o seu prêmio e descontos. Para utilizar seu prêmio basta você acessar seus Prêmios Adquiridos.',
          previousBalance: 'Saldo Anterior',
          balance: 'Saldo atual',
          accessPurchase: 'ACESSAR PRÊMIO ADQUIRIDO',
          showPurchases: 'VER PRÊMIOS ADQUIRIDOS',
          infoReward: 'Confira abaixo as informações da sua compra:',
          infoCoins: 'Seu saldo atual de moedas:',
          infoPurchase: 'Prêmio que deseja comprar:',
          resumePurchase: 'Resumo final:',
          selectedReward: 'Prêmio escolhido',
          confirmPurchase: 'Deseja concluir a compra de seu prêmio?',
          confirmInfo: 'Após concluir a compra, essa ação não poderá ser desfeita.',
          conclude: 'CONCLUIR',
          available: 'Disponíveis',
          acquired: 'Adquiridos',
          store: 'LOJA DE PRÊMIOS',
          prizeInstructions:
            'Para cumprir esse desafio e garantir a(s) $ :coins moeda(s), basta enviar sua resposta abaixo.',
          noPrizeInstructions: 'Para cumprir esse desafio, basta enviar sua resposta abaixo.',
          send: 'ENVIAR',
          commentPlaceholder: 'Deixe seu comentário (opcional)...',
          loadImage: 'Carregar imagem',
          yourJourney: 'SUA JORNADA',
          journeyDescription:
            'Confira aqui como iniciar sua jornada no aplicativo. Veja quais os passos e comece já!',
          rulesHome:
            'Nesta modalidade o jogador tem como principal objetivo concluir todos as etapas da jornada. Cada etapa tem uma dinâmica diferente e um prazo distinto.',
          generalRulesJourney: 'Regras gerais de jornadas',
          myJourney: 'MINHA JORNADA',
          join: 'PARTICIPAR',
          seeRules: 'Veja as regras',
          tutoWelcome: 'Bem-vindo à TuTo,',
          journeyText: 'Confira abaixo sua jornada e acompanhe seu desempenho!',
          start: 'INÍCIO',
          journeyList: 'Lista de Jornadas',
          subtextJourney:
            'Complete as jornadas abaixo, adquira mais conhecimento e garanta mais prêmios!',
          notFoundJourney: 'Nenhuma jornada disponível.',
          placeholderJourney: 'Buscar jornada...',
          modalLimit: 'Limite de partidas atingido',
          step: 'Etapa',
          congrats: 'Parabéns!',
          veryWell: 'Muito bem!',
          betterLuck: 'Sorte na próxima!',
          leaguePromoted:
            'Voce terminou em {{position}}º lugar e subiu para a Divisão {{newLeague}}!!',
          leagueRelegated:
            'Você terminou em {{position}}º lugar e caiu para a Divisão {{newLeague}}.',
          leagueUnchanged:
            'Você terminou em {{position}}º lugar e se manteve na Divisão {{newLeague}}.',
          nothingHere: 'Nada por aqui ainda',
          doLesson: 'Faça uma lição para entrar no ranking dessa semana',
          rankingAlert:
            'O ranking abaixo promove a competição saudável e NÃO gera premiação. Continue jogando para ganhar mais moedas e trocar na loja de prêmios.',
          welcomeAgain: 'Seja bem-vindo novamente!',
          joinLeagues: 'PARTICIPAR DA LIGA',
          joinLeaguesText: 'Participe da liga e mostre que você domina tudo!',
          confirmLeaguesExit: 'Tem certeza que deseja sair da liga?',
          pointsInfo: 'Você perderá os seus pontos e sua classificação.',
          keepLeagues: 'CONTINUAR NA LIGA!',
          exitLeagues: 'SAIR E PERDER OS PONTOS',
          leagueTop: 'Você entrou no top {{promotable}} da Divisão {{name}}!',
          leagueUp: 'Você subiu na Divisão {{name}}!',
          promoteInfo: 'Os {{promotable}} primeiros avançam para a próxima divisão.',
          division: 'Divisão ',
          zoneUp: 'Zona de classificação',
          zoneDown: 'Zona de rebaixamento',
          exitRank: 'Remova meu nome do ranking!',
          manualSignup: 'Cadastrado manualmente',
          changePasswordSuccess: 'Senha alterada com sucesso!',
          mailNotFound:
            'E-mail não encontrado. Caso não lembre seu login, entre em contato com contato@tutodigital.com.br para recuperar seu acesso.',
          forgotMyPassword: 'Esqueci minha senha',
          newPasswordText: 'Pronto, crie uma nova senha e confirme:',
          newPassword: 'Nova senha',
          confirmNewPassword: 'Confirmar nova senha',
          confirmPassword: 'Confirmar senha',
          confirm: 'Confirmar',
          entering: 'Entrando...',
          modalLeaveMessage1: 'Tem certeza que deseja sair?',
          modalLeaveMessage2:
            'Você <b style={{ fontFamily: "Poppins-Bold" }}>perderá todos os dados</b> desta partida e caso seja somente uma única tentativa, <b style={{ fontFamily: "Poppins-Bold" }}>não poderá jogar novamente.</b>',
          continuePlaying: 'CONTINUAR JOGANDO',
          abandonMatch: 'ABANDONAR PARTIDA',
          nextQuestion: 'PRÓXIMA PERGUNTA',
          showJustification: 'VER JUSTIFICATIVA',
          justification: 'Justificativa',
          close: 'FECHAR',
          phaseSuccess: 'Você concluiu a {{phaseName}} com sucesso!',
          almostThere: 'HUM, QUASE LÁ...',
          phaseFailureMessage1: 'Infelizmente você não atingiu a pontuação mínima para',
          phaseFailureMessage2: 'concluir essa fase. Não desanime, tente novamente!',
          percentageCorrect: 'de acertos',
          correctAnswers: 'resposta(s) correta(s)',
          answeredQuestions: 'pergunta(s) respondida(s)',
          wrongAnswers: 'resposta(s) errada(s)',
          unansweredQuestions: 'pergunta(s) não respondida(s)',
          showAnswers: 'VER GABARITO',
          playAgain: 'JOGAR NOVAMENTE',
          open: 'Abrir',
          openLesson: 'Abrir lição',
          downloadFile: 'Baixar arquivo',
          exit: 'SAIR',
          skip: 'PULAR',
          presentation: 'Apresentação',
          answerQuestions: 'RESPONDER PERGUNTAS',
          modalConfirmationClose:
            'Tem certeza que deseja fechar? <br /> Suas alterações não serão salvas.',
          modalConfirmationBold: 'alterações não serão salvas.',
          editProfile: 'Editar Perfil',
          name: 'Nome',
          nonEditable: 'não editável',
          endSession: 'Encerrar Sessão',
          saveChanges: 'SALVAR ALTERAÇÕES',
          ranking: 'Ranking',
          checkPosition: 'Confira abaixo sua posição e o ranking completo.',
          checkScore: 'Confira abaixo sua pontuação e o seu tempo.',
          disclosureLink: 'LINK DE DIVULGAÇÃO',
          registration: 'CADASTROS',
          inviteText:
            'Convide outros colegas do(a) {{companyName}} para a TuTo utilizando seu link de divulgação abaixo.',
          shareLink: 'COMPARTILHAR LINK',
          noCampaigns: 'Não há campanha de indicação ativa no momento.',
          ssoError: 'Houve um problema na associação com o provedor de conteúdo',
          accountAssociationMessage:
            'Deseja associar sua conta ({{login}}) ao provedor de conteúdo "{{companyName}}"?',
          newUserButton: 'NÃO! Quero criar uma nova conta',
          rejectNewUsers: 'O link utilizado não está aceitando novos cadastros.',
          moreInfo:
            'Para mais informações, entre em contato com a pessoa que te passou este link OU envie um e-mail para',
          newAssociation: 'Nova associação de conta',
          completeFormText: 'Quase lá! Complete seus dados para concluir o cadastro.',
          registerWithForm: 'Cadastrar com formulário',
          clickToAccess: 'Clique aqui para entrar',
          finalizeRegistration: 'FINALIZAR CADASTRO',
          updateRegistration: 'ATUALIZAR CADASTRO',
          selectStore: 'Selecione sua loja',
          area: 'Área',
          updatePasswordMessage:
            'Sua nova senha de acesso foi criada com sucesso, no próximo acesso faça login usando a nova senha.',
          login: 'ENTRAR',
          updateLater: 'ATUALIZAR DEPOIS',
          passwordToken: 'Digite abaixo o código que foi enviado para {{token}}',
          verifyCode: 'Código de verificação',
          invalidCode: 'Código inválido',
          validate: 'VALIDAR',
          infoMail: 'Informe seu e-mail, um código de verificação será enviado para ele.',
          purchaseDate: 'Data da compra: {{dateAcquired}}',
          successPurchase: 'COMPRA CONCLUÍDA COM SUCESSO!',
          rules: 'Regras',
          buyReward: 'COMPRAR PRÊMIO',
          haveUser: 'Tem uma conta?',
          terms: 'Ao prosseguir, você concorda com nossos ',
          and: ' e ',
          study: 'ESTUDAR',
          next: 'PRÓXIMO',
          previous: 'ANTERIOR',
          productNotFound: 'Produto não encontrado',
          paymentTypeCreditCard: 'Cartão de crédito',
          requiredField: 'Campo obrigatório',
          emailsDoNotMatch: 'E-mails não conferem',
          namePlaceholder: 'Digite seu nome...',
          emailPlaceholder: 'Digite seu e-mail...',
          confirmEmailLabel: 'Confirmar e-mail',
          confirmEmailPlaceholder: 'Confirme seu e-mail...',
          genericPlaceholder: 'Digite...',
          cellphoneLabel: 'Celular',
          cellphonePlaceholder: 'Digite seu celular...',
          underDevelopment: 'Em desenvolvimento',
          cardNameLabel: 'Titular',
          cardNumberLabel: 'Número do cartão',
          cardExpirationDateLabel: 'Validade',
          numberOfInstallmentsLabel: 'Número de parcelas',
        },
      },
      en: {
        translation: {
          timeToExchangeCoins: "It's time to exchange your coins 💰🤑",
          noFileSelected: 'No file selected.',
          done: 'Done!',
          feed: 'News feed',
          forgotPassword: 'Forgot password?',
          or: 'or',
          password: 'Password',
          placeholderType: 'Type',
          back: 'Back',
          contentNotEnabled: 'Content not enabled',
          okUnderstand: 'OK, UNDERSTAND!',
          lastChance:
            'By starting this match, you must complete it, as <strong>it will be your last or only attempt.</strong> If you do not finish or lose the match, <strong>you will not be able to play again.</strong> Do you want to continue?',
          yesContinue: 'YES, CONTINUE',
          noTryLater: 'NO, TRY LATER',
          unlockJourneys: 'You need to complete the following journeys to unlock:',
          journeys: 'Journeys',
          home: 'Home',
          leagues: 'Leagues',
          rewards: 'Rewards',
          reward: 'Reward',
          library: 'Library',
          subtextLibrary:
            'Want to reinforce the acquired knowledge? Below you can review all the content from completed journeys!',
          noLibraryAvailable: 'No content available.',
          profile: 'Profile',
          referAndWin: 'Refer and Win',
          eventSchedule: 'Event Schedule',
          eventMap: 'Event Map',
          logout: 'Logout',
          enoughCash: 'You already have enough balance to exchange for rewards!',
          exchangeCoins: 'EXCHANGE COINS',
          okContinue: 'OK, CONTINUE',
          continue: 'Continue',
          notFound: 'Oops, nothing found!',
          tutoText: 'Created with // by',
          resumePage: 'Page. {{pageNumber}} of {{numPages}}',
          needHelp: 'Need help?',
          support: 'Support',
          helpMessage1:
            'Hello! I need support for {{identifier}} {{companyName}}. My question is: ',
          helpMessage2: 'Hello! I need support for the email/cpf/whatsapp: ...',
          helpInfo: 'By clicking the button below, you will be redirected to WhatsApp.',
          helpStart: 'Start support',
          hello: 'Hello, {{userName}}!',
          yourCoins: 'Your Coins',
          menu: 'Menu',
          tWelcomeRewards: 'Welcome to the Rewards Store',
          tGameStart: 'Let the games begin!',
          tTimeToLearn: 'Time to learn',
          tLeagues: 'Introducing the leagues!',
          textLeagues:
            'Keep playing to climb the rankings. The more correct answers, the more points!',
          tLibrary: 'Journey completed! Library unlocked!',
          textLibrary:
            'The library contains study materials from completed journeys. This way, you can easily review them whenever you want.',
          textLibrarySplit: 'Just click on the // icon in the menu below',
          tCoins: 'Your coin balance is displayed at the top of the screen.',
          tAccess1Mobile: 'In the menu below, access the Rewards Store by clicking on ',
          tAccess1Desktop: 'In the menu on the side, access the Prize Store by clicking ',
          tAccess2: 'Or access the Knowledge Journeys by clicking on ',
          tEnoughCash:
            'When your balance is sufficient, click "Buy" and follow the instructions to get your reward.',
          tShowValue: 'The product value is shown next to the buy button.',
          tutorialText:
            'In this tutorial, you will learn how to exchange your earned coins for prizes and/or benefits.',
          okShowMe: 'OK! Show me how!',
          tutorialSplitText:
            ' Earn coins by completing the <strong>Knowledge Journeys</strong> and exchange them for <strong>prizes and/or benefits</strong> when you have enough balance.',
          understood: 'Understood!',
          tFirstJourney: 'Ready to have fun and learn?',
          okLetsGo: "OK! Let's go!",
          tPresentation:
            'Study the content to prepare for the challenge. When you\'re ready, click "PLAY" to start the quiz.',
          sso: 'Single Sign-On',
          ssoOpen: 'Open auto-registration page.',
          loginOpen: 'Open login page.',
          journeyBlock: 'BLOCKED JOURNEY',
          play: 'PLAY',
          errorAuth: 'Invalid authorization value.',
          errorConnect: 'Could not connect to the server, please check your internet connection.',
          errorGeneric: 'Oops, an error occurred! Please try again later.',
          errorInternal: 'There was an internal server error.',
          errorMaintenance: 'The server is undergoing maintenance. Please try again later.',
          errorAccessDenied: 'Access denied.',
          errorNotFound: 'No results found.',
          errorInvalid: 'Invalid username and/or password',
          errorUserBlocked: 'User blocked',
          errorLimit: 'Maximum match limit reached.',
          more: 'Learn more',
          attention: 'Attention!',
          acquiredReward:
            'Here you can find your acquired rewards. To use any prize below, simply copy the discount code and apply it at the time of purchase.',
          noAcquireRewards: 'You have no redeemed rewards.',
          availableRewards:
            'Check out the rewards available for you to purchase using your coin balance.',
          noAvailableRewards: 'No rewards available for redemption',
          approved: 'Approved',
          review: 'Under review',
          rejected: 'Rejected',
          accessLink: 'Access link: ',
          accessReward: 'Click here to access the reward',
          description: 'Description',
          // eslint-disable-next-line no-template-curly-in-string
          rewardValueWithCoins: 'Reward value in ${{value}}',
          rewardValue: 'Reward value',
          availableUntil: 'Product available until: {{endDateString}}',
          availableAfter: 'Available from: {{startDateString}}',
          quantity: 'QUANTITY',
          checkRules: 'Check the rules',
          buy: 'BUY',
          soldOff: 'SOLD OUT',
          finishedPurchase:
            'Now just enjoy your prize and discounts. To use your prize, simply access your Acquired Rewards.',
          previousBalance: 'Previous Balance',
          balance: 'Current Balance',
          accessPurchase: 'ACCESS ACQUIRED REWARD',
          showPurchases: 'VIEW ACQUIRED REWARDS',
          infoReward: 'Check below the information about your purchase:',
          infoCoins: 'Your current coin balance:',
          infoPurchase: 'Prize you want to purchase:',
          resumePurchase: 'Final summary:',
          selectedReward: 'Selected prize',
          confirmPurchase: 'Do you want to complete the purchase of your prize?',
          confirmInfo: 'After completing the purchase, this action cannot be undone.',
          conclude: 'CONCLUDE',
          available: 'Available',
          acquired: 'Acquired',
          store: 'REWARDS STORE',
          prizeInstructions:
            'To complete this challenge and earn $ :coins coin(s), simply send your answer below.',
          noPrizeInstructions: 'To complete this challenge, simply send your answer below.',
          send: 'SEND',
          commentPlaceholder: 'Leave your comment (optional)...',
          loadImage: 'Load image',
          yourJourney: 'YOUR JOURNEY',
          journeyDescription:
            'Check here how to start your journey in the app. See the steps and get started now!',
          rulesHome:
            "In this modality, the player's main goal is to complete all stages of the journey. Each stage has a different dynamic and a different deadline.",
          generalRulesJourney: 'General rules for journeys',
          myJourney: 'MY JOURNEY',
          join: 'JOIN',
          seeRules: 'See rules',
          tutoWelcome: 'Welcome to TuTo,',
          journeyText: 'Check below your journey and track your performance!',
          start: 'START',
          journeyList: 'Journey List',
          subtextJourney:
            'Complete the journeys below, gain more knowledge, and earn more rewards!',
          notFoundJourney: 'No journey available.',
          placeholderJourney: 'Search journey...',
          modalLimit: 'Maximum matches limit reached',
          step: 'Step',
          congrats: 'Congratulations!',
          veryWell: 'Very well!',
          betterLuck: 'Better luck next time!',
          leaguePromoted:
            'You finished in {{position}}th place and advanced to Division {{newLeague}}!!',
          leagueRelegated:
            'You finished in {{position}}th place and were relegated to Division {{newLeague}}.',
          leagueUnchanged:
            'You finished in {{position}}th place and remained in Division {{newLeague}}.',
          nothingHere: 'Nothing here yet',
          doLesson: "Take a lesson to enter this week's ranking",
          rankingAlert:
            'The ranking below promotes healthy competition and DOES NOT generate prizes. Keep playing to earn more coins and redeem them in the rewards store.',
          welcomeAgain: 'Welcome back!',
          joinLeagues: 'JOIN THE LEAGUE',
          joinLeaguesText: 'Join the league and show that you know it all!',
          confirmLeaguesExit: 'Are you sure you want to leave the league?',
          pointsInfo: 'You will lose your points and your ranking.',
          keepLeagues: 'CONTINUE IN THE LEAGUE!',
          exitLeagues: 'EXIT AND LOSE POINTS',
          leagueTop: 'You entered the top {{promotable}} of Division {{name}}!',
          leagueUp: 'You moved up in Division {{name}}!',
          promoteInfo: 'The top {{promotable}} advance to the next division.',
          division: 'Division ',
          zoneUp: 'Promotion zone',
          zoneDown: 'Relegation zone',
          exitRank: 'Remove my name from the ranking!',
          manualSignup: 'Manually signed up',
          changePasswordSuccess: 'Password changed successfully!',
          mailNotFound:
            "Email not found. If you don't remember your login, please contact contato@tutodigital.com.br to recover your access.",
          forgotMyPassword: 'I forgot my password',
          newPasswordText: 'Great, create a new password and confirm it:',
          newPassword: 'New password',
          confirmNewPassword: 'Confirm new password',
          confirmPassword: 'Confirm password',
          confirm: 'Confirm',
          entering: 'Entering...',
          modalLeaveMessage1: 'Are you sure you want to leave?',
          modalLeaveMessage2:
            "You will <b style={{ fontFamily: 'Poppins-Bold' }}>lose all data</b> from this match and if it's only a single attempt, <b style={{ fontFamily: 'Poppins-Bold' }}>you won't be able to play again.</b>",
          continuePlaying: 'CONTINUE PLAYING',
          abandonMatch: 'ABANDON MATCH',
          nextQuestion: 'NEXT QUESTION',
          showJustification: 'SHOW JUSTIFICATION',
          justification: 'Justification',
          close: 'CLOSE',
          phaseSuccess: 'You have successfully completed {{phaseName}}!',
          almostThere: 'ALMOST THERE...',
          phaseFailureMessage1: 'Unfortunately, you did not reach the minimum score to',
          phaseFailureMessage2: "complete this phase. Don't give up, try again!",
          percentageCorrect: 'correct',
          correctAnswers: 'correct answer(s)',
          answeredQuestions: 'answered question(s)',
          wrongAnswers: 'wrong answer(s)',
          unansweredQuestions: 'unanswered question(s)',
          showAnswers: 'SHOW ANSWERS',
          playAgain: 'PLAY AGAIN',
          open: 'Open',
          openLesson: 'Open lesson',
          downloadFile: 'Download file',
          exit: 'EXIT',
          skip: 'SKIP',
          presentation: 'Presentation',
          answerQuestions: 'ANSWER QUESTIONS',
          modalConfirmationClose:
            'Are you sure you want to close?<br /> Your changes will not be saved.',
          modalConfirmationBold: 'your changes will not be saved.',
          editProfile: 'Edit Profile',
          name: 'Name',
          nonEditable: 'non-editable',
          endSession: 'End Session',
          saveChanges: 'SAVE CHANGES',
          ranking: 'Ranking',
          checkPosition: 'Check below your position and the complete ranking.',
          checkScore: 'Check below your score and your time.',
          disclosureLink: 'DISCLOSURE LINK',
          registration: 'Registrations',
          inviteText:
            'Invite other colleagues from {{companyName}} to TuTo using your referral link below.',
          shareLink: 'SHARE LINK',
          noCampaigns: 'There is no active referral campaign at the moment.',
          ssoError: 'There was a problem associating with the content provider',
          accountAssociationMessage:
            'Do you want to associate your account ({{login}}) with the content provider "{{companyName}}"?',
          newUserButton: 'NO! I want to create a new account',
          rejectNewUsers: 'The link used does not accept new registrations.',
          moreInfo:
            'For more information, contact the person who gave you this link OR send an email to',
          newAssociation: 'New account association',
          completeFormText: 'Almost there! Complete your data to finish the registration.',
          registerWithForm: 'Register with form',
          clickToAccess: 'Click here to access',
          finalizeRegistration: 'FINALIZE REGISTRATION',
          updateRegistration: 'UPDATE REGISTRATION',
          selectStore: 'Select your store',
          area: 'Area',
          updatePasswordMessage:
            'Your new access password has been successfully created. On your next login, use the new password.',
          login: 'LOGIN',
          updateLater: 'UPDATE LATER',
          passwordToken: 'Enter the code that was sent to {{token}}',
          verifyCode: 'Verification code',
          invalidCode: 'Invalid code',
          validate: 'VALIDATE',
          infoMail: 'Provide your email, a verification code will be sent to it.',
          purchaseDate: 'Purchase date: {{dateAcquired}}',
          successPurchase: 'PURCHASE SUCCESSFULLY COMPLETED!',
          rules: 'Rules',
          buyReward: 'BUY REWARD',
          haveUser: 'Have a user?',
          terms: 'By proceeding, you agree to our ',
          and: ' and ',
          study: 'STUDY',
          next: 'NEXT',
          previous: 'PREVIOUS',
          productNotFound: 'Product not found',
          paymentTypeCreditCard: 'Credit Card',
          requiredField: 'Required field',
          emailsDoNotMatch: 'Emails do not match',
          namePlaceholder: 'Enter your name...',
          emailPlaceholder: 'Enter your email...',
          confirmEmailLabel: 'Confirm email',
          confirmEmailPlaceholder: 'Confirm your email...',
          genericPlaceholder: 'Type...',
          cellphoneLabel: 'Cellphone',
          cellphonePlaceholder: 'Enter your cellphone...',
          underDevelopment: 'Under development',
          cardNameLabel: 'Cardholder',
          cardNumberLabel: 'Card number',
          cardExpirationDateLabel: 'Expiration date',
          numberOfInstallmentsLabel: 'Number of installments',
        },
      },
      es: {
        translation: {
          timeToExchangeCoins: '¡Ya puedes cambiar monedas! 💰🤑',
          noFileSelected: 'Seleccionar imagen o archivo.',
          done: '¡Completada!',
          feed: 'Flujo de noticias',
          forgotPassword: '¿Olvidaste tu contraseña?',
          or: 'o',
          password: 'Contraseña',
          placeholderType: 'Escribir',
          back: 'Volver',
          contentNotEnabled: 'Contenido no habilitado',
          okUnderstand: '¡OK, ENTENDIDO!',
          lastChance:
            'Al iniciar esta partida, debes completarla, ya que <strong>será tu última o única oportunidad</strong>. Si no terminas o pierdes la partida, <strong>no podrás jugar de nuevo</strong>. ¿Deseas continuar?',
          yesContinue: 'SÍ, CONTINUAR',
          noTryLater: 'NO, INTENTAR MÁS TARDE',
          unlockJourneys: 'Debes completar los siguientes recorridos para desbloquear:',
          journeys: 'Recorridos',
          home: 'Inicio',
          leagues: 'Ranking',
          rewards: 'Premios',
          reward: 'Premio',
          library: 'Biblioteca',
          subtextLibrary:
            '¿Quieres repasar el conocimiento adquirido? ¡A continuación, puedes revisar todo el contenido de los recorridos completados!',
          noLibraryAvailable: 'No hay contenido disponible.',
          profile: 'Perfil',
          referAndWin: 'Referir y Ganar',
          eventSchedule: 'Programación del evento',
          eventMap: 'Mapa del evento',
          logout: 'Cerrar sesión',
          enoughCash: '¡Ya tienes suficiente saldo para canjear por premios!',
          exchangeCoins: 'CANJEAR MONEDAS',
          okContinue: 'OK, CONTINUAR',
          continue: 'Continuar',
          notFound: '¡Vaya, no se encontró nada!',
          tutoText: 'Creado con // por',
          resumePage: 'Pág. {{pageNumber}} de {{numPages}}',
          needHelp: '¿Necesitas ayuda?',
          support: 'Soporte',
          helpMessage1:
            '¡Hola! Necesito soporte para {{identifier}} {{companyName}}. Mi pregunta es: ',
          helpMessage2: '¡Hola! Necesito soporte para el correo electrónico/cpf/whatsapp: ...',
          helpInfo: 'Al hacer clic en el botón de abajo, serás redirigido a WhatsApp.',
          helpStart: 'Comenzar asistencia',
          hello: '¡Hola, {{userName}}!',
          yourCoins: 'Tus monedas',
          menu: 'Menú',
          tWelcomeRewards: 'Bienvenido a la Tienda de Premios',
          tGameStart: '¡Que comiencen los juegos!',
          tTimeToLearn: 'Hora de aprender',
          tLeagues: '¡Presentamos las ligas!',
          textLeagues:
            'Continúa jugando para subir en el ranking. ¡Cuanto más respuestas correctas, más puntos!',
          tLibrary: '¡Recorrido completado! ¡Biblioteca liberada!',
          textLibrary:
            'La biblioteca contiene los materiales de estudio de los recorridos completados. De esta manera, puedes revisarlos fácilmente cuando lo desees.',
          textLibrarySplit:
            'Simplemente haz clic en el ícono // presente en el menú a continuación',
          tCoins: 'Tu saldo de monedas se muestra en la parte superior de la pantalla.',
          tAccess1Mobile:
            'En el menú a continuación, accede a la Tienda de Premios haciendo clic en ',
          tAccess1Desktop: 'En el menú lateral accede a la Tienda de Premios haciendo clic en ',
          tAccess2: 'O accede a los Recorridos de Conocimiento haciendo clic en ',
          tEnoughCash:
            'Cuando tengas suficiente saldo, haz clic en "Comprar" y sigue las instrucciones para obtener tu premio.',
          tShowValue: 'El valor del producto se muestra al lado del botón de compra.',
          tutorialText:
            'En este tutorial, aprenderás a canjear tus monedas ganadas por premios y/o beneficios.',
          okShowMe: '¡OK! ¡Muéstrame cómo!',
          tutorialSplitText:
            'Acumula monedas completando los <strong>Recorridos de Conocimiento </strong>y canjéalas por <strong>premios y/o beneficios</strong> cuando tengas suficiente saldo.',
          understood: '¡Entendido!',
          tFirstJourney: '¿Estás listo para divertirte y aprender?',
          okLetsGo: '¡OK! ¡Vamos allá!',
          tPresentation:
            'Estudia el contenido para prepararte para el desafío. Cuando estés listo, haz clic en "JUGAR" para empezar el cuestionario.',
          sso: 'Registro automático',
          ssoOpen: 'Abrir página de registro automático.',
          loginOpen: 'Abrir página de inicio de sesión.',
          journeyBlock: 'RECORRIDO BLOQUEADO',
          play: 'JUGAR',
          errorAuth: 'El valor utilizado para la autorización no es válido.',
          errorConnect: 'No se pudo conectar al servidor, verifica tu conexión a internet.',
          errorGeneric: '¡Vaya, se produjo un error! Inténtalo de nuevo más tarde.',
          errorInternal: 'Hubo un error interno en el servidor.',
          errorMaintenance:
            'El servidor se encuentra en mantenimiento. Inténtalo de nuevo más tarde.',
          errorAccessDenied: 'Acceso denegado.',
          errorNotFound: 'No se encontraron resultados.',
          errorInvalid: 'Usuario y/o contraseña inválidos',
          errorUserBlocked: 'Usuario bloqueado',
          errorLimit: 'Se ha alcanzado el límite de partidas máximas.',
          more: 'Obtener más información',
          attention: '¡Atención!',
          acquiredReward:
            'Aquí encontrarás tus premios adquiridos. Para usar cualquier premio a continuación, simplemente copia el código del cupón de descuento y aplícalo al momento de la compra.',
          noAcquireRewards: 'No tienes ningún premio canjeado.',
          availableRewards:
            'A continuación, puedes ver los premios disponibles para comprar usando tu saldo de monedas.',
          noAvailableRewards: 'No hay premios disponibles para canjear',
          approved: 'Aprobado',
          review: 'En revisión',
          rejected: 'Rechazado',
          accessLink: 'Enlace de acceso: ',
          accessReward: 'Haz clic aquí para acceder al premio',
          description: 'Descripción',
          // eslint-disable-next-line no-template-curly-in-string
          rewardValueWithCoins: 'Valor del premio en monedas: ${{value}}',
          rewardValue: 'Valor del premio',
          availableUntil: 'Producto disponible hasta: {{endDateString}}',
          availableAfter: 'Disponible a partir de: {{startDateString}}',
          quantity: 'CANTIDAD',
          checkRules: 'Ver reglas',
          buy: 'COMPRAR',
          soldOff: 'AGOTADO',
          finishedPurchase:
            'Ahora solo tienes que disfrutar de tu premio y descuentos. Para utilizar tu premio, simplemente accede a tus Premios Adquiridos.',
          previousBalance: 'Saldo anterior',
          balance: 'Saldo actual',
          accessPurchase: 'ACCEDER AL PREMIO ADQUIRIDO',
          showPurchases: 'VER PREMIOS ADQUIRIDOS',
          infoReward: 'A continuación, puedes ver la información de tu compra:',
          infoCoins: 'Tu saldo actual de monedas:',
          infoPurchase: 'Premio que deseas comprar:',
          resumePurchase: 'Resumen final:',
          selectedReward: 'Premio seleccionado',
          confirmPurchase: '¿Deseas finalizar la compra de tu premio?',
          confirmInfo: 'Una vez realizada la compra, esta acción no se puede deshacer.',
          conclude: 'FINALIZAR',
          available: 'Disponibles',
          acquired: 'Adquiridos',
          store: 'TIENDA DE PREMIOS',
          prizeInstructions:
            'Para cumplir este desafío y obtener $ :coins moneda(s), simplemente envía tu respuesta a continuación.',
          noPrizeInstructions:
            'Para cumplir este desafío, simplemente envía tu respuesta a continuación.',
          send: 'ENVIAR',
          commentPlaceholder: 'Deja tu comentario (opcional)...',
          loadImage: 'Cargar imagen',
          yourJourney: 'TU RECORRIDO',
          journeyDescription:
            'Aquí puedes ver cómo comenzar tu recorrido en la aplicación. Mira los pasos y comienza ahora mismo.',
          rulesHome:
            'En esta modalidad, el jugador tiene como objetivo principal completar todas las etapas del recorrido. Cada etapa tiene una dinámica y un plazo diferentes.',
          generalRulesJourney: 'Reglas generales de los recorridos',
          myJourney: 'MI RECORRIDO',
          join: 'UNIRSE',
          seeRules: 'Ver reglas',
          tutoWelcome: 'Bienvenido a TuTo,',
          journeyText: 'A continuación, puedes ver tu recorrido y seguir tu progreso.',
          start: 'INICIO',
          journeyList: 'Lista de Recorridos',
          subtextJourney:
            'Completa los recorridos a continuación, adquiere más conocimiento y obtén más premios.',
          notFoundJourney: 'No hay recorridos disponibles.',
          placeholderJourney: 'Buscar recorrido...',
          modalLimit: 'Límite de partidas alcanzado',
          step: 'Paso',
          congrats: '¡Felicitaciones!',
          veryWell: '¡Muy bien!',
          betterLuck: '¡Mejor suerte la próxima vez!',
          leaguePromoted:
            '¡Terminaste en la {{position}}º posición y ascendiste a la División {{newLeague}}!',
          leagueRelegated:
            '¡Terminaste en la {{position}}º posición y descendiste a la División {{newLeague}}!',
          leagueUnchanged:
            '¡Terminaste en la {{position}}º posición y te mantuviste en la División {{newLeague}}!',
          nothingHere: 'Aquí aún no hay nada',
          doLesson: 'Haz una lección para ingresar al ranking de esta semana',
          rankingAlert:
            'El ranking a continuación promueve la competencia saludable y NO genera premios. Sigue jugando para ganar más monedas y canjear en la tienda de premios.',
          welcomeAgain: '¡Bienvenido de nuevo!',
          joinLeagues: 'UNIRSE A LA LIGA',
          joinLeaguesText: '¡Únete a la liga y demuestra todo lo que sabes!',
          confirmLeaguesExit: '¿Estás seguro de que deseas salir de la liga?',
          pointsInfo: 'Perderás tus puntos y clasificación.',
          keepLeagues: 'CONTINUAR EN LA LIGA',
          exitLeagues: 'SALIR Y PERDER LOS PUNTOS',
          leagueTop: '¡Entraste en el top {{promotable}} de la División {{name}}!',
          leagueUp: '¡Ascendiste a la División {{name}}!',
          promoteInfo: 'Los primeros {{promotable}} avanzarán a la siguiente división.',
          division: 'División',
          zoneUp: 'Zona de ascenso',
          zoneDown: 'Zona de descenso',
          exitRank: '¡Quita mi nombre del ranking!',
          manualSignup: 'Registrado manualmente',
          changePasswordSuccess: '¡Contraseña cambiada con éxito!',
          mailNotFound:
            'Correo electrónico no encontrado. Si no recuerdas tu inicio de sesión, ponte en contacto con contacto@tutodigital.com.br para recuperar tu acceso.',
          forgotMyPassword: 'Olvidé mi contraseña',
          newPasswordText: 'Listo, crea una nueva contraseña y confírmala:',
          newPassword: 'Nueva contraseña',
          confirmNewPassword: 'Confirmar nueva contraseña',
          confirmPassword: 'Confirmar contraseña',
          confirm: 'Confirmar',
          entering: 'Ingresando...',
          modalLeaveMessage1: '¿Estás seguro de que deseas salir?',
          modalLeaveMessage2:
            '<b style={{ fontFamily: "Poppins-Bold" }}>perderás todos los datos</b> de esta partida y, si solo tienes una oportunidad, <b style={{ fontFamily: "Poppins-Bold" }}>no podrás volver a jugar.</b>',
          continuePlaying: 'CONTINUAR JUGANDO',
          abandonMatch: 'ABANDONAR PARTIDA',
          nextQuestion: 'SIGUIENTE PREGUNTA',
          showJustification: 'VER JUSTIFICACIÓN',
          justification: 'Justificación',
          close: 'CERRAR',
          phaseSuccess: '¡Has completado la {{phaseName}} con éxito!',
          almostThere: '¡UPS, CASI LO TIENES...',
          phaseFailureMessage1: 'Lamentablemente, no has alcanzado la puntuación mínima para',
          phaseFailureMessage2: 'completar esta etapa. ¡No te desanimes, inténtalo de nuevo!',
          percentageCorrect: 'de respuestas correctas',
          correctAnswers: 'respuestas correctas',
          answeredQuestions: 'preguntas respondidas',
          wrongAnswers: 'respuestas incorrectas',
          unansweredQuestions: 'preguntas no respondidas',
          showAnswers: 'VER RESPUESTAS',
          playAgain: 'JUGAR DE NUEVO',
          open: 'Abrir',
          openLesson: 'Abrir lección',
          downloadFile: 'Descargar archivo',
          exit: 'SALIR',
          skip: 'OMITIR',
          presentation: 'Presentación',
          answerQuestions: 'RESPONDER PREGUNTAS',
          modalConfirmationClose:
            '¿Estás seguro de que deseas cerrar?<br /> No se guardarán los cambios realizados.',
          modalConfirmationBold: 'No se guardarán los cambios realizados.',
          editProfile: 'Editar perfil',
          name: 'Nombre',
          nonEditable: 'no editable',
          endSession: 'Finalizar sesión',
          saveChanges: 'GUARDAR CAMBIOS',
          ranking: 'Ranking',
          checkPosition: 'Consulta tu posición y el ranking completo a continuación.',
          checkScore: 'Consulta tu puntuación y tiempo a continuación.',
          disclosureLink: 'ENLACE DE DIVULGACIÓN',
          registration: 'REGISTROS',
          inviteText:
            'Invita a otros colegas de {{companyName}} a TuTo utilizando tu enlace de divulgación a continuación.',
          shareLink: 'COMPARTIR ENLACE',
          noCampaigns: 'No hay una campaña de referencia activa en este momento.',
          ssoError: 'Hubo un problema al asociarse con el proveedor de contenido',
          accountAssociationMessage:
            '¿Deseas asociar tu cuenta ({{login}}) al proveedor de contenido "{{companyName}}"?',
          newUserButton: '¡NO! Quiero crear una nueva cuenta',
          rejectNewUsers: 'El enlace utilizado no acepta nuevos registros.',
          moreInfo:
            'Para obtener más información, ponte en contacto con la persona que te proporcionó este enlace o envía un correo electrónico a',
          newAssociation: 'Nueva asociación de cuenta',
          completeFormText: '¡Casi listo! Completa tus datos para finalizar el registro.',
          registerWithForm: 'Registrar con formulário',
          clickToAccess: 'Clic aquí para entrar',
          finalizeRegistration: 'FINALIZAR EL REGISTRO',
          updateRegistration: 'ACTUALIZAR REGISTRO',
          selectStore: 'Selecciona tu tienda',
          area: 'Área',
          updatePasswordMessage:
            'Tu nueva contraseña de acceso se ha creado correctamente. La próxima vez que accedas, inicia sesión con la nueva contraseña.',
          login: 'INICIAR SESIÓN',
          updateLater: 'ACTUALIZAR MÁS TARDE',
          passwordToken: 'Ingrese el código que se envió a {{token}}',
          verifyCode: 'Código de verificación',
          invalidCode: 'Código inválido',
          validate: 'VALIDAR',
          infoMail: 'Proporcione su correo electrónico, se enviará un código de verificación a él.',
          purchaseDate: 'Fecha de compra: {{dateAcquired}}',
          successPurchase: '¡COMPRA FINALIZADA CON ÉXITO!',
          rules: 'Reglas',
          buyReward: 'COMPRAR PREMIO',
          haveUser: '¿Ya tienes una cuenta?',
          terms: 'Al continuar aceptas nuestros ',
          and: ' y ',
          study: 'ESTUDIAR',
          next: 'PRÓXIMO',
          previous: 'ANTERIOR',
          productNotFound: 'Producto no encontrado',
          paymentTypeCreditCard: 'Tarjeta de crédito',
          requiredField: 'Campo obligatorio',
          emailsDoNotMatch: 'Los correos electrónicos no coinciden',
          namePlaceholder: 'Ingrese su nombre...',
          emailPlaceholder: 'Ingrese su correo electrónico...',
          confirmEmailLabel: 'Confirmar correo electrónico',
          confirmEmailPlaceholder: 'Confirme su correo electrónico...',
          genericPlaceholder: 'Escriba...',
          cellphoneLabel: 'Teléfono celular',
          cellphonePlaceholder: 'Ingrese su número de celular...',
          underDevelopment: 'En desarrollo',
          cardNameLabel: 'Titular de la tarjeta',
          cardNumberLabel: 'Número de la tarjeta',
          cardExpirationDateLabel: 'Fecha de vencimiento',
          numberOfInstallmentsLabel: 'Número de cuotas',
        },
      },
    },
  });

if (defaultLanguage === 'es') {
  i18n.changeLanguage('es');
}

export const { t } = i18n;
export default i18n;
