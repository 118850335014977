import { Dispatch } from 'redux';
import { ConnectApiPost, ConnectApiGet, urls, ConnectApiPUT } from '../functions/connection';
import * as userActions from '../../store/reducers/user/actions';
import { setAuthorizationToken } from '../functions/connection/auth';
import { setImageCompany } from '../functions/imageCompany';
import { ERROR_CONNECTION, ERROR_MAINTENANCE } from '../constants/messages';
import { User } from '../../features/self_sign_up/UserCampaignForm';
import * as leagueActions from '../../store/reducers/league/actions';

export const get = async (hash: string) => {
  try {
    const response = await ConnectApiGet(urls.SELF_SIGN_UP_CAMPAIGN_URL.replace(':hash', hash));
    return response.data;
  } catch (error) {
    // // TODO: handle errors here
    // if (error instanceof Error && error.message === ERROR_USER_BLOCKED) {
    //   throw new Error(ERROR_USER_BLOCKED);
    // }
    // if (error instanceof Error && error.message === ERROR_MAINTENANCE || error instanceof Error && error.message === ERROR_CONNECTION) {
    //   throw new Error(error.message);
    // }
    // throw new Error(ERROR_INVALID_PASSWORD);
  }

  return null;
};

export const getSourceCampaign = async () => {
  const response = await ConnectApiGet(urls.SELF_SIGN_UP_UPDATE_URL);

  return response.data;
};

export const create = (hash: string, attrs: User) => async (dispatch: Dispatch) => {
  await ConnectApiPost(urls.SELF_SIGN_UP_CAMPAIGN_URL.replace(':hash', hash), {
    user: attrs,
  })
    .then(({ data: userData }) => {
      if (userData.user && userData.user.company) {
        setImageCompany(userData.user.company.image);
      }
      dispatch(userActions.setUserData(userData.user));
      if (userData.seasonConfig) dispatch(leagueActions.setSeason(userData.seasonConfig));
      setAuthorizationToken(userData.token);
    })
    .catch(error => {
      if (
        (error instanceof Error && error.message === ERROR_MAINTENANCE) ||
        (error instanceof Error && error.message.includes(ERROR_CONNECTION))
      ) {
        throw new Error(error.message);
      }

      throw error;
    });

  return null;
};

export const update = (attrs: User) => async (dispatch: Dispatch) => {
  await ConnectApiPUT(urls.SELF_SIGN_UP_UPDATE_URL, {
    user: attrs,
  })
    .then(({ data: userData }) => {
      dispatch(userActions.setUserData(userData.user));
    })
    .catch(error => {
      if (
        (error instanceof Error && error.message === ERROR_MAINTENANCE) ||
        (error instanceof Error && error.message.includes(ERROR_CONNECTION))
      ) {
        throw new Error(error.message);
      }

      throw error;
    });

  return null;
};

export const associate = (hash: string, params: Record<string, string>) => async (
  dispatch: Dispatch,
) => {
  await ConnectApiPost(urls.URL_SELF_SIGN_UP_NEW_COMPANY_ASSOCIATION.replace(':hash', hash), params)
    .then(async ({ data: userData }) => {
      if (userData.user && userData.user.company) {
        setImageCompany(userData.user.company.image);
      }
      dispatch(userActions.setUserData(userData.user));
      if (userData.seasonConfig) dispatch(leagueActions.setSeason(userData.seasonConfig));
      setAuthorizationToken(userData.token);
    })
    .catch(error => {
      if (
        (error instanceof Error && error.message === ERROR_MAINTENANCE) ||
        (error instanceof Error && error.message.includes(ERROR_CONNECTION))
      ) {
        throw new Error(error.message);
      }

      throw error;
    });

  return null;
};
