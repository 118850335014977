import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PAwardIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const AwardIcon = ({ width, height, color, style }: PAwardIcon) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="prefix_AwardIcon"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={19}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 00-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-5 1c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM4 19v-2h16v2H4zM4 8v6h16V8h-5.08L17 10.83 15.38 12 12 7.4 8.62 12 7 10.83 9.08 8H4z"
        fill={color}
      />
    </mask>
    <g mask="url(#prefix_AwardIcon)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

AwardIcon.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default AwardIcon;
