import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../functions/colors';

interface PHitsIcon {
  width?: number;
  height?: number;
  style?: CSSProperties;
  color?: string;
}

const HitsIcon = ({ width, height, style, color }: PHitsIcon) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.999.333A6.67 6.67 0 00.332 7a6.67 6.67 0 006.667 6.667A6.67 6.67 0 0013.665 7 6.67 6.67 0 007 .333zm0 12A5.34 5.34 0 011.665 7 5.34 5.34 0 017 1.667 5.34 5.34 0 0112.332 7a5.34 5.34 0 01-5.333 5.334zm2.586-7.806l-3.92 3.92-1.253-1.254a.664.664 0 10-.94.94L5.199 9.86c.26.26.68.26.94 0l4.393-4.393c.26-.26.26-.68 0-.94a.672.672 0 00-.947 0z"
      fill={color}
    />
  </svg>
);

HitsIcon.defaultProps = {
  width: 16,
  height: 16,
  style: {},
  color: colors.white,
};

export default HitsIcon;
