import React, { useEffect, useRef } from 'react';
import { forEach, isEmpty } from 'lodash';
import { JOURNEY_GRID_COLUMNS } from './Journey';
import { colors } from '../../shared/functions/colors';

interface JourneyPathInterface {
  canvasWidth: number;
  canvasHeight: number;
  stepButtons: Record<number, HTMLButtonElement>;
}

type CanvasCoordinates = [number, number];
interface DrawPathParamsInterface {
  from: CanvasCoordinates;
  to: CanvasCoordinates;
  ctx: CanvasRenderingContext2D;
  index: number;
}

const JourneyPath = ({ canvasWidth, canvasHeight, stepButtons }: JourneyPathInterface) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef.current;
  const canvasX = canvas ? canvas.getBoundingClientRect().x : 0;
  const canvasY = canvas ? canvas.getBoundingClientRect().y : 0;

  const drawPath = ({ from, to, ctx, index }: DrawPathParamsInterface) => {
    ctx.moveTo(...from);

    if (index % JOURNEY_GRID_COLUMNS === 0) {
      // vertical move
      const distortion = index % (JOURNEY_GRID_COLUMNS * 2) === 0 ? 60 : -60;
      ctx.bezierCurveTo(from[0] + distortion, from[1], to[0] + distortion, to[1], to[0], to[1]);
    } else {
      // horizontal move
      const distortion = (index - 1) % JOURNEY_GRID_COLUMNS === 0 ? -60 : 60;
      ctx.bezierCurveTo(from[0], from[1] + distortion, to[0], to[1] + distortion, to[0], to[1]);
    }
  };

  const determineCenterRelativeToCanvas = (stepButton: HTMLButtonElement): CanvasCoordinates => {
    // Grabs the step button position
    const stepX = stepButton.getBoundingClientRect().x;
    const stepY = stepButton.getBoundingClientRect().y;

    // Grabs the step button dimensions
    const stepWidth = stepButton.getBoundingClientRect().width;
    const stepHeight = stepButton.getBoundingClientRect().height;

    // Finds step's center
    const stepCenterX = stepX + stepWidth / 2;
    const stepCenterY = stepY + stepHeight / 2;

    // Returns the step button center relative to the grid
    return [stepCenterX - canvasX, stepCenterY - canvasY];
  };

  useEffect(() => {
    if (canvas && !isEmpty(stepButtons) && canvasWidth && canvasHeight) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.lineWidth = 5;
        ctx.strokeStyle = colors.white;
        ctx.setLineDash([10, 5]);
        ctx.beginPath(); // starts drawing the path.

        let from: CanvasCoordinates = [canvasWidth - 74, canvasHeight]; // the "INÍCIO" mark
        forEach(stepButtons, (stepButton, index) => {
          const to = determineCenterRelativeToCanvas(stepButton);
          drawPath({ from, to, ctx, index: parseInt(index, 10) });
          from = to;
        });

        ctx.stroke(); // draws the path.
        ctx.closePath();
      }
    }
  }, [canvas, stepButtons, canvasWidth, canvasHeight]);

  return (
    <canvas width={canvasWidth} height={canvasHeight} ref={canvasRef} className="journey__path" />
  );
};

export default JourneyPath;
