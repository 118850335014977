import React from 'react';
import RichTextEditor from '../richEditor/RichTextEditor';
import { PostContainer, ProfileImg, Date, Name, Text, Image, PostHeader } from './style';

export interface PostInterface {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  text: string;
  postAt: string;
  imageUrl: string;
  author: {
    name: string;
    image: string;
  };
}

const Post = ({ text, imageUrl, postAt, author }: PostInterface) => {
  return (
    <PostContainer>
      <PostHeader>
        <ProfileImg src={author.image || '/images/profile.png'} />
        <div>
          <Name>{author.name}</Name>
          <Date>{postAt}</Date>
        </div>
      </PostHeader>
      <Text>
        <RichTextEditor value={text} />
      </Text>
      {imageUrl && <Image src={imageUrl} />}
    </PostContainer>
  );
};

export default Post;
