import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootStateGlobal } from '../../../store/reducer';
import { stepDone } from '../../services/tutorial';
import { MilestonesInterface, StepLeftInterface, StepsLeftInterface } from '../../modals/user/user';
import { actions as userActions } from '../../../store/reducers/user';
import ModalTuTorial from './Modal';
import { MenuUrl } from '../../enums/menuUrl';

export const stepToBeShown = (
  currentRoute: string,
  stepsLeft: StepsLeftInterface,
  milestones: MilestonesInterface,
): StepLeftInterface | undefined => {
  const { award: awardUrl, presentation: presentationUrl, league: leagueUrl } = MenuUrl;
  const isAwardUrl = currentRoute === awardUrl;
  const isJourneyUrl = /^\/journeys\/[0-9a-z-]{36}\/play$/.test(currentRoute);
  const isPresentationUrl = currentRoute === presentationUrl;
  const isLeagueUrl = currentRoute === leagueUrl;

  if (stepsLeft.includes('homeIntro')) return 'homeIntro';
  if (!stepsLeft.includes('homeIntro') && stepsLeft.includes('homeCoins')) return 'homeCoins';
  if (!stepsLeft.includes('homeIntro') && stepsLeft.includes('homeStore')) return 'homeStore';
  if (isAwardUrl && stepsLeft.includes('store')) return 'store';
  if (isJourneyUrl && stepsLeft.includes('journey')) return 'journey';
  if (isPresentationUrl && stepsLeft.includes('presentations')) return 'presentations';
  if (isLeagueUrl && stepsLeft.includes('leagues')) return 'leagues';
  if (stepsLeft.includes('library') && milestones.includes('journeyCompleted')) {
    return 'library';
  }

  return undefined;
};

const Tutorial = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  if (!userData) return null;

  const { stepsLeft, milestones } = userData.tutorial;
  const currentRoute = history.location.pathname;

  if (!stepsLeft.length || location.pathname.includes('updateInfo')) return null;

  const step = stepToBeShown(currentRoute, stepsLeft, milestones);

  const markTutorialStepConcluded = (stepConcluded: StepLeftInterface) => {
    stepDone({ stepConcluded }).then(data => {
      dispatch(
        userActions.setUserData({
          ...userData,
          tutorial: data.tutorial,
        }),
      );
    });
  };

  return step?.length ? (
    <ModalTuTorial
      step={step}
      userData={userData}
      markTutorialStepConcluded={markTutorialStepConcluded}
    />
  ) : null;
};

export default Tutorial;
