import styled from 'styled-components';
import { colors } from '../../../../shared/functions/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  border-radius: 4px;
  background-color: ${colors.green};
  height: 15px;
  border-radius: 4px;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.white};
  z-index: 1;
`;
