import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PPersonOutline {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}
const PersonOutline = ({ width, height, color, style }: PPersonOutline) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="person">
        <path
          id="Vector"
          d="M14.6835 7.78575C15.9861 7.78575 17.0519 8.85153 17.0519 10.1541C17.0519 11.4568 15.9861 12.5225 14.6835 12.5225C13.3809 12.5225 12.3151 11.4568 12.3151 10.1541C12.3151 8.85153 13.3809 7.78575 14.6835 7.78575ZM14.6835 19.6277C17.8809 19.6277 21.5519 21.1553 21.7887 21.9961H7.57835C7.85072 21.1435 11.498 19.6277 14.6835 19.6277ZM14.6835 5.41736C12.0665 5.41736 9.94674 7.53707 9.94674 10.1541C9.94674 12.7712 12.0665 14.8909 14.6835 14.8909C17.3006 14.8909 19.4203 12.7712 19.4203 10.1541C19.4203 7.53707 17.3006 5.41736 14.6835 5.41736ZM14.6835 17.2593C11.5217 17.2593 5.20996 18.8461 5.20996 21.9961V24.3645H24.1571V21.9961C24.1571 18.8461 17.8453 17.2593 14.6835 17.2593Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PersonOutline.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default PersonOutline;
