import { datadogRum } from '@datadog/browser-rum';
import { ConnectApiGet, urls } from './shared/functions/connection';
import { environment } from './shared/functions/environment';

if (environment() === 'production') {
  ConnectApiGet(urls.DATADOG_KEYS_URL).then(({ data }) => {
    datadogRum.init({
      // data contains applicationId and clientToken.
      ...data,
      site: 'datadoghq.com',
      service: 'play',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
  });
}
