import React, { CSSProperties } from 'react';

interface PHeartIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const HeartIcon = ({ width, height, color, style }: PHeartIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15L6.84 13.921C2.72 10.1035 0 7.57766 0 4.49591C0 1.97003 1.936 0 4.4 0C5.792 0 7.128 0.662125 8 1.70027C8.872 0.662125 10.208 0 11.6 0C14.064 0 16 1.97003 16 4.49591C16 7.57766 13.28 10.1035 9.16 13.921L8 15Z"
      fill={color}
    />
  </svg>
);

HeartIcon.defaultProps = {
  width: 16,
  height: 16,
  color: '#F95D4A',
  style: {},
};

export default HeartIcon;
