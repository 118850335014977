import React, { ChangeEvent } from 'react';
import BaseForm from './BaseForm';
import { t } from '../../../i18n';

class NPS extends BaseForm {
  buildOptions = (answer: string) => {
    const {
      challenge: {
        mechanics: { options },
      },
    } = this.props;
    if (!options) return <></>;

    const { customBackground } = this.props;

    return options.map(option => (
      <label
        htmlFor={option}
        key={option}
        className={answer === option ? 'active' : undefined}
        style={answer === option && customBackground ? { backgroundColor: customBackground } : {}}
      >
        <span>{option}</span>
        <input
          type="radio"
          name="answer"
          id={option}
          value={option}
          onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleChangeValue(event.target)}
        />
      </label>
    ));
  };

  buildBody = () => {
    const { answer } = this.state;

    return (
      <>
        <div className="challenge-form__nps-options">{this.buildOptions(answer)}</div>
        <textarea
          name="comment"
          placeholder={t('commentPlaceholder')}
          style={{ width: '100%', height: '100px', padding: 8 }}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            this.handleChangeValue(event.target)
          }
        />
      </>
    );
  };
}

export default NPS;
