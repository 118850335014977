import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CheckIcon from '../../../../shared/icons/CheckIcon';
import { CheckContainer, Container, Wrapper } from './styles';

interface PCheckMark {
  style?: CSSProperties;
}

const CheckMark = ({ style }: PCheckMark) => (
  <Wrapper style={style}>
    <Container>
      <CheckContainer>
        <CheckIcon width={24} height={14} />
      </CheckContainer>
    </Container>
  </Wrapper>
);

CheckMark.defaultProps = {
  style: {},
};

export default CheckMark;
