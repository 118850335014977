import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout, getTokenValue } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

export const getCompanyPreferenceStyles = async ({ subdomain = '' }) => {
  try {
    const path =
      (getTokenValue() && urls.URL_COMPANY_PREFERENCES_STYLES) ||
      `${urls.URL_COMPANY_PREFERENCES_STYLES_BY_SUBDOMAIN}?subdomain=${subdomain}`;

    const response = await ConnectApiGet(path);
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }
  return {};
};
