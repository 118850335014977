import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { colors } from '../../../functions/colors';
import { useCustomStyle } from '../../../functions/hooks';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import { Container } from './styles';
import { t } from '../../../../i18n';

interface PButtonBack {
  style?: CSSProperties;
  urlBack?: string;
}

const ButtonBack = ({ style, urlBack }: PButtonBack) => {
  const history = useHistory();
  const { textColor } = useCustomStyle();

  return (
    <Container
      style={style}
      onClick={() => (urlBack ? history.push(urlBack) : history.go(-1))}
      color={textColor.color || colors.white}
    >
      <ChevronLeftIcon color={textColor.color || colors.white} />
      {t('back').toUpperCase()}
    </Container>
  );
};

ButtonBack.defaultProps = {
  style: {},
  urlBack: undefined,
};

export default ButtonBack;
