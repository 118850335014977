import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';

import { colors } from '../../../functions/colors';
import { Container } from './styles';
import { useCustomStyle } from '../../../functions/hooks';

const BlockScreen = () => {
  const { blockScreen } = useSelector((state: RootStateOrAny) => state.geralReducer);
  const { textColor } = useCustomStyle();

  if (blockScreen) {
    return (
      <Container>
        <ReactLoading
          type="bubbles"
          color={textColor.color || colors.white}
          width={44}
          height={40}
        />
      </Container>
    );
  }
  return null;
};

export default BlockScreen;
