import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useCustomStyle } from '../../functions/hooks';
import Button from '../buttons/Button';

import { Container, ContainerButtons } from './styles';

interface ListTabs {
  id: number;
  nameButton: string;
  children: ReactNode;
}

interface PTabs {
  listTabs: ListTabs[];
  selectedTabId?: number;
  style?: CSSProperties;
  setPropTab?: (x: number) => void;
}

const Tabs = ({ listTabs, style, selectedTabId = 1, setPropTab }: PTabs) => {
  const [tabId, setTabId] = useState<number>(selectedTabId);
  const tabSelected = listTabs.find(tab => tab.id === tabId);
  const { primaryColor } = useCustomStyle();

  useEffect(() => setTabId(selectedTabId), [selectedTabId]);

  useEffect(() => {
    if (setPropTab) {
      setPropTab(tabId);
    }
  }, [tabId, setPropTab]);

  const handleSelectTab = (idSelected: number) => {
    setTabId(idSelected);
    if (setPropTab) {
      setPropTab(idSelected);
    }
  };

  return (
    <Container style={style}>
      <ContainerButtons>
        {listTabs.map((tab, index) => {
          return (
            <Button
              key={`button_container_${index}`}
              customBackground={primaryColor.color}
              typeButton={tab.id === tabId ? 'tab-selected' : 'tab-not-selected'}
              onClick={() => handleSelectTab(tab.id)}
            >
              {tab.nameButton}
            </Button>
          );
        })}
      </ContainerButtons>
      {tabSelected?.children}
    </Container>
  );
};

Tabs.defaultProps = {
  selectedTabId: 1,
  style: {},
  setPropTab: undefined,
};

export default Tabs;
