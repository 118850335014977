import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Location } from 'history';
import Button from '../../shared/components/buttons/Button';
import { doLogout } from '../../shared/functions/connection/auth';
import { useCustomStyle } from '../../shared/functions/hooks';
import { User } from '../../shared/modals/user/user';
import { closeModal } from '../../store/reducers/geral/actions';
import * as selfSignUpService from '../../shared/services/selfSignUp';
import { SnackbarData } from '../../shared/modals/geral/geral';
import { actions as geralAction } from '../../store/reducers/geral';
import { t } from '../../i18n';

interface NewAssociationModalInterface {
  userData: User;
  companyName: string;
  ssuHash: string;
  location: Location;
}

const NewAssociationModal = ({
  userData,
  companyName,
  ssuHash,
  location,
}: NewAssociationModalInterface) => {
  const login = userData.email || userData.whatsapp || userData.cpf;
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const { primaryColor } = useCustomStyle();
  const dispatch = useDispatch();
  const referrerId = queryString.parse(location.search).uId as string;

  const handleAssociationToNewCompany = async () => {
    try {
      await dispatch(selfSignUpService.associate(ssuHash, { referrerId }));
      dispatch(closeModal());
    } catch (e) {
      setSnackBar({
        message: t('ssoError'),
        show: true,
        type: 'error',
      });
    }
  };

  const handleDenialToAssociateToNewCompany = () => {
    doLogout(false);
    dispatch(closeModal());
  };

  return (
    <div>
      <div>{t('accountAssociationMessage', { login, companyName })}</div>
      <Button style={{ marginTop: '16px' }} onClick={handleAssociationToNewCompany}>
        {t('yesContinue')}
      </Button>
      <Button
        typeButton="primary-white"
        style={{ marginTop: '16px' }}
        customBackground={primaryColor.color}
        onClick={handleDenialToAssociateToNewCompany}
      >
        {t('newUserButton')}
      </Button>
    </div>
  );
};

export default NewAssociationModal;
