import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
import { colors } from '../../../functions/colors';

interface PContainer {
  backgroundColor: string;
  border: string;
}

export const Container = styled.button<PContainer>`
  cursor: pointer;
  border: ${props => props.border};
  background-color: ${props => props.backgroundColor};
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const TextAlternative = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.white};
  text-align: left;
  word-break: break-word;
`;

export const BoxAlternative = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
