import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { setBlockScreen } from '../../store/reducers/geral/actions';
import { getUserData } from '../../store/reducers/user/operations';

export const SamlCallbackPage = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getInfoUser = async () => {
      if (token) {
        try {
          dispatch(setBlockScreen(true));
          await getUserData({ token })(dispatch);
          dispatch(setBlockScreen(false));
          history.push(MenuUrl.home);
        } catch (_) {
          dispatch(setBlockScreen(false));
          history.push('/');
        }
      }
    };
    getInfoUser();
  });
  return null;
};
