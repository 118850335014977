import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../functions/colors';

interface PCloseIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const CloseIcon = ({ width, height, color, style }: PCloseIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3.71a.996.996 0 00-1.41 0L7 5.59 2.11.7A.996.996 0 10.7 2.11L5.59 7 .7 11.89a.996.996 0 101.41 1.41L7 8.41l4.89 4.89a.996.996 0 101.41-1.41L8.41 7l4.89-4.89c.38-.38.38-1.02 0-1.4z"
      fill={color}
    />
  </svg>
);

CloseIcon.defaultProps = {
  width: 14,
  height: 14,
  color: colors.grey80,
  style: {},
};

export default CloseIcon;
