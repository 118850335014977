/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { deburr } from 'lodash';
import Button from '../../shared/components/buttons/Button';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';
import ArrowRight from '../../shared/icons/arrows/arrowRight';
import { ModalData } from '../../shared/modals/geral/geral';
import { RootStateGlobal } from '../../store/reducer';
import ModalLeave from './ModalLeave';
import { actions as geralActions } from '../../store/reducers/geral';
import { MenuUrl } from '../../shared/enums/menuUrl';
import {
  Container,
  CrownImg,
  CrownsContainer,
  CrownWrapper,
  Header,
  Name,
  Points,
  Position,
  ProfileImg,
  RankingContainer,
  RankingRow,
  RemainingTime,
  Wrapper,
  ZoneDivider,
  ZoneText,
} from './styles';
import EnterLeague from './EnterLeague';
import { fireEvent } from '../../shared/services/event';
import Alert from './Alert';
import Waiting from './waiting/Waiting';
import { t } from '../../i18n';
import { useCustomStyle } from '../../shared/functions/hooks';

const Season = () => {
  const history = useHistory();
  const location = useLocation<never>();
  const dispatch = useDispatch();
  const { backgroundPrimary, primaryColor, secondaryColor } = useCustomStyle();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const { enabled, leagues, seasonRanking, timeLeft } = useSelector(
    (state: RootStateGlobal) => state.leagueReducer,
  );
  const myPositionRef = createRef<HTMLDivElement>();

  const scrollToMyPosition = () => {
    myPositionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    fireEvent('visitedLeague', { enabled, waiting: !leagues?.length });
    scrollToMyPosition();
  }, [seasonRanking]);

  if (!enabled) return <EnterLeague />;
  if (!leagues?.length) return <Waiting />;

  const { exitToPath, leanLayout } = location.state || {};
  const fullLayout = !leanLayout;
  const league = leagues.find(league => league.current);

  if (!league) return null;

  const { promotable, name } = league;

  const handleLeaveLeague = () => {
    setModalData({
      show: true,
      children: (
        <ModalLeave history={history} exitToPath={location.state?.exitToPath || MenuUrl.home} />
      ),
      height: 'inherit',
      title: t('attention'),
    });
  };

  const getDetails = () => {
    if (leanLayout) {
      const position = seasonRanking.findIndex(place => place.currentUser) + 1;
      const text =
        position <= promotable ? t('leagueTop', { promotable, name }) : t('leagueUp', { name });
      return (
        <div
          style={{ fontSize: 16, fontFamily: fonts.semiBold, textAlign: 'center', margin: '8px 0' }}
        >
          {text}
        </div>
      );
    }
    return (
      <div style={{ fontSize: 10, textAlign: 'center', marginBottom: 8 }}>
        {t('promoteInfo', { promotable })}
      </div>
    );
  };

  const stylesFor = (i: number) => {
    if (i === 1)
      return { backgroundColor: '#EAA237', border: '1px solid #FFF', borderRadius: '50%' };
    if (i === 2)
      return { backgroundColor: '#929898', border: '1px solid #FFF', borderRadius: '50%' };
    if (i === 3)
      return { backgroundColor: '#D69353', border: '1px solid #FFF', borderRadius: '50%' };
    if (i <= league.promotable) return { color: colors.green };
    if (i > seasonRanking.length - league.relegatable) return { color: colors.red };

    return {};
  };

  return (
    <Container leanLayout={leanLayout}>
      <Wrapper leanLayout={leanLayout}>
        <Header
          leanLayout={leanLayout}
          backgroundPrimary={backgroundPrimary.backgroundColor}
          className="header"
        >
          <CrownsContainer>
            <CrownWrapper>
              {leagues[0].name && fullLayout && (
                <CrownImg
                  src={require(`../../shared/icons/leagues/${deburr(leagues[0].name)}.svg`)}
                />
              )}
            </CrownWrapper>
            <CrownWrapper>
              {leagues[1].name && fullLayout && (
                <CrownImg
                  src={require(`../../shared/icons/leagues/${deburr(leagues[1].name)}.svg`)}
                />
              )}
            </CrownWrapper>
            <CrownWrapper style={{ minWidth: '40%' }}>
              {leagues[2].name && (
                <CrownImg
                  src={require(`../../shared/icons/leagues/${deburr(leagues[2].name)}.svg`)}
                />
              )}
            </CrownWrapper>
            <CrownWrapper>
              {leagues[3].name && fullLayout && (
                <CrownImg
                  src={require(`../../shared/icons/leagues/${deburr(leagues[3].name)}.svg`)}
                />
              )}
            </CrownWrapper>
            <CrownWrapper>
              {leagues[4].name && fullLayout && (
                <CrownImg
                  src={require(`../../shared/icons/leagues/${deburr(leagues[4].name)}.svg`)}
                />
              )}
            </CrownWrapper>
          </CrownsContainer>
          {fullLayout && (
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                marginBottom: 24,
                marginTop: 8,
                justifyContent: 'center',
              }}
            >
              <CrownWrapper />
              <CrownWrapper />
              <CrownWrapper style={{ minWidth: '40%' }}>
                <div style={{ textAlign: 'center' }}>
                  {t('division')}
                  <p style={{ textTransform: 'uppercase' }}>{`${leagues[2].name}`}</p>
                </div>
              </CrownWrapper>
              <CrownWrapper />
              <CrownWrapper />
            </div>
          )}
          {getDetails()}
          <RemainingTime>{timeLeft}</RemainingTime>
        </Header>
        <Alert />
        <RankingContainer leanLayout={leanLayout}>
          {seasonRanking.map((place, i) => (
            <>
              <RankingRow
                key={i}
                ref={place.currentUser ? myPositionRef : null}
                currentUser={place.currentUser}
                backgroundPrimary={primaryColor.color}
              >
                <Position style={{ ...stylesFor(i + 1) }}>{i + 1}</Position>
                <ProfileImg src={place.image ? place.image : '/images/profile.png'} />
                <Name>{place.name}</Name>
                <Points>{`${place.score}pts`}</Points>
              </RankingRow>
              {i + 1 === league.promotable && (
                <ZoneDivider color={colors.green}>
                  <ArrowRight
                    style={{ transform: 'rotate(-90deg)' }}
                    width={15}
                    height={15}
                    color={colors.green}
                  />
                  <ZoneText>{t('zoneUp')}</ZoneText>
                  <ArrowRight
                    style={{ transform: 'rotate(-90deg)' }}
                    width={15}
                    height={15}
                    color={colors.green}
                  />
                </ZoneDivider>
              )}
              {!!league.relegatable && i + 1 === seasonRanking.length - league.relegatable && (
                <ZoneDivider color={colors.red}>
                  <ArrowRight
                    style={{ transform: 'rotate(90deg)' }}
                    width={15}
                    height={15}
                    color={colors.red}
                  />
                  <ZoneText>{t('zoneDown')}</ZoneText>
                  <ArrowRight
                    style={{ transform: 'rotate(90deg)' }}
                    width={15}
                    height={15}
                    color={colors.red}
                  />
                </ZoneDivider>
              )}
            </>
          ))}
          <div style={{ margin: '16px 0px' }}>
            <Button
              customBackground={secondaryColor.color}
              iconLeft="exit"
              onClick={handleLeaveLeague}
            >
              {t('exitRank')}
            </Button>
          </div>
        </RankingContainer>
        {leanLayout && (
          <div className="footer">
            <div style={{ margin: '16px 0 32px 0' }}>
              <Button
                customBackground={primaryColor.color}
                onClick={() => history.push(exitToPath)}
              >
                {t('continue')}
              </Button>
            </div>
          </div>
        )}
      </Wrapper>
    </Container>
  );
};

export default Season;
