import { PreferenceStyle } from '../../../../../business/src/shared/modals/preferences/preferences';

export enum CompanyPreferenceTypes {
  SET_COMPANY_PREFERENCE_STYLES = '@company/SET_COMPANY_PREFERENCE_STYLES',
}

export interface CompanyStylesTypes {
  readonly preview?: PreferenceStyle;
  readonly live?: PreferenceStyle;
  readonly companySupportWhatsapp: string;
  readonly showWhatsapp: boolean;
}

export interface CompanyState {
  readonly companyStyles: CompanyStylesTypes;
}
