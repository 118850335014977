import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../../shared/components/buttons/Button';
import { MenuUrl } from '../../../../shared/enums/menuUrl';
import { colors } from '../../../../shared/functions/colors';
import { useCustomStyle } from '../../../../shared/functions/hooks';
import { RootStateGlobal } from '../../../../store/reducer';
import RichTextEditor from '../../../richEditor/RichTextEditor';
import { Container, Image, Title, Text, Line, ContainerFlex } from './styles';
import { t } from '../../../../i18n';

const ConclusionBuyAward = () => {
  const history = useHistory();
  const { award } = useSelector((state: RootStateGlobal) => state.awardReducer);
  const { wallet } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { textColor, primaryColor } = useCustomStyle();
  const valueText = { color: colors.orange };

  if (!award || !wallet) {
    return null;
  }

  const handleGoToAward = () => {
    if (award.productUrl.length) window.open(award.productUrl, '_blank');
    else history.push(MenuUrl.award, { received: true });
  };

  const getInstructions = () => {
    return award.thankYouMessage.length ? (
      <span style={{ fontSize: 16 }}>
        <RichTextEditor value={award.thankYouMessage} />
      </span>
    ) : (
      t('finishedPurchase')
    );
  };

  return (
    <Container>
      <Image src="/images/conclusion_buy_award.png" alt="conclusion" />
      <Title style={{ margin: '16px 0px', ...textColor }}>{t('successPurchase')}</Title>
      <Line style={{ borderBottom: `1px solid ${textColor.color || colors.white}` }} />
      <Text style={{ margin: '20px 0', fontWeight: 400, ...textColor }}>{award.name}</Text>
      <Line
        style={{ borderBottom: `1px solid ${textColor.color || colors.white}`, marginBottom: 20 }}
      />
      <Text style={{ marginBottom: 16, ...textColor }}>
        <strong>{t('resumePurchase')}</strong>
      </Text>
      <ContainerFlex>
        <Text style={textColor}>{t('previousBalance')}</Text>
        <Text style={valueText}>{`$ ${wallet.magicashAmount + award.value}`}</Text>
      </ContainerFlex>
      <ContainerFlex>
        <Text style={textColor}>{t('rewardValue')}</Text>
        <Text style={valueText}>{`- $ ${award.value}`}</Text>
      </ContainerFlex>
      <ContainerFlex>
        <Text style={textColor}>{t('balance')}</Text>
        <Text style={valueText}>{`$ ${wallet.magicashAmount}`}</Text>
      </ContainerFlex>
      <Line
        style={{ borderBottom: `1px solid ${textColor.color || colors.white}`, marginBottom: 20 }}
      />
      <Text style={{ fontSize: '12px', marginBottom: 16, ...textColor }}>{getInstructions()}</Text>
      <Button
        onClick={handleGoToAward}
        style={{ margin: '20px 0px' }}
        customBackground={primaryColor.color}
      >
        {award.productUrl.length ? t('accessPurchase') : t('showPurchases')}
      </Button>
    </Container>
  );
};

export default ConclusionBuyAward;
