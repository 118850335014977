import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';

interface PContainer {
  color: string;
}

export const Container = styled.button<PContainer>`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s;
  disabled: false;
  color: ${props => props.color};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
`;
