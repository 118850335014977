import React, { useState, useEffect, useRef } from 'react';

import { noop } from 'lodash';
import BarProgress from '../../../shared/components/bars/barProgress/BarProgress';
import ButtonAlternative from '../../../shared/components/buttons/buttonAlternative/ButtonAlternative';
import { QuestionData } from '../../../shared/modals/socket/Socket';
import { Alternative } from '../../../shared/modals/tournament/Tournament';
import {
  Container,
  BoxQuestion,
  TextQuestion,
  ContainerProgress,
  NumberQuestion,
  TimeLeft,
  ContainerImage,
  BoxAlternatives,
} from './styles';
import { secondToStringClock } from '../../../shared/functions/date';
import Button from '../../../shared/components/buttons/Button';
import { useCustomStyle } from '../../../shared/functions/hooks';
import { colors } from '../../../shared/functions/colors';
import RichTextEditor from '../../richEditor/RichTextEditor';
import { t } from '../../../i18n';

const ONE_SECOND = 1000;

const calcPercentage = (timeLeft: number, time: number) => (timeLeft / time) * 100;

interface PQuestion {
  question: QuestionData;
  handleInsertAnswer: (q: QuestionData, a: Alternative) => void;
  showingAnswer: boolean;
  selectedAnswer: string;
  indexQuestion: number;
  rightAnswer: string | undefined;
  isRight: boolean | undefined;
  questionAmount: number;
  loading: boolean;
  onClickShowJustify?: () => void;
  onClickNextQuestion?: () => void;
  isShowButtonProgress?: boolean;
  shouldShowRightAnswer: boolean;
}

interface LastImage {
  image: string | undefined;
}

const Question = ({
  question,
  handleInsertAnswer,
  showingAnswer,
  selectedAnswer,
  indexQuestion,
  rightAnswer,
  isRight,
  questionAmount,
  loading,
  onClickShowJustify,
  onClickNextQuestion,
  isShowButtonProgress,
  shouldShowRightAnswer,
}: PQuestion) => {
  const [timeLeft, setTimeLeft] = useState(question.time - 1);
  const [lastImage, setLastImage] = useState<LastImage>({ image: '' });
  const [imageLoaded, setImageLoaded] = useState(false);
  const timer = useRef<NodeJS.Timeout>();
  const { textColor, primaryColor, backgroundPrimary, backgroundSecondary } = useCustomStyle();

  const handleSubmitAnswer = (question: QuestionData, alternative: Alternative) => {
    clearInterval(timer.current as NodeJS.Timeout);
    handleInsertAnswer(question, alternative);
  };

  useEffect(() => {
    if (timeLeft >= 0) return;

    handleSubmitAnswer(question, { idAlternative: '', text: '' });
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(question.time - 1);

    timer.current = setInterval(() => {
      setTimeLeft(previousTimeLeft => previousTimeLeft - 1);
    }, ONE_SECOND);

    return () => clearInterval(timer.current as NodeJS.Timeout);
  }, [question]);

  if (lastImage.image !== question.image) {
    setLastImage({ image: question.image });
    setImageLoaded(false);
  }

  return (
    <Container>
      <BoxQuestion
        style={{
          borderLeft: `4px solid ${primaryColor.color || colors.purpleRegular}`,
          ...backgroundSecondary,
        }}
      >
        <TextQuestion style={textColor}>
          <RichTextEditor value={question.text} />
        </TextQuestion>
        {question.image && (
          <ContainerImage>
            <img
              src={question.image}
              hidden={!imageLoaded}
              onLoad={() => setImageLoaded(true)}
              alt="imagem questão"
            />
          </ContainerImage>
        )}
      </BoxQuestion>
      <BoxAlternatives>
        {question.idQuestion &&
          question.alternatives.map((alternative, index) => {
            if (showingAnswer) {
              const isSelectedAlternative = selectedAnswer === alternative.idAlternative;

              if (isSelectedAlternative) {
                if (loading) {
                  return (
                    <ButtonAlternative
                      key={`button_default_${index}`}
                      selected
                      typeButton="default"
                      loading={loading}
                      onClick={() => handleSubmitAnswer(question, alternative)}
                    >
                      {alternative.text}
                    </ButtonAlternative>
                  );
                }
                if (isRight) {
                  return (
                    <ButtonAlternative
                      key={`button_correct_${index}`}
                      selected
                      typeButton="correct"
                      onClick={noop}
                    >
                      {alternative.text}
                    </ButtonAlternative>
                  );
                }
                return (
                  <ButtonAlternative
                    key={`button_wrong_${index}`}
                    selected
                    typeButton="wrong"
                    onClick={noop}
                  >
                    {alternative.text}
                  </ButtonAlternative>
                );
              }

              if (shouldShowRightAnswer && alternative.idAlternative === rightAnswer) {
                return (
                  <ButtonAlternative
                    key={`button_correctNotSelected_${index}`}
                    selected={false}
                    onClick={noop}
                    typeButton="correctNotSelected"
                  >
                    {alternative.text}
                  </ButtonAlternative>
                );
              }
            }
            return (
              <ButtonAlternative
                key={`button_default_${index}`}
                selected={false}
                typeButton="default"
                onClick={() => handleSubmitAnswer(question, alternative)}
              >
                {alternative.text}
              </ButtonAlternative>
            );
          })}
      </BoxAlternatives>
      <ContainerProgress style={backgroundPrimary}>
        {isShowButtonProgress ? (
          <>
            <Button
              onClick={onClickNextQuestion}
              customBackground={primaryColor.color}
              style={{
                marginBottom: 16,
              }}
            >
              {t('nextQuestion')}
            </Button>
            {onClickShowJustify && (
              <Button
                customBackground={primaryColor.color}
                onClick={onClickShowJustify}
                typeButton="primary-black"
              >
                {t('showJustification')}
              </Button>
            )}
          </>
        ) : (
          <>
            <NumberQuestion style={textColor}>
              {`${indexQuestion > 9 ? indexQuestion : `0${indexQuestion}`} / ${
                questionAmount > 9 ? questionAmount : `0${questionAmount}`
              }`}
            </NumberQuestion>
            <BarProgress width={showingAnswer ? 0 : calcPercentage(timeLeft, question.time)} />
            <TimeLeft percentage={showingAnswer ? 0 : calcPercentage(timeLeft, question.time)}>
              {secondToStringClock(showingAnswer || timeLeft < 0 ? 0 : timeLeft)}
            </TimeLeft>
          </>
        )}
      </ContainerProgress>
    </Container>
  );
};

Question.defaultProps = {
  onClickShowJustify: undefined,
  onClickNextQuestion: undefined,
  isShowButtonProgress: false,
};

export default Question;
