import { BrowserRouter, Route, Switch } from 'react-router-dom';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import Award from './features/award';
import CheckoutProduct from './features/checkoutProduct/CheckoutProduct';
import Feed from './features/feed';
import Home from './features/home';
import Journey from './features/journey/Journey';
import JourneyList from './features/journey/JourneyList';
import Season from './features/league/Season';
import SeasonResult from './features/league/seasonResult/SeasonResult';
import LibraryList from './features/libraryList/LibraryList';
import Logged from './features/logged';
import Login from './features/login';
import LoginMFA from './features/login/LoginMFA';
import { SamlCallbackPage } from './features/login/SamlCallbackPage';
import LoginCompany from './features/loginCompany';
import Presentation from './features/presentation';
import Profile from './features/profile';
import Ranking from './features/ranking';
import Referral from './features/referral/Referral';
import SelfSignUp from './features/self_sign_up/SelfSignUp';
import UpdateInfo from './features/self_sign_up/UpdateInfo';
import Tournament from './features/tournament';
import ConclusionPage from './features/tournament/ConclusionPage';
import UpdatePassword from './features/updatePassword';
import { MenuUrl } from './shared/enums/menuUrl';
import { previewActions } from './store/reducers/preview';

const Router = () => {
  const dispatch = useDispatch();
  // TODO: use useSearchParams() from react-router v6
  dispatch(previewActions.setPreview(queryString.parse(window.location.search).preview === 'true'));

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path={`${MenuUrl.loginCompany}/:token`} exact component={LoginCompany} />
        <Route path="/mfa/:token" exact component={LoginMFA} />
        <Route path="/signup/:hash" exact component={SelfSignUp} />
        <Route path="/checkoutProduct/:productId" exact component={CheckoutProduct} />
        <Route path="/saml/callback/:token" exact component={SamlCallbackPage} />
        <Route path={MenuUrl.updateInfo} exact>
          <Logged>
            <UpdateInfo />
          </Logged>
        </Route>
        <Route path={MenuUrl.updatePassword} exact>
          <Logged>
            <UpdatePassword />
          </Logged>
        </Route>
        <Route path={MenuUrl.home} exact>
          <Logged>
            <Home />
          </Logged>
        </Route>
        <Route path={MenuUrl.profile} exact>
          <Logged showMenu={false}>
            <Profile />
          </Logged>
        </Route>
        <Route path={`${MenuUrl.tournament}/:idTourney`} exact>
          <Logged showMenu={false}>
            <Tournament />
          </Logged>
        </Route>
        <Route path={MenuUrl.ranking} exact>
          <Logged>
            <Ranking />
          </Logged>
        </Route>
        <Route path={MenuUrl.result} exact>
          <Logged>
            <ConclusionPage />
          </Logged>
        </Route>
        <Route path={MenuUrl.presentation} exact>
          <Logged whiteScreen>
            <Presentation />
          </Logged>
        </Route>
        <Route path={MenuUrl.referrals} exact>
          <Logged>
            <Referral />
          </Logged>
        </Route>
        <Route path={`${MenuUrl.award}/:idAward?`} exact>
          <Logged>
            <Award />
          </Logged>
        </Route>
        <Route path={MenuUrl.journeysList} exact>
          <Logged>
            <JourneyList />
          </Logged>
        </Route>
        <Route path={`${MenuUrl.journeysList}/:journeyId/play`} exact>
          <Logged>
            <Journey />
          </Logged>
        </Route>
        <Route path={MenuUrl.league} exact>
          <Logged>
            <Season />
          </Logged>
        </Route>
        <Route path={MenuUrl.libraryList} exact>
          <Logged>
            <LibraryList />
          </Logged>
        </Route>
        <Route path={MenuUrl.seasonResult} exact>
          <Logged>
            <SeasonResult />
          </Logged>
        </Route>
        <Route path={MenuUrl.feed} exact>
          <Logged>
            <Feed />
          </Logged>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
