import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import { setFullScreen } from '../../../store/reducers/geral/actions';
import { isImage, isPdf, isWebsite } from '../../functions/mediaType';
import CloseIcon from '../../icons/CloseIcon';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import PdfViewer from '../pdf/PdfViewer';

import { Container, Image } from './styles';

const FullScreen = () => {
  const dispatch = useDispatch();
  const { fullScreen } = useSelector((state: RootStateGlobal) => state.geralReducer);

  if (!fullScreen) return null;

  const { mediaType, mediaUrl } = fullScreen;

  const handleCloseFullScreen = () => {
    dispatch(setFullScreen());
  };

  return (
    <Container>
      <ButtonIcon
        onClick={handleCloseFullScreen}
        style={{ position: 'absolute', top: 16, right: 16, zIndex: 100 }}
      >
        <CloseIcon />
      </ButtonIcon>
      {isPdf(mediaType) && (
        <>
          {window.innerWidth < 400 ? (
            <PdfViewer width={window.innerWidth} urlImage={mediaUrl} showButtonFullScreen={false} />
          ) : (
            <PdfViewer
              height={window.innerHeight - 80}
              urlImage={mediaUrl}
              showButtonFullScreen={false}
            />
          )}
        </>
      )}
      {isImage(mediaType) && <Image src={mediaUrl} />}
      {isWebsite(mediaType) && (
        <iframe
          src={mediaUrl}
          title={mediaUrl}
          style={{ width: '100%', height: '100%', margin: '96px 0px 0px 0px' }}
        />
      )}
    </Container>
  );
};

export default FullScreen;
