import React, { ReactNode, useState } from 'react';
import { CSSProperties } from 'styled-components';
import NotFound from '../other/notFound/NotFound';
import ButtonBack from '../buttons/buttonBack/ButtonBack';
import { Container, Subtext, Title } from './styles';
import { useCustomStyle } from '../../functions/hooks';
import Input from '../input/Input';

interface PContainerPage {
  title: string;
  style?: CSSProperties;
  subText?: string;
  menuUrl: string;
  renderList: ReactNode;
  blockScreen: boolean;
  listLength: number;
  notFoundTitle: string;
  hideBackButton?: boolean;
  filterInput?: (value: string) => void;
  placeholder?: string;
}

const ContainerPage = ({
  title,
  menuUrl,
  renderList,
  notFoundTitle,
  blockScreen,
  style,
  listLength,
  subText,
  hideBackButton,
  filterInput,
  placeholder,
}: PContainerPage) => {
  const { textColor, backgroundSecondary } = useCustomStyle();
  const [filterValue, setFilterValue] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    if (filterInput) {
      filterInput(event.target.value);
    }
  };

  const renderNotFound = () => (
    <NotFound>
      {notFoundTitle}
      <br />
      Tente mais tarde.
    </NotFound>
  );

  return (
    <Container style={style}>
      {!hideBackButton && <ButtonBack urlBack={menuUrl} />}
      <Title style={textColor}>{title}</Title>
      {subText && <Subtext>{subText}</Subtext>}
      {filterInput && (
        <Input
          type="text"
          placeholder={placeholder}
          value={filterValue}
          onChange={handleFilterChange}
          icon="search"
          customBackground={backgroundSecondary.backgroundColor}
        />
      )}
      {!blockScreen && listLength === 0 ? renderNotFound() : renderList}
    </Container>
  );
};

ContainerPage.defaultProps = {
  style: {},
  subText: '',
  hideBackButton: false,
  filterInput: undefined,
  placeholder: '',
};

export default ContainerPage;
