import React from 'react';
import InputFile from '../../../shared/components/input/InputFile';
import BaseForm from './BaseForm';
import { t } from '../../../i18n';

class UploadFileForm extends BaseForm {
  buildBody = () => {
    return (
      <InputFile
        title={t('loadImage')}
        setUrlImage={(url: string) => this.handleChangeValue({ name: 'answer', value: url })}
        setIsLoading={this.setIsLoading}
      />
    );
  };
}

export default UploadFileForm;
