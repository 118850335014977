/* eslint-disable import/no-cycle */
import Axios, { AxiosRequestConfig } from 'axios';
import * as Constants from '../../constants/requests';
import * as Auth from './auth';
import {
  ERROR_CONNECTION,
  ERROR_INTERNAL,
  ERROR_NOT_FOUND,
  ERROR_LIMIT_EXCEEDED,
  ERROR_MAINTENANCE,
  ERROR_USER_BLOCKED,
} from '../../constants/messages';

export const headerRequests = async () => ({
  Authorization: await Auth.getAuthorizationToken(),
  'Content-Type': 'application/json',
  'Front-Version': 9,
});

interface CONNECT_CALL {
  url: string;
  method: string;
  body: Record<string, unknown> | FormData;
  config?: AxiosRequestConfig | Record<string, never>;
}

export default class Connect {
  static async call({ url, method, body, config = {} }: CONNECT_CALL) {
    let answer;
    let configs = config;
    const headers = await headerRequests();

    try {
      configs = {
        ...configs,
        headers,
      };
    } catch (e) {
      configs = {};
    }

    switch (method) {
      case Constants.GET:
        answer = Axios.get(url, configs);
        break;
      case Constants.POST:
        answer = Axios.post(url, body, configs);
        break;
      case Constants.DELETE:
        answer = Axios.delete(url, configs);
        break;
      case Constants.PUT:
        answer = Axios.put(url, body, configs);
        break;
      case Constants.PATCH:
        answer = Axios.patch(url, body, configs);
        break;
      default:
        answer = Axios.get(url, configs);
        break;
    }

    return answer;
  }

  static async connect(
    url: string,
    method: string,
    body: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig | Record<string, never>,
  ) {
    return Connect.call({ url, method, body, config }).catch(error => {
      if (error.response) {
        switch (error.response.status) {
          case 302:
            throw new Error(error.response.data.message);
          case 403:
          case 401:
            throw new Error(ERROR_USER_BLOCKED);
          case 404:
            throw new Error(ERROR_NOT_FOUND);
          case 400:
            throw new Error(ERROR_LIMIT_EXCEEDED);
          case 422:
            if (typeof error.response.data?.message === 'string') {
              throw new Error(error.response.data.message);
            } else if (typeof error.response.data?.message === 'object') {
              throw new Error(JSON.stringify(error.response.data.message));
            }
            throw new Error(ERROR_INTERNAL);
          case 423:
            throw new Error(error.response.data.message);
          case 500:
            throw new Error(ERROR_INTERNAL);
          case 503:
            throw new Error(ERROR_MAINTENANCE);
          default:
            throw new Error(ERROR_CONNECTION);
        }
      } else if (error.message === 'Upload canceled by user.') {
        throw error;
      }

      throw new Error(`${ERROR_CONNECTION} - ${method} ${url}`);
    });
  }
}

export async function ConnectApiPost(
  url: string,
  body: Record<string, unknown> | FormData,
  config?: AxiosRequestConfig | Record<string, never>,
) {
  return Connect.connect(url, Constants.POST, body, config);
}

export async function ConnectApiPUT(url: string, body: Record<string, unknown>) {
  return Connect.connect(url, Constants.PUT, body);
}

export async function ConnectApiPatch(url: string, body: Record<string, unknown>) {
  return Connect.connect(url, Constants.PATCH, body);
}

export async function ConnectApiGet(url: string) {
  return Connect.connect(url, Constants.GET, {});
}

export async function ConnectApiDelete(url: string) {
  return Connect.connect(url, Constants.DELETE, {});
}
