import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { ImNewTab } from 'react-icons/im';
import { colors } from '../../../functions/colors';

import { Container, Text } from './styles';

interface PButtonMenus extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  text: string;
  isSelected?: boolean;
  textColor?: string;
  customBackground?: string;
  primaryColor: string;
  newTab?: boolean;
}

const ButtonMenu: FC<PButtonMenus> = ({
  icon,
  text,
  isSelected,
  textColor,
  customBackground,
  newTab,
  primaryColor,
  ...props
}: PButtonMenus) => (
  <Container
    primaryColor={primaryColor}
    isSelected={isSelected}
    {...props}
    customBackground={customBackground}
  >
    {icon}
    <Text isSelected={isSelected} textColor={textColor}>
      <p style={{ color: textColor }}>{text}</p>
      {newTab && <ImNewTab style={{ marginLeft: '5px', alignSelf: 'bottom' }} fontSize="small" />}
    </Text>
  </Container>
);

ButtonMenu.defaultProps = {
  isSelected: false,
  textColor: colors.white,
  customBackground: '',
  newTab: false,
};

export default ButtonMenu;
