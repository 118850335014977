export const URL_AUTH = `${process.env.REACT_APP_API_URL}/auth`;
export const URL_REFRESH_TOKEN = `${process.env.REACT_APP_API_URL}/auth/refreshToken`;

export const URL_COMPANY = `${process.env.REACT_APP_API_URL}/company`;
export const URL_COMPANY_ID = `${process.env.REACT_APP_API_URL}/company/{idCompany}`;
export const URL_COMPANY_BLOCK = `${process.env.REACT_APP_API_URL}/company/{idCompany}/block`;
export const URL_COMPANY_UNLOCK = `${process.env.REACT_APP_API_URL}/company/{idCompany}/unlock`;
export const URL_COMPANY_PREFERENCES_STYLES = `${process.env.REACT_APP_API_URL}/preferences/styles`;
export const URL_COMPANY_PREFERENCES_STYLES_BY_SUBDOMAIN = `${process.env.REACT_APP_API_URL}/preferences/styles/by_subdomain`;

export const URL_USER = `${process.env.REACT_APP_API_URL}/user`;
export const URL_USER_ADMIN_COMPANY = `${process.env.REACT_APP_API_URL}/user/adminCompany`;
export const URL_USER_ID = `${process.env.REACT_APP_API_URL}/user/{idUser}`;
export const URL_USER_EDIT_PROFILE = `${process.env.REACT_APP_API_URL}/user/profile/{idUser}`;
export const URL_USER_INSERT_ADMIN_COMPANY = `${process.env.REACT_APP_API_URL}/user/insertAdminCompany`;
export const URL_USER_BLOCK = `${process.env.REACT_APP_API_URL}/user/{idUser}/block`;
export const URL_USER_UNLOCK = `${process.env.REACT_APP_API_URL}/user/{idUser}/unlock`;
export const URL_USER_PASS_CODE = `${process.env.REACT_APP_API_URL}/user/passCode`;
export const URL_USER_VERIFY_CODE = `${process.env.REACT_APP_API_URL}/user/verifyCode`;
export const URL_USER_REDEFINE_PASS = `${process.env.REACT_APP_API_URL}/user/redefinePass`;
export const URL_USER_UPDATE_PASSWORD = `${process.env.REACT_APP_API_URL}/user/updatePassword`;
export const URL_USER_WALLET = `${process.env.REACT_APP_API_URL}/wallet`;
export const URL_USER_UPDATE_REWARD_NOTIFICATION = `${process.env.REACT_APP_API_URL}/user/rewards_notification`;
export const URL_CLEAR_USER_LEAGUE_STATUS = `${process.env.REACT_APP_API_URL}/user/league_status`;

export const URL_CATEGORY = `${process.env.REACT_APP_API_URL}/category`;
export const URL_CATEGORY_ID = `${process.env.REACT_APP_API_URL}/category/{idCategory}`;
export const URL_ALTERNATIVE = `${process.env.REACT_APP_API_URL}/alternative`;
export const URL_QUESTION = `${process.env.REACT_APP_API_URL}/question`;
export const URL_QUESTION_ID = `${process.env.REACT_APP_API_URL}/question/{idQuestion}`;
export const URL_QUESTIONS_CATEGORY = `${process.env.REACT_APP_API_URL}/question/{idCategory}`;
export const URL_ALTERNATIVE_QUESTION = `${process.env.REACT_APP_API_URL}/alternative/{idQuestion}`;

export const URL_TOURNAMENT = `${process.env.REACT_APP_API_URL}/tourney`;
export const URL_TOURNAMENT_AREA = `${process.env.REACT_APP_API_URL}/tourney/area`;
export const URL_TOURNAMENT_ID = `${process.env.REACT_APP_API_URL}/tourney/{idTourney}`;

export const URL_MATCH = `${process.env.REACT_APP_API_URL}/match`;
export const URL_FEEDBACK = `${process.env.REACT_APP_API_URL}/match/question/{idMatch}`;
export const URL_MATCH_RANKING = `${process.env.REACT_APP_API_URL}/match/ranking/{idTourney}`;
export const URL_ANSWER = `${process.env.REACT_APP_API_URL}/match/answer`;

export const URL_AREA = `${process.env.REACT_APP_API_URL}/area`;
export const URL_AREA_ID = `${process.env.REACT_APP_API_URL}/area/{idArea}`;

export const URL_UPLOAD_IMAGE = `${process.env.REACT_APP_API_URL}/file/uploadImage`;
export const URL_REQUEST_PRESIGNED_S3_URL = `${process.env.REACT_APP_API_URL}/aws/s3/presigned_url`;

export const URL_REPORT_USER_APP = `${process.env.REACT_APP_REPORT_URL}/report/user/app`;

export const URL_AWARDS_APP = `${process.env.REACT_APP_API_URL}/user/rewards`;
export const URL_AWARDS_ID = `${process.env.REACT_APP_API_URL}/user/rewards/{idAward}`;
export const URL_AWARDS_ACQUIRED_APP = `${process.env.REACT_APP_API_URL}/user/purchases`;
export const URL_AWARDS_MANAGER = `${process.env.REACT_APP_API_URL}/user/purchases`;

export const ANSWER_CHALLENGE_URL = `${process.env.REACT_APP_API_URL}/challenges/:challengeId/answer`;

export const SELF_SIGN_UP_CAMPAIGN_URL = `${process.env.REACT_APP_API_URL}/signup/:hash`;
export const SELF_SIGN_UP_UPDATE_URL = `${process.env.REACT_APP_API_URL}/signup/updater`;
export const URL_SELF_SIGN_UP_NEW_COMPANY_ASSOCIATION = `${process.env.REACT_APP_API_URL}/signup/:hash/associate`;

export const JOURNEYS_URL = `${process.env.REACT_APP_API_URL}/journeys/playable`;
export const JOURNEY_URL = `${process.env.REACT_APP_API_URL}/journeys/:journeyId/playable`;
export const JOURNEY_DEPENDENCIES_URL = `${process.env.REACT_APP_API_URL}/journeys/:journeyId/dependencies`;
export const JOURNEY_STEPS_URL = `${process.env.REACT_APP_API_URL}/journeys/:journeyId/playable_phases`;

export const PRIVACY_URL = `${process.env.REACT_APP_API_URL}/user/privacy/`;

export const EVENT_URL = `${process.env.REACT_APP_API_URL}/event`;

export const DATADOG_KEYS_URL = `${process.env.REACT_APP_API_URL}/credentials/datadog`;

export const TUTORIAL_URL = `${process.env.REACT_APP_API_URL}/user/tutorial_statuses`;

export const FEED_URL = `${process.env.REACT_APP_API_URL}/user/feed`;

export const CHECKOUT_PRODUCT_ID_URL = `${process.env.REACT_APP_API_URL}/checkout/:id`;
