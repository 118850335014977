import React from 'react';
import {
  Table,
  Line,
  Column,
  TextLimited,
} from '../../shared/components/table/tableRanking/styles';
import { useCustomStyle } from '../../shared/functions/hooks';
import { User } from '../../shared/modals/user/user';
import { MissionDescription, ReferralContainer } from './styles';

const ReferralsList = ({ memberGetMember }: User) => {
  const { textColor, backgroundSecondary } = useCustomStyle();

  if (!memberGetMember) return null;

  return (
    <ReferralContainer>
      <MissionDescription style={textColor}>{memberGetMember.title}</MissionDescription>
      <Table style={{ display: 'flex', flexDirection: 'column' }}>
        {memberGetMember.referrals.map((referral, index) => {
          const [date, time] = referral.createdAt.toString().split(' ');
          return (
            <Line
              key={`table_ranking_line_${index}`}
              isPlayerLogged={false}
              customBackground={
                backgroundSecondary.backgroundColor
                  ? backgroundSecondary.backgroundColor
                  : '#262824'
              }
              style={{
                display: 'flex',
                marginBottom: '4px',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Column style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextLimited
                  style={{ color: textColor.color, fontWeight: 'bold', alignSelf: 'flex-start' }}
                >
                  {referral.name}
                </TextLimited>
                <Column style={{ color: textColor.color, fontWeight: 'bold' }}>{date}</Column>
              </Column>
              <Column style={{ color: textColor.color, fontWeight: 'bold' }}>{time}</Column>
            </Line>
          );
        })}
      </Table>
    </ReferralContainer>
  );
};

export default ReferralsList;
