import React from 'react';
import ReactLoading from 'react-loading';
import { colors } from '../../functions/colors';

const Spinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: colors.white,
      }}
    >
      <div>
        <ReactLoading type="spin" width={44} height={40} />
      </div>
      <div style={{ margin: 8 }}>Carregando...</div>
    </div>
  );
};

export default Spinner;
