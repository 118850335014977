import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';
import { colors } from '../../../shared/functions/colors';

interface PTimeLeft {
  percentage: number;
}

export const Container = styled.div`
  overflow: auto;
`;

export const ContainerProgress = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: ${colors.background};
  z-index: 1;
`;

export const BoxAlternatives = styled.div`
  margin-bottom: 120px;
`;

export const ContainerImage = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 16px;

  img {
    max-width: 90%;
  }
`;

export const BoxQuestion = styled.div`
  background-color: ${colors.black};
  border-left: 4px solid ${colors.purpleRegular};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const TextQuestion = styled.p`
  text-align: left;
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const NumberQuestion = styled.p`
  color: ${colors.white};
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const TimeLeft = styled.p<PTimeLeft>`
  color: ${colors.white};
  width: 100%;
  text-align: center;
  margin-top: 8px;
  color: ${props =>
    props.percentage > 30 ? `${props.percentage > 70 ? colors.green : colors.orange}` : colors.red};
  transition: 1s ease-out;

  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;
