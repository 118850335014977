import { ConnectApiGet, ConnectApiPost, urls } from '../functions/connection';
import { Award } from '../modals/award/award';

export const getAward = async (idAward: string) => {
  const respostaServico = await ConnectApiGet(
    urls.URL_AWARDS_ID.replace('{idAward}', `${idAward}`),
  );
  return respostaServico.data;
};

export const getAwards = async (): Promise<Award[]> => {
  const respostaServico = await ConnectApiGet(urls.URL_AWARDS_APP);
  return respostaServico.data;
};

export const getAwardsAcquired = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_AWARDS_ACQUIRED_APP);
  return respostaServico.data;
};

export const insertAwardInRequered = async (idAward: string) => {
  const respostaServico = await ConnectApiPost(urls.URL_AWARDS_MANAGER, { idAward });
  return respostaServico.data;
};
