import * as React from 'react';
import { colors } from '../../functions/colors';

interface PCleanArrowRight {
  width?: number;
  height?: number;
  color?: string;
}

const CleanArrowRight = ({ width, height, color }: PCleanArrowRight) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={color}>
    <title>clean-arrow-right2</title>
    <mask
      id="mask-clean-arrow-right"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="6"
      width="8"
      height="12"
    >
      <path
        d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask-clean-arrow-right)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

CleanArrowRight.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
};

export default CleanArrowRight;
