import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { actions as geralActions } from '../../store/reducers/geral';
import * as journeysService from '../../shared/services/journeys';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { JourneyInterface } from './Journey';
import JourneyCard from '../../shared/components/journeyCard/JourneyCard';
import ContainerPage from '../../shared/components/containerPage/ContainerPage';
import { RootStateGlobal } from '../../store/reducer';
import { t } from '../../i18n';
import { JourneyContainer } from './styles';
import Spinner from '../../shared/components/loadings/Spinner';

const JourneyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setBlockScreen = async (x: boolean) => dispatch(geralActions.setBlockScreen(x));
  const [journeys, setJourneys] = useState<JourneyInterface[]>([]);
  const { blockScreen } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const [filteredJourneys, setFilteredJourneys] = useState<JourneyInterface[] | undefined>(
    undefined,
  );

  useEffect(() => {
    (async () => {
      setBlockScreen(true);
      setJourneys(await journeysService.getAll({}));
      setFilteredJourneys(await journeysService.getAll({}));
      setBlockScreen(false);
    })();
  }, []);

  if (filteredJourneys === undefined) return <Spinner />;

  const handleFilter = (name: string) => {
    setFilteredJourneys(
      journeys.filter(journey => journey.name.toLowerCase().includes(name.toLowerCase())),
    );
  };

  const renderJourneys = () => {
    // Does not show the list when there is only 1 journey
    if (journeys.length === 1) {
      const journey = journeys[0];
      history.replace(`/journeys/${journey.id}/play`);
      return null;
    }

    return (
      <JourneyContainer>
        {filteredJourneys.map((journey, index) => (
          <JourneyCard key={index} journey={journey} />
        ))}
      </JourneyContainer>
    );
  };

  return (
    <ContainerPage
      title={t('journeyList')}
      subText={t('subtextJourney')}
      menuUrl={MenuUrl.home}
      blockScreen={blockScreen}
      listLength={filteredJourneys.length}
      notFoundTitle={t('notFoundJourney')}
      renderList={renderJourneys()}
      hideBackButton
      filterInput={handleFilter}
      placeholder={t('placeholderJourney')}
    />
  );
};

export default JourneyList;
