import styled from 'styled-components';
import { fonts } from '../../shared/functions/fonts';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  background-color: ${colors.background};
`;

export const Header = styled.div`
  display: flex;
  padding: 24px 24px 0px;
  justify-content: space-between;
  align-items: center;
`;

export const NameTourney = styled.h1`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.white};
`;

export const Title = styled.h1`
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.white};
  margin-top: 24px;
`;

export const SubTitle = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: ${colors.white};
  margin-bottom: 24px;
`;

export const BoxWinners = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

export const BoxRanking = styled.div`
  margin-bottom: 160px;
`;

export const BoxButtonBackgroundSolid = styled.div`
  background-color: ${colors.background};
  padding: 0px 24px 32px 24px;
  display: flex;
  max-width: 420px;
  width: 100%;
  justify-content: center;
`;

export const BoxButton = styled.div`
  display: flex;
  width: 100%;
  bottom: 0;
  justify-content: center;
`;

export const TextJustification = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${fonts.semiBold};
  color: ${colors.purpleRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;
