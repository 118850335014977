import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const ImgLogo = styled.img`
  margin: 16px auto 16px;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-family: ${fonts.bold};
  color: ${colors.purpleRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

export const Text = styled.p`
  color: ${colors.black};
  text-align: left;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  margin: 16px 0px;
`;
