import React from 'react';
import { Container, Text, Title } from './styles';
import { t } from '../../../i18n';

const Waiting = () => {
  return (
    <Container>
      <img
        style={{ width: 'inherit', maxWidth: '100%' }}
        src="/images/waiting.png"
        alt={t('nothingHere')}
      />

      <div>
        <Title>{t('nothingHere')}</Title>
        <Text>{t('doLesson')}</Text>
      </div>
    </Container>
  );
};

export default Waiting;
