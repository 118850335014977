import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';
import { colors } from '../../../shared/functions/colors';

interface PFirst {
  isFirst?: boolean;
}

export const Container = styled.div<PFirst>`
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => (props.isFirst ? '30%' : '28%')};
  min-width: 80px;
`;

export const BoxPosition = styled.div<PFirst>`
  width: ${props => (props.isFirst ? '64px' : '48px')};
  margin-top: ${props => (props.isFirst ? '0px' : '80px')};
  position: relative;
`;

export const ImagePosition = styled.img`
  width: 100%;
`;

export const ImagePositionTop = styled.img`
  position: absolute;
  bottom: 6px;
  left: -8px;
  z-index: 4;
`;

export const ImageUser = styled.img<PFirst>`
  position: absolute;
  object-fit: cover;
  bottom: 8px;
  left: ${props => (props.isFirst ? '5px' : '0px')};
  width: ${props => (props.isFirst ? '56px' : '48px')};
  height: ${props => (props.isFirst ? '56px' : '48px')};
  border-radius: 50%;
  z-index: 3;
`;

export const PlayerName = styled.h2<PFirst>`
  color: ${props => (props.isFirst ? colors.orange : colors.white)};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  width: 120px;
  height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxHitsTime = styled.div<PFirst>`
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;

  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${props => (props.isFirst ? colors.orange : colors.white)};
`;

export const BoxHits = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
