import styled from 'styled-components';
import { fonts } from '../../shared/functions/fonts';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  max-width: 420px;
  align-self: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const Title = styled.h1`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.white};
`;

export const ImageIcon = styled.label`
  cursor: pointer;
`;

export const ImageProfile = styled.img`
  margin: 24px auto 12px;
  max-width: 104px;
  max-height: 104px;
  width: 104px;
  height: 104px;
  border-radius: 55px;
  object-fit: cover;
`;

export const ImageFlagContainer = styled.div`
  position: absolute;
  top: 100px;
  right: -12px;
  border-radius: 8px;
  background: var(--color-black, #262824);
`;

export const BoxButton = styled.div`
  width: 100%;
  max-width: 420px;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  padding: 24px 24px 0px;
  justify-content: space-between;
  align-items: center;
`;

export const BoxLogout = styled.div`
  width: 100%;
  padding: 0 24px;
  max-width: 420px;
  display: none;
  justify-content: left;

  @media only screen and (max-width: 900px) {
    display: flex;
  }
`;

export const ShortIdWrapper = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
