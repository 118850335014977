import { RankData } from '../../../shared/modals/socket/Socket';
import { Result, Tournament } from '../../../shared/modals/tournament/Tournament';

import { RankingTypes } from './rankingTypes';

export const setRanking = (ranking: RankData[]) => ({
  type: RankingTypes.SET_RANKING,
  payload: ranking,
});

export const setCountdown = (time: number) => ({
  type: RankingTypes.SET_TIME,
  payload: time,
});

export const setCurrentTourney = (tourney?: Tournament) => ({
  type: RankingTypes.SET_CURRENT_TOURNEY,
  payload: tourney,
});

export const setResult = (result?: Result) => ({
  type: RankingTypes.SET_RESULT,
  payload: result,
});
