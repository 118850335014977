import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  text-align: left;
  padding: 72px 0px 24px 0px;
`;

export const Title = styled.h1`
  margin: 16px 24px;
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.white};
`;

export const Text = styled.p`
  margin: 24px;
  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.17px;
`;
