import React, { ReactNode } from 'react';
import {
  Editor,
  EditorState,
  CompositeDecorator,
  ContentState,
  ContentBlock,
  convertFromRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { noop } from 'lodash';
import { colors } from '../../shared/functions/colors';

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link = (props: { contentState: ContentState; entityKey: string; children: ReactNode }) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a
      href={url}
      style={{ color: colors.link, textDecoration: 'underline' }}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const RichTextEditor = ({ value }: { value: string }) => {
  let content: ContentState;

  // Our first version of presentations stored presentation.content as a
  // simple string. Below check makes the old stored values still loadable
  // by the rich-text editor.
  try {
    // If conversion succeeds, it's a valid Draft-js object
    content = convertFromRaw(JSON.parse(value));
  } catch {
    // If it throws, that's because it's a simple string or a null value.
    // Thus, we create a valid Draft-js object from the string so that we
    // can initialize the editor.
    content = ContentState.createFromText(value || '');
  }
  const editorState = EditorState.createWithContent(content, decorator);

  return <Editor editorState={editorState} onChange={noop} readOnly />;
};

export default RichTextEditor;
