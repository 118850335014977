import React from 'react';
import { History } from 'history';
import { Dispatch } from 'redux';
import Challenge from '../../../features/challenge/Challenge';
import { PlayableChallenge } from '../../constants/types';
import { ModalData } from '../../modals/geral/geral';
import { ChallengeParamsInterface } from '../../services/challenges';
import * as challengesService from '../../services/challenges';
import { setCoinsEarned, setCanPurchaseSomethingNew } from '../../../store/reducers/geral/actions';
import { MenuUrl } from '../../enums/menuUrl';
import { t } from '../../../i18n';

export const handleChallengeSubmission = (
  params: ChallengeParamsInterface,
  challengeId: string,
  callback: () => void,
  dispatch: Dispatch,
) => {
  challengesService.submitAnswer(challengeId, params).then(response => {
    if (response) {
      Promise.all([
        dispatch(setCanPurchaseSomethingNew(response.data.canPurchaseSomethingNew)),
        dispatch(setCoinsEarned(response.data.coinsEarned)),
      ]);
    }
    callback();
  });
};

interface StartChallenge {
  challenge: PlayableChallenge;
  setModalData: (x: ModalData) => void;
  closeModal: () => void;
  exitToPath: string;
  history: History;
  callback: () => void;
  index?: string;
  dispatch: Dispatch;
  customBackground?: string;
}

export const handleJourneyStartChallenge = ({
  challenge,
  setModalData,
  closeModal,
  exitToPath,
  history,
  callback,
  index,
  dispatch,
  customBackground,
}: StartChallenge) => {
  const title = index ? `Desafio #${index} - ${challenge.title}` : challenge.title;

  if (challenge.presentations.length) {
    closeModal();
    history.push(MenuUrl.presentation, {
      gameable: challenge,
      exitToPath,
      title,
      customBackground,
    });
  } else {
    setModalData({
      show: true,
      height: '60%',
      children: (
        <Challenge
          challenge={challenge}
          customBackground={customBackground}
          title={title}
          handleSubmission={(params: ChallengeParamsInterface, challengeId: string) =>
            handleChallengeSubmission(params, challengeId, callback, dispatch)
          }
        />
      ),
      title: t('more'),
    });
  }
};
