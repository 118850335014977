import React, { Component } from 'react';
import Button from '../../../shared/components/buttons/Button';
import { PlayableChallenge } from '../../../shared/constants/types';
import { ChallengeParamsInterface } from '../../../shared/services/challenges';
import { t } from '../../../i18n';

export interface BaseFormProps {
  challenge: PlayableChallenge;
  handleSubmission: (params: ChallengeParamsInterface) => void;
  customBackground?: string;
}

interface BaseFormState {
  answer: string;
  comment?: string;
  isLoading: boolean;
}

const INSTRUCTION_WITH_PRIZE = t('prizeInstructions');
const INSTRUCTION_WITHOUT_PRIZE = t('noPrizeInstructions');

class BaseForm extends Component<BaseFormProps, BaseFormState> {
  static defaultProps = {
    customBackground: '',
  };

  constructor(props: BaseFormProps) {
    super(props);

    this.state = {
      answer: '',
      isLoading: false,
    };
  }

  handleChangeValue = (target: { name: string; value: string }) => {
    const { name, value } = target as HTMLInputElement;
    const state = { [name]: value } as unknown;
    this.setState(state as Pick<BaseFormState, keyof BaseFormState>);
  };

  buildInstructions = () => {
    const { challenge } = this.props;

    const instructions = challenge.prize
      ? INSTRUCTION_WITH_PRIZE.replace(':coins', challenge.prize.toString())
      : INSTRUCTION_WITHOUT_PRIZE;

    return <div className="challenge-form__instructions">{instructions}</div>;
  };

  buildButton = () => {
    const { isLoading, answer } = this.state;
    const { customBackground } = this.props;

    return (
      <Button
        typeButton="card-purple"
        loading={isLoading}
        disabled={!answer.length}
        onClick={this.handleSubmission}
        customBackground={answer.length ? customBackground : ''}
      >
        {t('send')}
      </Button>
    );
  };

  buildBody = () => {
    // Implemented by children
    return <></>;
  };

  handleSubmission = () => {
    const { handleSubmission } = this.props;
    const { answer, comment } = this.state;

    this.setIsLoading(true);
    handleSubmission({ answer, comment });
  };

  setIsLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  render() {
    return (
      <>
        {this.buildInstructions()}
        {this.buildBody()}
        {this.buildButton()}
      </>
    );
  }
}

export default BaseForm;
