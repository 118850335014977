import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';

import { Container } from '../login/styles';
import { getCheckoutProductById } from '../../shared/services/checkoutProduct';
import { CheckoutProduct as CheckoutProductModel } from '../../shared/modals/checkoutProduct/checkoutProduct';
import Spinner from '../../shared/components/loadings/Spinner';
import { ProductDetail } from './ProductDetail';
import { Form } from './Form';
import NotFound from './NotFound';

const CheckoutProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [checkoutProduct, setCheckoutProduct] = useState<CheckoutProductModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCheckoutProduct = useCallback(async () => {
    const result = await getCheckoutProductById(productId);
    setCheckoutProduct(result);
  }, []);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      await fetchCheckoutProduct();
      setLoading(false);
    }

    fetch();
  }, [fetchCheckoutProduct]);

  // Show Spinner when first loading
  if (loading && !checkoutProduct) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (!checkoutProduct) {
    return (
      <Container>
        <NotFound />
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} order={{ sm: 1, md: 2 }}>
          <ProductDetail checkoutProduct={checkoutProduct} />
        </Grid>
        <Grid item sm={12} md={6} order={{ sm: 2, md: 1 }}>
          <Form companyImageUrl={checkoutProduct.companyImageUrl} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutProduct;
