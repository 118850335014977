import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../functions/colors';

interface PClockIcon {
  width?: number;
  height?: number;
  style?: CSSProperties;
  color?: string;
}

const ClockIcon = ({ width, height, style, color }: PClockIcon) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="prefix__ClockIcon" maskUnits="userSpaceOnUse" x={2} y={0} width={12} height={15}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 .667h4V2H6V.667zm1.333 8.666v-4h1.334v4H7.333zm5.354-4.406l.946-.947c-.286-.34-.6-.66-.94-.94l-.946.947A5.975 5.975 0 008 2.667a6 6 0 106 6 5.975 5.975 0 00-1.313-3.74zm-9.354 3.74A4.663 4.663 0 008 13.333a4.663 4.663 0 004.667-4.666A4.663 4.663 0 008 4a4.663 4.663 0 00-4.667 4.667z"
        fill={colors.white}
      />
    </mask>
    <g mask="url(#prefix__ClockIcon)">
      <path fill={color} d="M0 0h16v16H0z" />
    </g>
  </svg>
);

ClockIcon.defaultProps = {
  width: 16,
  height: 16,
  style: {},
  color: colors.white,
};

export default ClockIcon;
