import { datadogRum } from '@datadog/browser-rum';
import { User } from '../modals/user/user';

// Reference: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
export const setUser = (userData: User) => {
  // TODO: add CPF and whatsapp to the payload
  datadogRum.setUser({
    id: `${userData.idUser}`,
    name: userData.name,
    email: userData.email,
    type: userData.typeUser.name,
    company: userData.company.name,
    companyId: userData.company.idCompany,
  });
};

// Reference: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#remove-the-user-identification
export const { removeUser } = datadogRum;
