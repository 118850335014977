import React from 'react';
import { Grid } from '@mui/material';

import { TextField } from './TextField';
import { SelectInput } from './SelectInput';
import { t } from '../../i18n';

export const FormCardFields = () => {
  const installments = [
    { label: '1x de R$ 1.000,00', value: '1' },
    { label: '2x de R$ 500,00', value: '2' },
    { label: '3x de R$ 333,33', value: '3' },
    { label: '4x de R$ 250,00', value: '4' },
    { label: '5x de R$ 200,00', value: '5' },
    { label: '6x de R$ 166,67', value: '6' },
    { label: '7x de R$ 142,86', value: '7' },
    { label: '8x de R$ 125,00', value: '8' },
    { label: '9x de R$ 111,11', value: '9' },
    { label: '10x de R$ 100,00', value: '10' },
    { label: '11x de R$ 90,91', value: '11' },
    { label: '12x de R$ 83,33', value: '12' },
  ]; // ToDo: get this from backend

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <TextField
            name="cardName"
            label={t('cardNameLabel')}
            placeholder={t('genericPlaceholder')}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextField
            name="cardNumber"
            label={t('cardNumberLabel')}
            placeholder={t('genericPlaceholder')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <TextField
            name="cardExpirationDate"
            label={t('cardExpirationDateLabel')}
            placeholder={t('genericPlaceholder')}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextField name="cardSecurityCode" label="CVV" placeholder={t('genericPlaceholder')} />
        </Grid>
      </Grid>
      <SelectInput
        name="cardNumberOfInstallments"
        id="numeroDeParcelas"
        label={t('numberOfInstallmentsLabel')}
        options={installments}
      />
    </>
  );
};
