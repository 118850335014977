import styled from 'styled-components';
import { fonts } from '../../../../functions/fonts';
import { colors } from '../../../../functions/colors';

export const Container = styled.div``;

export const TextBold = styled.p`
  margin-top: 16px;
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.black};
`;
