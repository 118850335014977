import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import { Container } from './styles';

interface PButtonText extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  color: string;
}

const ButtonText: FC<PButtonText> = ({ children, color, ...props }: PButtonText) => (
  <Container color={color} {...props}>
    {children}
  </Container>
);

export default ButtonText;
