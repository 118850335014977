import { Dispatch } from 'redux';

import * as awardActions from './awardActions';
import * as serviceAward from '../../../shared/services/serviceAward';
import { ERROR_MAINTENANCE } from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { AwardAcquired } from '../../../shared/modals/award/award';
import { getUserWallet } from '../user/operations';

export const getAwards = () => async (dispatch: Dispatch) => {
  try {
    const awards = await serviceAward.getAwards();
    const awardsAcquired: AwardAcquired[] = await serviceAward.getAwardsAcquired();
    dispatch(
      awardActions.setAcquiredAward(
        awardsAcquired.map(awardAcquired => ({
          ...awardAcquired.award,
          dateAcquired: awardAcquired.createdAt,
          status: awardAcquired.status,
        })),
      ),
    );
    dispatch(awardActions.setAvailableAward(awards));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }
};

export const getAward = (idAward: string) => async (dispatch: Dispatch) => {
  try {
    const award = await serviceAward.getAward(idAward);
    dispatch(awardActions.setAward(award));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }
};

export const insertAwardInRequered = (idAward: string) => async (dispatch: Dispatch) => {
  try {
    await serviceAward.insertAwardInRequered(idAward);
    await getAwards()(dispatch);
    await getUserWallet()(dispatch);
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
    if (error instanceof Error) {
      throw error;
    }
  }
};
