import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { actions as geralAction } from '../../../store/reducers/geral';
import { SnackbarData } from '../../modals/geral/geral';

import { Container, BoxMessage, Close } from './styles';

export interface PSnackbar {
  type: string;
  message: string;
  show: boolean;
  setSnackBar?: Dispatch<SetStateAction<PSnackbar>>;
}

const SnackBar = () => {
  const dispatch = useDispatch();
  const { snackbarData } = useSelector((state: RootStateOrAny) => state.geralReducer);
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const position = snackbarData.show ? 0 : -3000;

  const close = () => {
    setSnackBar({
      ...snackbarData,
      show: false,
    });
  };

  useEffect(() => {
    if (snackbarData.show) setTimeout(close, 5000);
  }, [snackbarData.show]);

  return (
    <Container position={position} style={{ pointerEvents: 'none' }}>
      <BoxMessage type={snackbarData.type} style={{ pointerEvents: 'all', zIndex: 1 }}>
        {snackbarData.message}
      </BoxMessage>
      <Close style={{ pointerEvents: 'all', zIndex: 1 }} onClick={close}>
        x
      </Close>
    </Container>
  );
};

export default SnackBar;
