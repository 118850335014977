import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  display: flex;
  padding: 24px;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: ${fonts.semiBold};
  margin-top: 50px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
`;

export const Text = styled.h1`
  font-family: ${fonts.regular};
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  opacity: 0.56;
`;
