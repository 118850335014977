import { t } from '../../i18n';

const AQUILA_COMPANY = 'cdb33eac-2d6f-41b4-9d91-5ee5f664fadb';
const BUSER = '4efe3c02-9ba6-4d20-bede-0bde537add62';
const IFL_BH = 'a8867507-2535-4987-a910-ec42cbcd5c36';
const INSTITUTO_FORMACAO_LIDERES = '833479ea-0965-4561-8b0f-bf7f6cf0139f';
const MAIS_PS = '4abfd569-8c52-4103-9c93-b9e0170c9fbb';
const MARCELO_POLITI = '73967837-8d52-472f-806f-32d186d367b3';
const AMET = 'ada50a5f-90dc-4cb2-91c3-3544f975289d';
const ONE_INVESTIMENTOS = '25e5d978-49e8-483d-9cdd-7bbd155a558d';

export const getPlayButtonText = (idCompany?: string): string => {
  if ([BUSER, IFL_BH].includes(idCompany || '')) {
    return t('answerQuestions');
  }

  if (
    [
      AQUILA_COMPANY,
      MAIS_PS,
      MARCELO_POLITI,
      AMET,
      ONE_INVESTIMENTOS,
      INSTITUTO_FORMACAO_LIDERES,
    ].includes(idCompany || '')
  ) {
    return 'Acessar';
  }
  return t('play');
};
