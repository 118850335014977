import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  max-width: 438px;
  background: ${colors.black};
  border-radius: 4px;
  border-left: 4px solid ${colors.purpleRegular};
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const ContainerImage = styled.div`
  width: 96px;
  margin-right: 16px;
`;

export const Title = styled.h1`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  color: ${colors.white};
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 96px;
`;

export const Text = styled.p`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${colors.orange};

  @media only screen and (max-width: 360px) {
    align-self: center;
  }
`;

export const BackgroundDateInfo = styled.div`
  display: flex;
  background-color: #e11900;
  width: 100%;
  border-radius: 50px;
  text-align: center;
  height: 21px;
  align-items: center;
  margin-bottom: 16px;
`;

export const TextDate = styled.p`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  color: ${colors.white};
  width: 100%;
`;

export const BoxStockAndRules = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BoxButton = styled.div`
  width: 100%;
  min-width: 90px;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  max-width: 96px;
  max-height: 96px;
  border-radius: 4px;
`;

export const ImageGray = styled(Image)`
  filter: grayscale(1);
`;

export const BoxValue = styled.div`
  border-radius: 4px;
  width: 40%;
  height: 32px;
  display: flex;
  align-items: center;
`;

export const ImageCash = styled.img`
  height: 24px;
  z-index: 2;
  margin-right: 4px;
`;
