import { MatchData, QuestionData } from '../../../shared/modals/socket/Socket';
import { Tournament } from '../../../shared/modals/tournament/Tournament';

export enum TournamentTypes {
  SET_TOURNAMENT = '@tournament/SET_TOURNAMENT',
  SET_LIST_TOURNAMENT = '@tournament/SET_LIST_TOURNAMENT',
  SET_QUESTION = '@tournament/SET_QUESTION',
  SET_MATCH = '@tournament/SET_MATCH',
}

export interface TournamentState {
  readonly tournament?: Tournament;
  readonly listTournament: Tournament[];
  readonly question?: QuestionData;
  readonly match?: MatchData;
}
