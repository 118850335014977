import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import { ModalData } from '../../modals/geral/geral';
import { actions as geralActions } from '../../../store/reducers/geral';
import Modal from './Modal';
import { SupportButton } from './styles';
import { useCustomStyle } from '../../functions/hooks';
import { t } from '../../../i18n';

const ContactUsButton = () => {
  const [supportPhone, setSupportPhone] = useState('');
  const dispatch = useDispatch();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { companyStyles } = useSelector(
    (state: RootStateGlobal) => state.companyPreferenceStylesReducer,
  );
  const { showWhatsapp } = useCustomStyle();
  const showModal = () => {
    setModalData({
      show: true,
      height: 'inherit',
      children: <Modal phone={supportPhone} />,
      title: t('needHelp'),
    });
  };

  useEffect(() => {
    setSupportPhone(companyStyles.companySupportWhatsapp);
  }, [companyStyles]);

  return showWhatsapp ? (
    <SupportButton onClick={showModal} isLoggedIn={!!userData}>
      <img style={{ width: 'inherit' }} src="/images/wa-logo.png" alt="Suporte" />
      {t('support')}
    </SupportButton>
  ) : null;
};

export default ContactUsButton;
