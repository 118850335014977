import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const FeedContainer = styled.div`
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 16,
`;

export const PostContainer = styled.div`
  position: relative;
  background-color: ${colors.black};
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 16px;
  gap: 16px;
`;

export const PostHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Image = styled.img`
  background-color: ${colors.black};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  width: 100%;
`;

export const ProfileImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 16px;
  border: 1px solid ${colors.purpleRegular};
`;

export const Name = styled.h2`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

export const Date = styled.h4`
  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 21px;
`;

export const Text = styled.p`
  color: ${colors.white};
`;
