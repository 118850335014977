import { Dispatch } from 'redux';
import { User, Wallet } from '../../../shared/modals/user/user';
import {
  BodyInsertArea,
  BodyUserLogin,
  BodyCreateMember,
  BodyEditMember,
} from '../../../shared/modals/user/bodies';

import {
  ERROR_INVALID_PASSWORD,
  ERROR_MAINTENANCE,
  ERROR_NOT_FOUND,
} from '../../../shared/constants/messages';

import * as userActions from './actions';
import * as leagueActions from '../league/actions';
import * as serviceUser from '../../../shared/services/serviceUser';
import { doLogout, setAuthorizationToken } from '../../../shared/functions/connection/auth';
import { setImageCompany } from '../../../shared/functions/imageCompany';

const updateImageCompany = (userData: User) => {
  if (userData?.company) {
    setImageCompany(userData.company.image);
  }
};

export const reqLogin = (bodyUserLogin: BodyUserLogin, subdomain?: string) => async (
  dispatch: Dispatch,
) => {
  try {
    const userData = await serviceUser.login(bodyUserLogin, subdomain);
    updateImageCompany(userData.user);
    dispatch(userActions.setUserData(userData.user));
    if (userData.seasonConfig) dispatch(leagueActions.setSeason(userData.seasonConfig));
    setAuthorizationToken(userData.token);
  } catch (error) {
    if (error instanceof Error) {
      let { message } = error;

      if (!message || message === ERROR_NOT_FOUND) {
        message = ERROR_INVALID_PASSWORD;
      }

      throw new Error(message);
    }
  }
};

export const getUserData = ({ token = '', ssuHash = '', subdomain = '' }) => async (
  dispatch: Dispatch,
) => {
  try {
    if (token) {
      setAuthorizationToken(token);
    }
    const userData = await serviceUser.getUserData(ssuHash, subdomain);
    updateImageCompany(userData.user);
    dispatch(userActions.setUserData(userData.user));
    if (userData.seasonConfig) dispatch(leagueActions.setSeason(userData.seasonConfig));
    setAuthorizationToken(userData.token);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const getAllUsers = () => async (dispatch: Dispatch) => {
  try {
    const users = await serviceUser.getAllUsers();
    dispatch(userActions.setUsers(users));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const blockUser = (idUser: string) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.blockUser(idUser);
    getAllUsers()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const unlockUser = (idUser: string) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.unlockUser(idUser);
    getAllUsers()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const insertMember = (body: BodyCreateMember) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.insertMember(body);
    getAllUsers()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const editMember = (body: BodyCreateMember, idUser: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.editUser(body, idUser);
    getAllUsers()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const editProfile = (body: BodyEditMember, idUser: string) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.editProfile(body, idUser);
    getUserData({})(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const getAllAreas = () => async (dispatch: Dispatch) => {
  try {
    const areas = await serviceUser.getAllAreas();
    dispatch(userActions.setAreas(areas));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
    dispatch(userActions.setAreas([]));
  }
};

export const insertArea = (body: BodyInsertArea) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.insertArea(body);
    getAllAreas()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const getUserWallet = () => async (dispatch: Dispatch): Promise<Wallet | undefined> => {
  try {
    const wallet = await serviceUser.getUserWallet();
    dispatch(userActions.setWallet(wallet));
    return wallet;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }

    return undefined;
  }
};
