import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PGiftIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const GiftIcon = ({ width, height, color, style }: PGiftIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <mask
      id="mask0_70_6376"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="25"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1573 7.68234H21.5758C21.706 7.31524 21.7889 6.91261 21.7889 6.49814C21.7889 4.53238 20.2021 2.94556 18.2363 2.94556C16.9929 2.94556 15.9153 3.58502 15.2759 4.54422L14.6838 5.33763L14.0917 4.53238C13.4522 3.58502 12.3746 2.94556 11.1312 2.94556C9.1654 2.94556 7.57858 4.53238 7.57858 6.49814C7.57858 6.91261 7.66147 7.31524 7.79174 7.68234H5.21019C3.89573 7.68234 2.85364 8.73627 2.85364 10.0507L2.8418 23.0769C2.8418 24.3913 3.89573 25.4453 5.21019 25.4453H24.1573C25.4718 25.4453 26.5257 24.3913 26.5257 23.0769V10.0507C26.5257 8.73627 25.4718 7.68234 24.1573 7.68234ZM18.2366 5.31393C18.8879 5.31393 19.4208 5.84682 19.4208 6.49813C19.4208 7.14944 18.8879 7.68232 18.2366 7.68232C17.5853 7.68232 17.0524 7.14944 17.0524 6.49813C17.0524 5.84682 17.5853 5.31393 18.2366 5.31393ZM12.3156 6.49813C12.3156 5.84682 11.7827 5.31393 11.1314 5.31393C10.4801 5.31393 9.9472 5.84682 9.9472 6.49813C9.9472 7.14944 10.4801 7.68232 11.1314 7.68232C11.7827 7.68232 12.3156 7.14944 12.3156 6.49813ZM24.1575 23.0769H5.21042V20.7085H24.1575V23.0769ZM5.21042 17.1558H24.1575V10.0506H18.1418L20.605 13.4019L18.6866 14.7874L14.684 9.34008L10.6814 14.7874L8.763 13.4019L11.2261 10.0506H5.21042V17.1558Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_70_6376)">
      <rect x="0.473633" y="0.577148" width="28.4207" height="28.4207" fill={color} />
    </g>
  </svg>
);

GiftIcon.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default GiftIcon;
