import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { User } from '../../shared/modals/user/user';
import { RootStateGlobal } from '../../store/reducer';
import * as serviceUser from '../../shared/services/serviceUser';
import { actions as actionsGeral } from '../../store/reducers/geral';
import { actions as actionsUser } from '../../store/reducers/user';
import Button from '../../shared/components/buttons/Button';
import Input from '../../shared/components/input/Input';
import { Box } from '../../shared/components/styles/styledBox';
import { SnackbarData } from '../../shared/modals/geral/geral';
import { BodyUpdatePassword } from '../../shared/modals/user/bodies';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { Container, ContainerDone, ImgLogo, Text, TitleDone } from './styles';
import DoneIcon from '../../shared/icons/DoneIcon';
import { useCustomStyle } from '../../shared/functions/hooks';
import { t } from '../../i18n';

const UpdatePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const [bodyUpdatePassword, setBodyUpdatePassword] = useState<BodyUpdatePassword>({
    password: '',
  });
  const [confirmPassword, setConfirmParssword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const setSnackbarData = (x: SnackbarData) => dispatch(actionsGeral.setSnackbarData(x));
  const setUserData = (x: User) => dispatch(actionsUser.setUserData(x));
  const { textColor, primaryColor, backgroundPrimary, backgroundSecondary } = useCustomStyle();

  useEffect(() => {
    if (userData) {
      setUserData({
        ...userData,
        firstAccess: false,
      });
    }
  }, []);

  const handleGoToHome = () => {
    history.push(MenuUrl.home);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setBodyUpdatePassword({
      password: event.target.value,
    });
  };

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCode(false);
    setConfirmParssword(event.target.value);
  };

  const handleSendNewPassword = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await serviceUser.updatePassword(bodyUpdatePassword);
      setPasswordUpdated(true);
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarData({
          message: error.message,
          show: true,
          type: 'error',
        });
      }
    }
    setLoading(false);
  };

  if (passwordUpdated) {
    return (
      <ContainerDone>
        <DoneIcon />
        <TitleDone style={{ margin: '24px auto', ...textColor }}>
          {t('changePasswordSuccess')}
        </TitleDone>
        <Text style={primaryColor}>{t('updatePasswordMessage')}</Text>
        <Button
          style={{ marginTop: 96 }}
          onClick={handleGoToHome}
          type="button"
          customBackground={primaryColor.color}
        >
          {t('login')}
        </Button>
      </ContainerDone>
    );
  }

  return (
    <Container style={backgroundSecondary}>
      <Box>
        <ImgLogo src="/images/updatePassword.png" />
        <form onSubmit={handleSendNewPassword}>
          <Text style={textColor}>Este é o seu 1º acesso, crie uma nova senha:</Text>
          <Input
            placeholder={t('placeholderType')}
            title={t('newPassword')}
            onChange={handleChangePassword}
            value={bodyUpdatePassword.password}
            style={{ marginBottom: 16 }}
            error={errorCode}
            type="password"
            customBackground={backgroundPrimary.backgroundColor}
            customTextColor={textColor.color}
          />
          <Input
            placeholder={t('placeholderType')}
            title={t('confirmPassword')}
            onChange={handleConfirmPassword}
            value={confirmPassword}
            style={{ marginBottom: 16 }}
            error={errorCode}
            type="password"
            customBackground={backgroundPrimary.backgroundColor}
            customTextColor={textColor.color}
          />

          <Button
            loading={loading}
            disabled={
              confirmPassword !== bodyUpdatePassword.password || confirmPassword.length <= 3
            }
            style={{ marginBottom: 16 }}
            customBackground={primaryColor.color}
          >
            {t('confirm').toUpperCase()}
          </Button>
          <Button
            onClick={handleGoToHome}
            typeButton="primary-black"
            type="button"
            customBackground={primaryColor.color}
            style={backgroundPrimary}
          >
            {t('updateLater')}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default UpdatePassword;
