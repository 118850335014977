import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PPlaceIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const PlaceIcon = ({ width, height, color, style }: PPlaceIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="place">
        <path
          id="Vector"
          d="M14.6835 14.6839C13.3809 14.6839 12.3151 13.6181 12.3151 12.3155C12.3151 11.0129 13.3809 9.94709 14.6835 9.94709C15.9861 9.94709 17.0519 11.0129 17.0519 12.3155C17.0519 13.6181 15.9861 14.6839 14.6835 14.6839ZM21.7887 12.5523C21.7887 8.25369 18.6506 5.21031 14.6835 5.21031C10.7165 5.21031 7.57835 8.25369 7.57835 12.5523C7.57835 15.3233 9.88753 18.9943 14.6835 23.3759C19.4795 18.9943 21.7887 15.3233 21.7887 12.5523ZM14.6835 2.84192C19.6571 2.84192 24.1571 6.65503 24.1571 12.5523C24.1571 16.4839 20.9953 21.1377 14.6835 26.5258C8.37176 21.1377 5.20996 16.4839 5.20996 12.5523C5.20996 6.65503 9.70991 2.84192 14.6835 2.84192Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PlaceIcon.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default PlaceIcon;
