import { RankData } from '../../../shared/modals/socket/Socket';
import { Result, Tournament } from '../../../shared/modals/tournament/Tournament';

export enum RankingTypes {
  SET_RANKING = '@ranking/SET_RANKING',
  SET_TIME = '@ranking/SET_TIME',
  SET_CURRENT_TOURNEY = '@ranking/SET_CURRENT_TOURNEY',
  SET_RESULT = '@ranking/SET_RESULT',
}

export interface RankingState {
  readonly ranking: RankData[];
  readonly time: number;
  readonly currentTourney?: Tournament;
  readonly result?: Result;
}
