import { Reducer } from 'redux';

import { RankingState, RankingTypes } from './rankingTypes';

const INITIAL_STATE: RankingState = {
  ranking: [],
  time: 0,
  currentTourney: undefined,
};

const reducer: Reducer<RankingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RankingTypes.SET_RANKING:
      return {
        ...state,
        ranking: action.payload,
      };
    case RankingTypes.SET_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case RankingTypes.SET_CURRENT_TOURNEY:
      return {
        ...state,
        currentTourney: action.payload,
      };
    case RankingTypes.SET_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
