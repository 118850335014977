/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { rgba } from 'polished';
import { useDispatch } from 'react-redux';
import { MdMenuBook } from 'react-icons/md';
import { actions as geralActions } from '../../../store/reducers/geral';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';
import Button from '../buttons/Button';
import { useCustomStyle } from '../../functions/hooks';
import { StepLeftInterface, User } from '../../modals/user/user';
import { t } from '../../../i18n';
import GiftIcon from '../../icons/GiftIcon';
import AwardIcon from '../../icons/AwardIcon';
import PlaceIcon from '../../icons/Place';

interface ModalTuTorialInterface {
  step: StepLeftInterface;
  userData: User;
  markTutorialStepConcluded: (stepConcluded: StepLeftInterface) => void;
}

const ModalTuTorial = ({ step, userData, markTutorialStepConcluded }: ModalTuTorialInterface) => {
  const { primaryColor } = useCustomStyle();
  const dispatch = useDispatch();
  const isDesktop = window.innerWidth > 720;

  if (step === 'homeCoins') {
    dispatch(geralActions.setHighlightElement('coins'));
  } else if (step === 'homeStore') {
    dispatch(geralActions.setHighlightElement('menuEntries'));
  } else if (step === 'library') {
    dispatch(geralActions.setHighlightElement('menuEntryLibrary'));
  } else {
    dispatch(geralActions.setHighlightElement());
  }

  const renderTitle = () => {
    switch (step) {
      case 'homeIntro':
        return t('hello', { userName: userData.name.split(' ')[0] });
      case 'homeCoins':
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {t('yourCoins')}
            <img src="/images/magicashCash.png" alt="MS" />
          </span>
        );
      case 'homeStore':
        return t('menu');
      case 'store':
        return t('tWelcomeRewards');
      case 'journey':
        return t('tGameStart');
      case 'presentations':
        return `${t('tTimeToLearn')} 🤓`;
      case 'leagues':
        return t('tLeagues');
      case 'library':
        return t('tLibrary');
      default:
        return '';
    }
  };

  const renderBody = () => {
    switch (step) {
      case 'homeIntro':
        return (
          <>
            <div style={{ marginBottom: 24 }}>
              <p style={{ marginBottom: 8 }}>{t('tFirstJourney')}</p>
              <p>{t('tutorialText')}</p>
            </div>
            <Button
              onClick={() => markTutorialStepConcluded('homeIntro')}
              customBackground={primaryColor.color}
            >
              {t('okShowMe')}
            </Button>
          </>
        );
      case 'homeCoins':
        return (
          <div>
            <div style={{ marginBottom: 24 }}>
              <p style={{ marginBottom: 8 }}>{t('tCoins')}</p>
              <p
                style={{ marginBottom: 8 }}
                dangerouslySetInnerHTML={{ __html: t('tutorialSplitText') }}
              />
            </div>
            <Button
              onClick={() => markTutorialStepConcluded('homeCoins')}
              customBackground={primaryColor.color}
            >
              {t('understood')}
            </Button>
          </div>
        );
      case 'homeStore':
        return (
          <div>
            <div style={{ marginBottom: 24 }}>
              <p style={{ marginBottom: 8 }}>
                {isDesktop ? (
                  <>
                    {t('tAccess1Desktop')}
                    <GiftIcon color="black" style={{ marginBottom: -3 }} />
                  </>
                ) : (
                  <>
                    {t('tAccess1Mobile')}
                    <AwardIcon color="black" style={{ marginBottom: -3 }} />
                  </>
                )}
              </p>
              <p>
                {t('tAccess2')}
                <PlaceIcon color="black" style={{ marginBottom: -3 }} />
              </p>
            </div>
            <Button
              onClick={() => markTutorialStepConcluded('homeStore')}
              customBackground={primaryColor.color}
            >
              {t('understood')}
            </Button>
          </div>
        );
      case 'store':
        return (
          <>
            <div style={{ marginBottom: 24 }}>
              <p style={{ marginBottom: 8 }}>{t('tEnoughCash')}</p>
              <p>{t('tShowValue')}</p>
            </div>
            <Button
              onClick={() => markTutorialStepConcluded('store')}
              customBackground={primaryColor.color}
            >
              {t('understood')}
            </Button>
          </>
        );
      case 'journey':
        return (
          <>
            <div style={{ marginBottom: 24 }}>{t('tFirstJourney')}</div>
            <Button
              onClick={() => markTutorialStepConcluded('journey')}
              customBackground={primaryColor.color}
            >
              {t('okLetsGo')}
            </Button>
          </>
        );
      case 'presentations':
        return (
          <>
            <div style={{ marginBottom: 24 }}>{t('tPresentation')}</div>
            <Button
              onClick={() => markTutorialStepConcluded('presentations')}
              customBackground={primaryColor.color}
            >
              {t('okLetsGo')}
            </Button>
          </>
        );
      case 'leagues':
        return (
          <>
            <div style={{ marginBottom: 24 }}>{t('textLeagues')}</div>
            <Button
              onClick={() => markTutorialStepConcluded('leagues')}
              customBackground={primaryColor.color}
            >
              {t('understood')}
            </Button>
          </>
        );
      case 'library':
        return (
          <>
            <div style={{ marginBottom: 24 }}>{t('textLibrary')}</div>
            <div style={{ marginBottom: 24 }}>
              {t('textLibrarySplit').split('//')[0]}
              <MdMenuBook color="black" style={{ marginBottom: -3 }} />
              {t('textLibrarySplit').split('//')[1]}
            </div>
            <Button
              onClick={() => markTutorialStepConcluded('library')}
              customBackground={primaryColor.color}
            >
              {t('understood')}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const isOpen = (): boolean =>
    [
      'homeIntro',
      'homeCoins',
      'homeStore',
      'store',
      'journey',
      'presentations',
      'leagues',
      'library',
    ].includes(step);

  // Small hack to make animations to work.
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isOpen() && !show) setTimeout(() => setShow(true));
    else setShow(false);
  }, [isOpen()]);

  return (
    <Modal
      open={isOpen()}
      style={{
        transition: 'opacity .5s ease-out',
        opacity: show ? 1 : 0,
      }}
    >
      <div
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'relative',
          maxWidth: 720,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            margin: 16,
            borderRadius: 10,
            backgroundColor: colors.white,
          }}
        >
          <div
            style={{
              backgroundColor: rgba(primaryColor.color || colors.purpleRegular, 0.7),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 16,
              color: colors.white,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              minHeight: 80,
              fontFamily: fonts.bold,
              fontSize: 20,
            }}
          >
            {renderTitle()}
          </div>
          <div
            style={{
              backgroundColor: colors.white,
              color: colors.black,
              borderEndStartRadius: 10,
              borderEndEndRadius: 10,
              padding: 24,
            }}
          >
            {renderBody()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTuTorial;
