import React, { CSSProperties } from 'react';
import HeartIcon from '../../../icons/HeartIcon';
import { Container, Text } from './style';
import { t } from '../../../../i18n';

interface PTutoText {
  style?: CSSProperties;
}

const TutoText = ({ style }: PTutoText) => {
  return (
    <Container style={style}>
      <Text>
        {t('tutoText').split('//')[0]}
        <HeartIcon style={{ margin: '4px' }} />
        {t('tutoText').split('//')[1]}
        <a
          className="tutoTextClass"
          style={{ marginLeft: '4px' }}
          target="_blank"
          rel="noreferrer"
          href="https://bit.ly/criado-pela-tuto"
        >
          TuTo
        </a>
      </Text>
    </Container>
  );
};

export default TutoText;

TutoText.defaultProps = {
  style: {},
};
