import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';
import ButtonIconDefault from '../../buttons/buttonIcon/ButtonIcon';

interface PDivider {
  primaryColor: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 220px;
  top: 0;
  bottom: 0;
  background-color: ${colors.background};
  z-index: 20;

  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  position: fixed;

  @media (min-width: 900px) {
    display: flex;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  overflow-y: scroll;

  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Divider = styled.div<PDivider>`
  width: 2px;
  height: 94%;
  background-color: ${props => (props.primaryColor ? props.primaryColor : colors.purpleRegular)};
  align-self: center;
`;

export const ButtonIcon = styled(ButtonIconDefault)`
  position: absolute;
  right: 24px;
  top: 16px;
`;

export const NameBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

export const NameUser = styled.h1`
  color: ${colors.white};

  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  margin-right: 5px;
`;

export const FunctionUser = styled.h2`
  color: ${colors.white};
  margin-bottom: 32px;

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
`;

export const ButtonList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;
