import Axios, { CancelToken } from 'axios';
import { ConnectApiPost, urls } from '../functions/connection';

export const requestPresignedS3Url = async (file: File) => {
  const respostaServico = await ConnectApiPost(urls.URL_REQUEST_PRESIGNED_S3_URL, {
    filename: file.name,
  });
  return respostaServico.data;
};

export const uploadImage = async (
  file: File,
  config?: {
    onUploadProgress: (x: { loaded: number; total: number }) => void;
    cancelToken: CancelToken;
  },
) => {
  // Requests a URL to upload file to S3
  const { url: preSignedURL } = await requestPresignedS3Url(file);

  // Uploads file to S3
  await Axios.put(preSignedURL, file, config);

  // Returns the S3 file's URL
  const locationFile = preSignedURL.split('?')[0];
  return { locationFile };
};
