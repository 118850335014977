import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
`;
