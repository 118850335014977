import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { operations as operationsUser } from '../../store/reducers/user';
import { actions as geralAction } from '../../store/reducers/geral';
import { BodyUserLoginMFA } from '../../shared/modals/user/bodies';
import { SnackbarData } from '../../shared/modals/geral/geral';
import { t } from '../../i18n';

type ParamTypes = {
  token?: string;
  email?: string;
};

const LoginWithMfa = () => {
  const { token } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch = useDispatch();
  const reqLogin = async (x: BodyUserLoginMFA) => dispatch(operationsUser.reqLogin(x));
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        await reqLogin({ token });
        history.push('/home');
      } catch (error) {
        if (error instanceof Error) {
          const { message } = error;

          if (message.startsWith('Para concluir o login')) {
            dispatch(setSnackBar({ message, show: true, type: 'success' }));
          } else if (message.startsWith('O link de acesso expirou')) {
            dispatch(setSnackBar({ message, show: true, type: 'error' }));
          } else {
            dispatch(setSnackBar({ message, show: true, type: 'error' }));
          }

          history.push('/');
        }
      }
    })();
  }, [token]);

  return <div>{t('entering')}</div>;
};

export default LoginWithMfa;
