import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import ButtonBack from '../../../shared/components/buttons/buttonBack/ButtonBack';
import { colors } from '../../../shared/functions/colors';
import { useCustomStyle } from '../../../shared/functions/hooks';
import { SnackbarData } from '../../../shared/modals/geral/geral';
import { RootStateGlobal } from '../../../store/reducer';
import { awardOperations } from '../../../store/reducers/award';
import { actions as geralActions } from '../../../store/reducers/geral';
import BoxAwardAvailable from '../boxAwardAvailable/BoxAwardAvailable';
import ConclusionBuyAward from './conclusionBuyAward/ConclusionBuyAward';
import { Container, Title, Text, ContainerFlex, Line, TextSmall, ContainerInfo } from './styles';
import { t } from '../../../i18n';

interface PBuyAward {
  idAward: string;
}

const BuyAward = ({ idAward }: PBuyAward) => {
  const [showConclusion, setShowConclusion] = useState(false);
  const { textColor, primaryColor } = useCustomStyle();

  const dispatch = useDispatch();
  const { award } = useSelector((state: RootStateGlobal) => state.awardReducer);
  const { wallet } = useSelector((state: RootStateGlobal) => state.userReducer);
  const setSnackBar = (x: SnackbarData) => dispatch(geralActions.setSnackbarData(x));
  const [loading, setLoading] = useState(false);
  const valueText = { color: colors.orange };

  useEffect(() => {
    const getAwards = async () => {
      dispatch(geralActions.setBlockScreen(true));
      await awardOperations.getAward(idAward)(dispatch);
      dispatch(geralActions.setBlockScreen(false));
    };
    getAwards();
  }, []);

  const handleInsertAwardInRequered = async () => {
    setLoading(true);
    try {
      await awardOperations.insertAwardInRequered(idAward)(dispatch);
      setLoading(false);
      setShowConclusion(true);
    } catch (error) {
      if (error instanceof Error) {
        setLoading(false);
        setSnackBar({
          message: error.message,
          show: true,
          type: 'error',
        });
      }
    }
  };

  if (showConclusion) {
    return <ConclusionBuyAward />;
  }

  if (!award || !wallet) {
    return null;
  }

  return (
    <Container>
      <ContainerInfo>
        <ButtonBack />
        <Title
          style={{
            marginTop: 16,
            color: textColor.color || colors.white,
          }}
        >
          {`${t('buyReward')}`}
        </Title>

        <Text
          style={{
            marginTop: 16,
            color: textColor.color || colors.white,
            fontWeight: '400',
          }}
        >
          {t('infoReward')}
        </Text>
        <ContainerFlex style={{ marginTop: 16 }}>
          <Text style={textColor}>
            <strong>{t('infoCoins')}</strong>
          </Text>
          <Text>
            <strong style={{ color: colors.orange }}>{`$ ${wallet.magicashAmount}`}</strong>
          </Text>
        </ContainerFlex>
        <Line
          style={{
            margin: '16px 0px',
            borderBottom: `1px solid ${textColor.color || colors.white}`,
          }}
        />
        <Text
          style={{
            marginBottom: 16,
            color: textColor.color || colors.white,
          }}
        >
          <strong>{t('infoPurchase')}</strong>
        </Text>
        <BoxAwardAvailable award={award} hideButtonBuy />
        <Line
          style={{
            margin: '16px 0px',
            borderBottom: `1px solid ${textColor.color || colors.white}`,
          }}
        />
        <Text style={{ marginBottom: 16 }}>
          <strong style={textColor}>{t('resumePurchase')}</strong>
        </Text>
        <ContainerFlex>
          <Text style={textColor}>{t('balance')}</Text>
          <Text style={valueText}>{`$ ${wallet.magicashAmount}`}</Text>
        </ContainerFlex>
        <ContainerFlex>
          <Text style={textColor}>{t('selectedReward')}</Text>
          <Text style={valueText}>{`- $ ${award.value}`}</Text>
        </ContainerFlex>
        <Line
          style={{
            borderBottom: `1px solid ${textColor.color || colors.white}`,
          }}
        />
        <Text
          style={{
            margin: '8px 0px',
            color: textColor.color || colors.white,
            fontWeight: '400',
          }}
        >
          {t('confirmPurchase')}
        </Text>
        <TextSmall
          style={{
            marginBottom: 16,
            color: textColor.color || colors.white,
            fontWeight: '600',
          }}
        >
          {t('confirmInfo')}
        </TextSmall>
        <Button
          disabled={!wallet || wallet.magicashAmount < award.value}
          loading={loading}
          onClick={handleInsertAwardInRequered}
          customBackground={primaryColor.color}
        >
          {t('conclude')}
        </Button>
      </ContainerInfo>
    </Container>
  );
};

export default BuyAward;
