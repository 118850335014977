import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  Container,
  Header,
  Title,
  BoxWinners,
  BoxButton,
  Subtitle,
  ScoreText,
  StatsText,
} from './conclusionPage.styles';
import { handleJourneyStartContest } from '../../shared/functions/gameStarters/contests';
import { useCustomStyle } from '../../shared/functions/hooks';
import { actions as geralAction } from '../../store/reducers/geral';
import { ModalData } from '../../shared/modals/geral/geral';
import Button from '../../shared/components/buttons/Button';
import { RootStateGlobal } from '../../store/reducer';
import { Tournament } from '../../shared/modals/tournament/Tournament';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { t } from '../../i18n';
import { rankingOperations } from '../../store/reducers/ranking';
import { setBlockScreen } from '../../store/reducers/geral/actions';

interface RankingLocationState {
  idTourney: string;
  idMatch: string;
  tournament: Tournament;
  exitToPath: string;
}

const ConclusionPage = () => {
  const dispatch = useDispatch();
  const location = useLocation<never>();
  const history = useHistory();
  const { textColor, primaryColor, secondaryColor } = useCustomStyle();
  const closeModal = () => dispatch(geralAction.closeModal());
  const setModalData = (x: ModalData) => dispatch(geralAction.setModal(x));
  const leaguesSeason = useSelector((state: RootStateGlobal) => state.leagueReducer);
  const { result } = useSelector((state: RootStateGlobal) => state.rankingReducer);
  const { idTourney, idMatch, tournament, exitToPath } = location.state as RankingLocationState;
  const getRankingAfterMatch = async () =>
    dispatch(rankingOperations.getRankingAfterMatch(idMatch, idTourney));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  useEffect(() => {
    (async () => {
      setBlockScreen(true);
      await getRankingAfterMatch();
      setBlockScreen(false);
    })();
  }, []);

  useEffect(() => {
    history.push(`${MenuUrl.result}/`, {
      idTourney,
      idMatch,
      tournament,
      exitToPath,
    });

    return history.listen(() => {
      if (history.action === 'POP') {
        history.push(exitToPath);
      }
    });
  }, []);

  if (!(tournament && result)) return null;

  const handleOpenPhase = () => {
    handleJourneyStartContest(tournament, exitToPath, history, closeModal, setModalData);
  };

  const handleGoToPhases = () => {
    if (leaguesSeason.roseUp && userData?.experiments?.leagues) {
      history.push(MenuUrl.league, { exitToPath, leanLayout: true });
    } else {
      history.push(exitToPath);
    }
  };

  const totalQuestions = result.questionAmount;
  const rightAnswers = result.score;
  const wrongAnswers = totalQuestions - rightAnswers - result.unansweredQuestions;
  const isStepConcluded = result.concluded;

  return (
    <Container>
      <Header>
        <Title style={textColor}>{tournament.name}</Title>
      </Header>
      <BoxWinners>
        <img
          src={isStepConcluded ? '/images/phaseSuccess.png' : '/images/phaseFailure.png'}
          alt=""
        />
      </BoxWinners>
      {isStepConcluded ? (
        <>
          <Title style={textColor}>{t('congrats').toUpperCase()}</Title>
          <Subtitle style={textColor}>{t('phaseSuccess', { phaseName: tournament.name })}</Subtitle>
        </>
      ) : (
        <>
          <Title style={textColor}>{t('almostThere')}</Title>
          <Subtitle style={textColor}>
            {t('phaseFailureMessage1')}
            <br />
            {t('phaseFailureMessage2')}
          </Subtitle>
        </>
      )}
      <ScoreText style={secondaryColor}>
        {`${result.scorePercentage}% ${t('percentageCorrect')}`}
      </ScoreText>
      <StatsText style={textColor}>
        {`${rightAnswers}/${totalQuestions} ${t('correctAnswers')}`}
      </StatsText>
      <StatsText style={textColor}>
        {`${rightAnswers + wrongAnswers}/${totalQuestions} ${t('answeredQuestions')}`}
      </StatsText>
      <StatsText style={textColor}>{`${wrongAnswers} ${t('wrongAnswers')}`}</StatsText>
      <StatsText style={textColor}>
        {`${result.unansweredQuestions} ${t('unansweredQuestions')}`}
      </StatsText>

      {!isStepConcluded && (
        <BoxButton style={{ marginTop: 20 }}>
          <Button customBackground={primaryColor.color} onClick={handleOpenPhase}>
            {t('playAgain')}
          </Button>
        </BoxButton>
      )}

      <BoxButton>
        <Button
          typeButton={isStepConcluded ? 'primary' : 'primary-black'}
          customBackground={primaryColor.color}
          onClick={handleGoToPhases}
        >
          {t('continue').toUpperCase()}
        </Button>
      </BoxButton>
    </Container>
  );
};

export default ConclusionPage;
