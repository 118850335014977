import styled from 'styled-components';
import { fonts } from '../../shared/functions/fonts';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  padding: 24px;
  background-color: ${colors.background};
  height: 100%;
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-family: ${fonts.semiBold};
  color: ${colors.white};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  text-align: left;
  margin-bottom: 24px;
`;
