import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  margin-top: 20px;
`;

export const Text = styled.p`
  margin: 24px;
  color: ${colors.white};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.17px;
`;

export const ContainerList = styled.div`
  padding: 0px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
