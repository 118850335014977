import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.background};
  display: flex;
  justify-content: center;
  padding: 32px;
  overflow: auto;
`;

export const BoxForgetPassword = styled.div`
  text-align: right;
  color: ${colors.white};
  margin: 16px 0px 32px 0px;
`;

export const ImgLogo = styled.img`
  margin: 40px auto 40px;
  max-height: 104px;
  max-width: 100%;
`;

export const ImageFlagContainer = styled.div`
  top: 30px;
  right: 30px;

  @media (max-width: 600px) {
    top: 10px;
    right: 10px;
  }
`;
