import React from 'react';
import styled from 'styled-components';
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { PaymentType } from '../../shared/enums/paymentTypes';
import { colors } from '../../shared/functions/colors';
import { t } from '../../i18n';

interface CardContainerProps {
  selected: boolean;
}

const CardContainer = styled.button<CardContainerProps>`
  background-color: ${props => (props.selected ? colors.purpleRegular : 'transparent')};
  border: ${props => (props.selected ? 'none' : `1px solid ${colors.greyf0}`)};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

CardContainer.defaultProps = {
  type: 'button',
};

const paymentTexts: Record<PaymentType, string> = {
  pix: 'Pix',
  credit_card: t('paymentTypeCreditCard'),
};

const paymentIcons: Record<PaymentType, React.ElementType> = {
  pix: PixIcon,
  credit_card: CreditCardIcon,
};

interface PaymentCardInterface {
  type: PaymentType;
  selected: boolean;
  onClick: () => void;
}

interface PaymentIconProps {
  type: PaymentType;
}

const PaymentIcon = ({
  type,
  ...rest
}: PaymentIconProps & React.ComponentProps<typeof PixIcon>) => {
  const IconComponent = paymentIcons[type];

  return <IconComponent {...rest} />;
};

export const PaymentCard = ({ type, selected, onClick }: PaymentCardInterface) => {
  return (
    <CardContainer selected={selected} onClick={onClick}>
      <PaymentIcon
        type={type}
        sx={{
          color: selected ? colors.white : colors.grey100,
          fontSize: '38px',
          marginBottom: '10px',
        }}
      />
      <p style={{ fontSize: '16px', color: selected ? colors.white : colors.grey100 }}>
        {paymentTexts[type]}
      </p>
    </CardContainer>
  );
};
