import { t } from '../../i18n';

export const ERROR_AUTHORIZATION_VALUE_IS_INVALID = t('errorAuth');
export const ERROR_CONNECTION = t('errorConnect');
export const ERROR_GENERIC = t('errorGeneric');
export const ERROR_INTERNAL = t('errorInternal');
export const ERROR_MAINTENANCE = t('errorMaintenance');
export const ERROR_ACCESS_DENIED = t('errorAccessDenied');
export const ERROR_NOT_FOUND = t('errorNotFound');
export const ERROR_INVALID_PASSWORD = t('errorInvalid');
export const ERROR_USER_BLOCKED = t('errorUserBlocked');
export const ERROR_LIMIT_EXCEEDED = t('errorLimit');
