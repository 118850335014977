import { LeagueTypes, LeagueConfig } from './types';

export const setSeason = (season: LeagueConfig) => ({
  type: LeagueTypes.SET_SEASON,
  payload: season,
});

export const setRanking = (season: LeagueConfig) => ({
  type: LeagueTypes.SET_UPDATED_RANKING,
  payload: season,
});

export const disableLeague = () => ({
  type: LeagueTypes.DISABLE_LEAGUE,
});
