import { MatchData, QuestionData } from '../../../shared/modals/socket/Socket';
import { Tournament } from '../../../shared/modals/tournament/Tournament';

import { TournamentTypes } from './types';

export const setTournament = (tournament?: Tournament) => ({
  type: TournamentTypes.SET_TOURNAMENT,
  payload: tournament,
});

export const clearTournament = () => ({
  type: TournamentTypes.SET_TOURNAMENT,
  payload: undefined,
});

export const setListTournament = (listTournament: Tournament[]) => ({
  type: TournamentTypes.SET_LIST_TOURNAMENT,
  payload: listTournament,
});

export const setQuestion = (question?: QuestionData) => ({
  type: TournamentTypes.SET_QUESTION,
  payload: question,
});

export const clearQuestion = () => ({
  type: TournamentTypes.SET_QUESTION,
  payload: undefined,
});

export const setMatch = (match: MatchData) => ({
  type: TournamentTypes.SET_MATCH,
  payload: match,
});

export const clearMatch = () => ({
  type: TournamentTypes.SET_MATCH,
  payload: undefined,
});
