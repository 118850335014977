import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

import { useTypeContainer } from './hooks';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  ${useTypeContainer}
`;

export const BoxIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxIconLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const TextIcon = styled.p`
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;
