import { ConnectApiGet, urls } from '../functions/connection';
import { CheckoutProduct } from '../modals/checkoutProduct/checkoutProduct';
import { ERROR_NOT_FOUND } from '../constants/messages';

export const getCheckoutProductById = async (id: string) => {
  const url = urls.CHECKOUT_PRODUCT_ID_URL.replace(':id', id);
  try {
    const response = await ConnectApiGet(url);
    return response.data as CheckoutProduct;
  } catch (e) {
    if (e instanceof Error && e.message === ERROR_NOT_FOUND) {
      return null;
    }
  }

  return null;
};
