import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
import ButtonText from '../buttonText/ButtonText';

export const Container = styled(ButtonText)`
  align-items: center;
  display: flex;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;
