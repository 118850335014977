import React from 'react';
import { MdNotifications } from 'react-icons/md';
import { Badge } from '@material-ui/core';
import { IconContext } from 'react-icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../store/reducer';
import { MenuUrl } from '../../enums/menuUrl';

const Alerts = () => {
  const history = useHistory();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  if (!userData || !userData.experiments.postsAndFeed) return null;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{ marginRight: 16, cursor: 'pointer' }}
      onClick={() => history.push(MenuUrl.feed)}
      onKeyDown={() => history.push(MenuUrl.feed)}
    >
      <IconContext.Provider value={{ color: 'white', size: '1.7em' }}>
        <Badge badgeContent={userData.alertsCount} max={9} overlap="circular" color="primary">
          <MdNotifications />
        </Badge>
      </IconContext.Provider>
    </div>
  );
};

export default Alerts;
