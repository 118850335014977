import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectProps,
  FormHelperText,
} from '@mui/material';
import { FieldHookConfig, useField } from 'formik';

import { colors } from '../../shared/functions/colors';

interface InputOption {
  label: string;
  value: string;
}

interface SelectInputProps {
  id: string;
  label: string;
  options: InputOption[];
}

export const SelectInput = ({ id, label, options, ...props }: SelectInputProps & SelectProps) => {
  const [field, meta] = useField(props as FieldHookConfig<string>);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <FormControl style={{ width: '100%', marginBottom: '20px' }}>
      <InputLabel style={{ color: colors.black1f }} id={id}>
        {label}
      </InputLabel>
      <Select
        style={{ backgroundColor: colors.greyf0, borderRadius: '4px 4px 0 0' }}
        labelId={id}
        label={label}
        {...props}
        {...field}
        error={!!errorText}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {!props.disabled && <FormHelperText style={{ color: '#f44336' }}>{errorText}</FormHelperText>}
    </FormControl>
  );
};
