import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PChevronLeftIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const ChevronLeftIcon = ({ width, height, color, style }: PChevronLeftIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <mask id="prefix_ChevronLeftIcon" maskUnits="userSpaceOnUse" x={8} y={6} width={8} height={12}>
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" fill={colors.white} />
    </mask>
    <g mask="url(#prefix_ChevronLeftIcon)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

ChevronLeftIcon.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default ChevronLeftIcon;
