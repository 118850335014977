import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
import { colors } from '../../../functions/colors';

interface PSelected {
  isSelected?: boolean;
  textColor?: string;
  customBackground?: string;
  primaryColor?: string;
}

export const Container = styled.button<PSelected>`
  cursor: pointer;

  display: flex;
  padding: 16px 8px;
  align-items: center;
  align-self: stretch;
  border: none;

  height: 60px;

  border-radius: 16px;
  background: ${props =>
    props.isSelected
      ? props.primaryColor || colors.purpleRegular
      : props.customBackground || '#263238'};

  margin: 0 9px 28px 9px;
`;

export const Text = styled.p<PSelected>`
  font-family: ${fonts.regular};
  color: var(--primary-contrast, #fff);
  font-size: 14.21px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.589px;
  letter-spacing: 0.474px;
  padding: 8px;
`;
