import React from 'react';
import PrizeCard from '../../../shared/components/card/prizeCard/PrizeCard';
import { colors } from '../../../shared/functions/colors';
import { useCustomStyle } from '../../../shared/functions/hooks';
import ClockIcon from '../../../shared/icons/ClockIcon';
import HitsIcon from '../../../shared/icons/HitsIcon';
import { Tournament } from '../../../shared/modals/tournament/Tournament';

import {
  Container,
  BoxPosition,
  PlayerName,
  BoxHitsTime,
  BoxHits,
  BoxTime,
  ImagePosition,
  ImageUser,
  ImagePositionTop,
} from './styles';

interface PWinners {
  playerName: string;
  playerPosition: number;
  hits: number;
  time: string;
  prize: number;
  tournament: Tournament;
  imageUser?: string;
}

const Winners = ({
  playerName,
  playerPosition,
  hits,
  time,
  prize,
  tournament,
  imageUser,
}: PWinners) => {
  const { textColor } = useCustomStyle();

  const renderImagePosition = () => {
    switch (playerPosition) {
      case 1:
        return (
          <>
            <ImagePositionTop src="/images/position1.png" alt="1º" />
            <ImageUser isFirst src={imageUser || '/images/userNotImage.png'} alt="imageUser" />
            <ImagePosition src="/images/ranking_1.png" alt="1º" />
          </>
        );
      case 2:
        return (
          <>
            <ImagePositionTop src="/images/position2.png" alt="2º" />
            <ImageUser src={imageUser || '/images/userNotImage.png'} alt="imageUser" />
            <ImagePosition src="/images/ranking_2.png" alt="2º" />
          </>
        );
      case 3:
      default:
        return (
          <>
            <ImagePositionTop src="/images/position3.png" alt="3º" />
            <ImageUser src={imageUser || '/images/userNotImage.png'} alt="imageUser" />
            <ImagePosition src="/images/ranking_3.png" alt="3º" />
          </>
        );
    }
  };

  return (
    <Container isFirst={playerPosition === 1}>
      <BoxPosition isFirst={playerPosition === 1}>{renderImagePosition()}</BoxPosition>
      <PlayerName isFirst={playerPosition === 1} style={textColor}>
        {playerName}
      </PlayerName>
      <BoxHitsTime
        isFirst={playerPosition === 1}
        style={{
          color: playerPosition === 1 ? colors.orange : textColor.color || colors.white,
        }}
      >
        <BoxTime>
          <ClockIcon
            width={16}
            height={16}
            style={{ marginRight: 4 }}
            color={playerPosition === 1 ? colors.orange : textColor.color || colors.white}
          />
          {time}
        </BoxTime>
        <BoxHits>
          <HitsIcon
            width={16}
            height={16}
            style={{ marginRight: 4, marginLeft: 4 }}
            color={playerPosition === 1 ? colors.orange : textColor.color || colors.white}
          />
          {`${hits}/${tournament.questionAmount}`}
        </BoxHits>
      </BoxHitsTime>
      <PrizeCard value={prize} />
    </Container>
  );
};

Winners.defaultProps = {
  imageUser: undefined,
};

export default Winners;
