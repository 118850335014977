import { Reducer } from 'redux';

import { CompanyPreferenceTypes, CompanyState } from './types';

const INITIAL_STATE: CompanyState = {
  companyStyles: { companySupportWhatsapp: '', showWhatsapp: false },
};

const reducer: Reducer<CompanyState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompanyPreferenceTypes.SET_COMPANY_PREFERENCE_STYLES:
      return {
        ...state,
        companyStyles: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
