import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../buttons/Button';
import { actions } from '../../../../../store/reducers/geral';

import { Container, TextBold } from './styles';
import { useCustomStyle } from '../../../../functions/hooks';
import { t } from '../../../../../i18n';

const ModalRules = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());
  const { primaryColor } = useCustomStyle();

  return (
    <Container>
      <TextBold>{t('contentNotEnabled')}</TextBold>
      <Button style={{ marginTop: 16 }} onClick={closeModal} customBackground={primaryColor.color}>
        {t('okUnderstand')}
      </Button>
    </Container>
  );
};

export default ModalRules;
