import { SelfSignUpCampaignInterface } from '../../features/self_sign_up/UserCampaignForm';

export const isProvidedDataEnough = (campaign?: SelfSignUpCampaignInterface): boolean => {
  if (!campaign) return false;

  const nonCpfAndName = !campaign.fields.some(({ fieldType }) =>
    ['cpf', 'phone'].includes(fieldType),
  );
  const onlyOneArea = campaign.areas.length === 1;

  return nonCpfAndName && onlyOneArea;
};
