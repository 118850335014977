import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

export const getAll = async () => {
  try {
    const response = await ConnectApiGet(urls.FEED_URL);
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
  }

  return [];
};
