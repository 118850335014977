import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { MenuUrl } from '../../shared/enums/menuUrl';
import { actions as geralAction } from '../../store/reducers/geral';
import Winners from './winners/Winners';
import {
  Container,
  Header,
  Title,
  BoxWinners,
  BoxRanking,
  BoxButton,
  BoxButtonBackgroundSolid,
  NameTourney,
  SubTitle,
  TextJustification,
} from './styles';
import TableRanking from '../../shared/components/table/tableRanking/TableRanking';
import { RootStateGlobal } from '../../store/reducer';
import { rankingOperations } from '../../store/reducers/ranking';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import CloseIcon from '../../shared/icons/CloseIcon';
import { Tournament } from '../../shared/modals/tournament/Tournament';
import { verifyDateStringBeforeNow } from '../../shared/functions/date';
import { useCustomStyle } from '../../shared/functions/hooks';
import { t } from '../../i18n';

interface RankingLocationState {
  idTourney: string;
  idMatch: string;
  tournament: Tournament;
  exitToPath: string;
}

const Ranking = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<never>();
  const { idTourney, idMatch, tournament, exitToPath } = location.state as RankingLocationState;
  const getRankingAfterMatch = async () =>
    dispatch(rankingOperations.getRankingAfterMatch(idMatch, idTourney));
  const getRanking = async () => dispatch(rankingOperations.getRanking(idTourney));
  const { ranking, currentTourney } = useSelector((state: RootStateGlobal) => state.rankingReducer);
  const setBlockScreen = (x: boolean) => dispatch(geralAction.setBlockScreen(x));
  const { textColor, primaryColor, backgroundPrimary } = useCustomStyle();
  const leaguesSeason = useSelector((state: RootStateGlobal) => state.leagueReducer);
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  useEffect(() => {
    const handleGetRanking = async () => {
      setBlockScreen(true);
      if (idMatch) {
        await getRankingAfterMatch();
      } else {
        await getRanking();
      }
      setBlockScreen(false);
    };
    handleGetRanking();
  }, []);

  useEffect(() => {
    history.push(history.location.pathname, { idTourney, idMatch, tournament, exitToPath });

    return history.listen(() => {
      if (history.action === 'POP') {
        history.push(exitToPath);
      }
    });
  }, []);

  if (!(ranking && currentTourney && userData)) {
    return null;
  }

  const handleGoToTournaments = () => {
    if (userData.company.migrated) {
      history.push(`${MenuUrl.result}/`, {
        idTourney,
        idMatch,
        tournament,
        exitToPath,
      });
    } else if (leaguesSeason.roseUp && userData?.experiments?.leagues) {
      history.push(MenuUrl.league, { exitToPath, leanLayout: true });
    } else {
      history.push(exitToPath);
    }
  };

  const winners = (index: number) => {
    return (
      <Winners
        key="winners_0"
        hits={ranking[index].score}
        playerName={ranking[index].name}
        playerPosition={ranking[index].position}
        prize={ranking[index].prize}
        time={ranking[index].time.toString()}
        tournament={tournament}
        imageUser={ranking[index].imageUser}
      />
    );
  };

  // When ranking is disabled, the `ranking` object holds only the current user result
  // When ranking is enabled, the `tableRanking` should show only users from 4th place onward
  //   because 1st, 2nd and 3rd places are highlighted above the table.
  const tableRanking = currentTourney.rankingEnabled ? ranking.slice(3) : ranking;
  const title =
    idMatch || !currentTourney.rankingEnabled
      ? t('congrats').toUpperCase()
      : t('ranking').toUpperCase();
  const subtitle = currentTourney.rankingEnabled ? t('checkPosition') : t('checkScore');

  return (
    <Container style={backgroundPrimary}>
      <Header>
        <NameTourney style={textColor}>{tournament.name}</NameTourney>
        <ButtonIcon onClick={handleGoToTournaments}>
          <CloseIcon />
        </ButtonIcon>
      </Header>
      <Title style={textColor}>{title}</Title>
      <SubTitle style={textColor}>{subtitle}</SubTitle>
      <BoxWinners>
        {currentTourney.rankingEnabled && ranking.length === 1 && (
          <>
            <div key="winners_left" style={{ width: '28%' }} />
            {winners(0)}
            <div key="winners_right" style={{ width: '28%' }} />
          </>
        )}
        {currentTourney.rankingEnabled && ranking.length === 2 && (
          <>
            {winners(1)}
            {winners(0)}
            <div key="winners_right" style={{ width: '28%' }} />
          </>
        )}
        {currentTourney.rankingEnabled && ranking.length >= 3 && (
          <>
            {winners(1)}
            {winners(0)}
            {winners(2)}
          </>
        )}
      </BoxWinners>
      <BoxRanking>
        <TableRanking ranking={tableRanking} tournament={tournament} />
      </BoxRanking>
      <BoxButton>
        {idMatch &&
          currentTourney?.startShowFeedback !== null &&
          verifyDateStringBeforeNow(currentTourney.startShowFeedback) && (
            <ButtonIcon
              style={{ width: '100%', margin: '16px 0px' }}
              onClick={() => history.push(`${MenuUrl.feedback}/${idMatch}`)}
            >
              <TextJustification style={primaryColor}>{t('showAnswers')}</TextJustification>
            </ButtonIcon>
          )}
        <BoxButtonBackgroundSolid style={backgroundPrimary}>
          <Button
            customBackground={primaryColor.color}
            style={{ maxWidth: '420px', margin: '10px' }}
            onClick={handleGoToTournaments}
          >
            {t('continue')}
          </Button>
        </BoxButtonBackgroundSolid>
      </BoxButton>
    </Container>
  );
};

export default Ranking;
