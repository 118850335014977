import { Reducer } from 'redux';
import { PreviewState } from './types';

const INITIAL_STATE: PreviewState = {
  previewParam: undefined,
};

const reducer: Reducer<PreviewState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_COMPANY_PREVIEW':
      return {
        ...state,
        previewParam: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
