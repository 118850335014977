import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PStarIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const StarIcon = ({ width, height, color, style }: PStarIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.2005 20.3432L14.6838 20.0313L14.167 20.3432L8.87769 23.5357L10.2814 17.5187L10.4185 16.9309L9.96228 16.5357L5.28796 12.4863L11.4407 11.9643L12.0416 11.9134L12.2769 11.3581L14.6838 5.6793L17.0906 11.3581L17.3259 11.9134L17.9268 11.9643L24.0795 12.4863L19.4052 16.5357L18.949 16.9309L19.0862 17.5187L20.4898 23.5357L15.2005 20.3432Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

StarIcon.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default StarIcon;
