import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const Container = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  font-family: ${fonts.semiBold};
  color: ${colors.white};
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};
  margin-bottom: 24px;
`;

export const ScoreText = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: zpx;
  color: ${colors.orange};
  font-family: ${fonts.regular};
  margin-bottom: 12px;
`;

export const StatsText = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: ${colors.white};
`;

export const BoxWinners = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

export const BoxButton = styled.div`
  display: flex;
  width: 100%;
  bottom: 0;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
`;
