import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

import { Container, Progress } from './styles';

interface PBarProgress {
  width: number;
  style?: CSSProperties;
}

const BarProgress = ({ width, style }: PBarProgress) => (
  <Container>
    <Progress width={width} style={style} />
  </Container>
);

BarProgress.defaultProps = {
  style: {},
};

export default BarProgress;
