import React, { useEffect, useRef } from 'react';
import CheckMark from './checkMark/CheckMark';
import StageDone from './stageDone/StageDone';
import LockOutlineIcon from '../../../shared/icons/LockOutlineIcon';
import { JOURNEY_GRID_COLUMNS, StepInterface } from '../Journey';

const Step = ({
  step,
  i,
  registerStepButton,
  onClick,
}: {
  step: StepInterface;
  i: number;
  registerStepButton: (index: number, ref: HTMLButtonElement) => void;
  onClick: () => void;
}) => {
  const stepButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const stepButton = stepButtonRef.current;
    if (stepButton) registerStepButton(i, stepButton);
  }, []);

  // Ensures the path has a zigzag pattern from bottom-up. Example:
  // 9 | 8 | 7
  // 4 | 5 | 6
  // 3 | 2 | 1
  const calcOrder = (index: number): number => {
    const row = Math.floor(index / JOURNEY_GRID_COLUMNS) + 1;
    const flow = row % 2 === 1 ? 'ltr' : 'rtl';
    const swapPositionsBy = [2, 0, -2];
    const result = flow === 'rtl' ? index + swapPositionsBy[i % JOURNEY_GRID_COLUMNS] : index;

    return -result;
  };

  const defineVerticalPositioning = (index: number): string => {
    const verticalPos = ['center', 'flex-start', 'center', 'flex-start', 'center', 'flex-start'];
    return verticalPos[index % (JOURNEY_GRID_COLUMNS * 2)];
  };

  const defineHorizontalPositioning = (index: number): string => {
    const horizontalPos = ['flex-end', 'center', 'flex-start', 'flex-end', 'center', 'flex-start'];
    return horizontalPos[index % (JOURNEY_GRID_COLUMNS * 2)];
  };

  return (
    <div
      className="journey__step-container"
      style={{
        alignItems: defineVerticalPositioning(i),
        justifyContent: defineHorizontalPositioning(i),
        order: calcOrder(i),
      }}
    >
      {step && (
        <div style={{ position: 'absolute' }}>
          {step.status === 'unlocked' && (
            <CheckMark style={{ position: 'relative', left: '30px', top: '15px' }} />
          )}
          <button
            className={`journey__step journey__step--${step.status}`}
            type="button"
            onClick={onClick}
            ref={stepButtonRef}
          >
            <div className="journey__step-body">
              <span className="journey__step-text">
                {i + 1}
                {['disabled', 'expired', 'waiting'].includes(step.status) && <LockOutlineIcon />}
              </span>
            </div>
          </button>
          {step.status === 'unlocked' && (
            <StageDone style={{ position: 'relative', bottom: '5px' }} />
          )}
        </div>
      )}
    </div>
  );
};

export default Step;
