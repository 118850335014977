import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';
import AwardIcon from '../../../icons/AwardIcon';
import { MenuUrl } from '../../../enums/menuUrl';
import { ButtonContainer, ButtonText, ContainerFooter } from './styles';
import { useCustomStyle, useLibrary, useTutorialRefForStep } from '../../../functions/hooks';
import { colors } from '../../../functions/colors';
import { RootStateGlobal } from '../../../../store/reducer';
import { actions as geralActions } from '../../../../store/reducers/geral';
import { t } from '../../../../i18n';
import FolderFilled from '../../../icons/FolderFilled';
import OutlinedFlag from '../../../icons/OutlinedFlag';
import PlaceIcon from '../../../icons/Place';
import PersonOutline from '../../../icons/PersonOutline';

const FooterMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { backgroundPrimary, primaryColor } = useCustomStyle();
  const ref = useTutorialRefForStep('homeStore');
  const { highlightElement } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const homeAndStoreHighlightClass = highlightElement === 'menuEntries' ? 'heart-beat' : undefined;
  const libraryHighlightClass = highlightElement === 'menuEntryLibrary' ? 'heart-beat' : undefined;
  const isLibraryEnabled = useLibrary();
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);

  const handleGoTo = (menuUrl: string) => {
    // Clears the store so that no element from menu gets highlighted. Thus,
    // if an element is being highlighted, changing the page will stop the
    // animation/highlighting.
    dispatch(geralActions.setHighlightElement());

    history.push(menuUrl);
  };

  const verifyPath = (menuUrl: string) => window.location.pathname.includes(menuUrl);
  const activeColor = primaryColor.color || colors.purpleRegular;

  if (!userData) {
    return null;
  }

  return (
    <ContainerFooter ref={ref} style={{ ...backgroundPrimary, borderColor: activeColor }}>
      <ButtonContainer
        onClick={() => handleGoTo(MenuUrl.journeysList)}
        verifyPath={verifyPath(MenuUrl.journeysList) || verifyPath(MenuUrl.home)}
        backgroundColor={activeColor}
      >
        <ButtonIcon className={homeAndStoreHighlightClass}>
          <PlaceIcon
            color={
              verifyPath(MenuUrl.journeysList) || verifyPath(MenuUrl.home) ? activeColor : undefined
            }
          />
        </ButtonIcon>
        <ButtonText
          activeColor={activeColor}
          verifyPath={verifyPath(MenuUrl.journeysList) || verifyPath(MenuUrl.home)}
        >
          {t('journeys')}
        </ButtonText>
      </ButtonContainer>

      {userData?.experiments?.leagues && (
        <ButtonContainer
          onClick={() => handleGoTo(MenuUrl.league)}
          verifyPath={verifyPath(MenuUrl.league)}
          backgroundColor={activeColor}
        >
          <ButtonIcon name="league" style={{ width: '24px', justifyContent: 'center' }}>
            <OutlinedFlag color={verifyPath(MenuUrl.league) ? activeColor : undefined} />
          </ButtonIcon>
          <ButtonText activeColor={activeColor} verifyPath={verifyPath(MenuUrl.league)}>
            {t('leagues')}
          </ButtonText>
        </ButtonContainer>
      )}

      {userData.experiments.rewards && (
        <ButtonContainer
          onClick={() => handleGoTo(MenuUrl.award)}
          verifyPath={verifyPath(MenuUrl.award)}
          backgroundColor={activeColor}
        >
          <ButtonIcon name="rewards" className={homeAndStoreHighlightClass}>
            <AwardIcon color={verifyPath(MenuUrl.award) ? activeColor : undefined} />
          </ButtonIcon>
          <ButtonText activeColor={activeColor} verifyPath={verifyPath(MenuUrl.award)}>
            {t('rewards')}
          </ButtonText>
        </ButtonContainer>
      )}
      {isLibraryEnabled && (
        <ButtonContainer
          onClick={() => handleGoTo(MenuUrl.libraryList)}
          verifyPath={verifyPath(MenuUrl.libraryList)}
          backgroundColor={activeColor}
        >
          <ButtonIcon name="library" className={libraryHighlightClass}>
            <FolderFilled color={verifyPath(MenuUrl.libraryList) ? activeColor : undefined} />
          </ButtonIcon>
          <ButtonText activeColor={activeColor} verifyPath={verifyPath(MenuUrl.libraryList)}>
            {t('library')}
          </ButtonText>
        </ButtonContainer>
      )}

      <ButtonContainer
        onClick={() => handleGoTo(MenuUrl.profile)}
        verifyPath={verifyPath(MenuUrl.profile)}
        backgroundColor={activeColor}
      >
        <ButtonIcon>
          <PersonOutline color={verifyPath(MenuUrl.profile) ? activeColor : undefined} />
        </ButtonIcon>
        <ButtonText activeColor={activeColor} verifyPath={verifyPath(MenuUrl.profile)}>
          {t('profile')}
        </ButtonText>
      </ButtonContainer>
    </ContainerFooter>
  );
};

export default FooterMenu;
