import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../buttons/Button';
import { actions as geralActions } from '../../../../store/reducers/geral';

import { Container, Description } from './styles';
import { t } from '../../../../i18n';
import RichTextEditor from '../../../../features/richEditor/RichTextEditor';
import { useCustomStyle } from '../../../functions/hooks';

interface PJustification {
  description: string;
}

const Justification = ({ description }: PJustification) => {
  const dispatch = useDispatch();
  const { primaryColor } = useCustomStyle();
  const closeModal = () => dispatch(geralActions.closeModal());

  return (
    <Container>
      <Description>
        <RichTextEditor value={description} />
      </Description>
      <Button customBackground={primaryColor.color} onClick={closeModal}>
        {t('okContinue')}
      </Button>
    </Container>
  );
};

export default Justification;
