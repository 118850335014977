import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions as geralActions } from '../../../store/reducers/geral';
import { RootStateGlobal } from '../../../store/reducer';
import CloseIcon from '../../icons/CloseIcon';
import { ModalData } from '../../modals/geral/geral';
import { Container, Header, Title, Shadow, Content } from './styles';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import { useCustomStyle } from '../../functions/hooks';

const Modal = () => {
  const dispatch = useDispatch();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const { modalData } = useSelector((state: RootStateGlobal) => state.geralReducer);
  const { primaryColor } = useCustomStyle();
  const { show, height, title, children } = modalData;
  const visibility = show ? 'visible' : 'hidden';

  const closeModal = () => {
    setModalData({
      ...modalData,
      show: false,
    });
  };

  return (
    <Shadow show={show} visibility={visibility}>
      <Container show={show} height={height}>
        <Header>
          <Title style={primaryColor}>{title}</Title>
          <ButtonIcon onClick={closeModal}>
            <CloseIcon />
          </ButtonIcon>
        </Header>
        <Content id="modalID">{children}</Content>
      </Container>
    </Shadow>
  );
};

export default Modal;
