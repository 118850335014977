import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import ButtonText from '../../../shared/components/buttons/buttonText/ButtonText';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { colors } from '../../../shared/functions/colors';
import { useCustomStyle } from '../../../shared/functions/hooks';
import { Award } from '../../../shared/modals/award/award';
import { ModalData } from '../../../shared/modals/geral/geral';
import { RootStateGlobal } from '../../../store/reducer';
import { actions as geralActions } from '../../../store/reducers/geral';
import RulesAward from './rulesAward.tsx/RulesAward';
import {
  Container,
  Image,
  ContainerImage,
  ContainerInfo,
  Title,
  Text,
  BoxButton,
  ContainerButtons,
  BoxValue,
  ImageCash,
  ImageGray,
  ContainerText,
  BoxStockAndRules,
  TextDate,
  BackgroundDateInfo,
} from './styles';
import { dateTimeStampToString } from '../../../shared/functions/date';
import { t } from '../../../i18n';

interface PBoxAwardAvailable {
  award: Award;
  hideButtonBuy?: boolean;
}

const BoxAwardAvailable = ({ award, hideButtonBuy }: PBoxAwardAvailable) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const setModalData = (x: ModalData) => dispatch(geralActions.setModal(x));
  const { wallet } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { textColor, backgroundSecondary, primaryColor } = useCustomStyle();
  const [borderColor, setBorderColor] = useState(primaryColor.color || colors.purpleRegular);

  const isAvailable = award.available > 0;

  const startDateString = award.toBeStarted && dateTimeStampToString(award.startDate);
  const endDateString = award.endDate && dateTimeStampToString(award.endDate);

  const handleOpenRules = () => {
    setModalData({
      show: true,
      height: '320px',
      children: <RulesAward award={award} />,
      title: t('rules'),
    });
  };

  const handleOpenBuyAward = () => {
    if (!award.toBeStarted) {
      history.push(`${MenuUrl.award}/${award.idAward}`);
    }
  };

  useEffect(() => {
    if (award.toBeStarted) {
      setBorderColor(colors.orange);
    } else if (award.endDate) {
      setBorderColor(colors.red);
    }
  }, []);

  return (
    <Container
      style={{
        borderLeft: `4px solid ${borderColor}`,
        ...backgroundSecondary,
      }}
    >
      {award.endDate && !award.toBeStarted && (
        <BackgroundDateInfo>
          <TextDate>{t('availableUntil', { endDateString })}</TextDate>
        </BackgroundDateInfo>
      )}
      {award.toBeStarted && (
        <BackgroundDateInfo style={{ backgroundColor: colors.orange }}>
          <TextDate style={{ color: colors.black, fontWeight: '700' }}>
            {t('availableAfter', { startDateString })}
          </TextDate>
        </BackgroundDateInfo>
      )}
      <Title style={textColor}>{award.name}</Title>
      <ContainerInfo>
        {award.image && (
          <ContainerImage>
            {isAvailable ? (
              <Image src={award.image} alt="icon" />
            ) : (
              <ImageGray src={award.image} alt="icon" />
            )}
          </ContainerImage>
        )}

        <ContainerText>
          <BoxButton>
            <BoxValue>
              <ImageCash src="/images/magicashCash.png" alt="MC" />
              <Text>{award.value}</Text>
            </BoxValue>

            <BoxStockAndRules>
              {award.showAvailableStock && (
                <Text
                  style={{
                    color: colors.grey100,
                    fontSize: '8px',
                    lineHeight: '9px',
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  {`${t('quantity')}: ${award.available}`}
                </Text>
              )}
              <Text style={{ alignSelf: 'self-end' }}>
                <ButtonText
                  color={colors.orange}
                  style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: 1,
                    fontSize: '8px',
                    display: 'flex',
                  }}
                  onClick={() => {
                    handleOpenRules();
                  }}
                >
                  {t('checkRules')}
                </ButtonText>
              </Text>
            </BoxStockAndRules>
          </BoxButton>

          <ContainerButtons style={{ marginTop: award.toBeStarted ? '8px' : '20px' }}>
            {!hideButtonBuy && (
              <BoxButton style={{ flexDirection: 'column' }}>
                <Button
                  disabled={
                    !wallet ||
                    wallet.magicashAmount < award.value ||
                    !isAvailable ||
                    award.toBeStarted
                  }
                  onClick={handleOpenBuyAward}
                  typeButton="primary-pink"
                  iconLeft={isAvailable ? 'award' : 'block'}
                  customBackground={primaryColor.color}
                >
                  {isAvailable ? t('buy') : t('soldOff')}
                </Button>
              </BoxButton>
            )}
          </ContainerButtons>
        </ContainerText>
      </ContainerInfo>
    </Container>
  );
};

BoxAwardAvailable.defaultProps = {
  hideButtonBuy: false,
};

export default BoxAwardAvailable;
