import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateGlobal } from '../../../../store/reducer';
import { useCustomStyle } from '../../../functions/hooks';
import ClockIcon from '../../../icons/ClockIcon';
import HitsIcon from '../../../icons/HitsIcon';
import { RankData } from '../../../modals/socket/Socket';
import { Tournament } from '../../../modals/tournament/Tournament';
import PrizeCard from '../../card/prizeCard/PrizeCard';

import { Table, Line, Column, TextLimited } from './styles';

interface PTableRanking {
  ranking: RankData[];
  tournament: Tournament;
}

const TableRanking = ({ ranking, tournament }: PTableRanking) => {
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const { textColor, backgroundPrimary, backgroundSecondary } = useCustomStyle();

  return (
    <Table>
      {ranking.map((userRanking, index) => (
        <Line
          key={`table_ranking_line_${index}`}
          isPlayerLogged={!!userData && userRanking.idUser === userData.idUser}
          isPair={index % 2 === 0}
          customBackground={backgroundPrimary.backgroundColor}
          customBackgroundSecondary={backgroundSecondary.backgroundColor}
        >
          <Column style={textColor}>{userRanking.position && `${userRanking.position}º`}</Column>
          <Column>
            <TextLimited style={textColor}>{userRanking.name}</TextLimited>
          </Column>
          <Column style={textColor}>
            <ClockIcon
              color={textColor.color}
              width={24}
              height={16}
              style={{ marginBottom: -4 }}
            />
            {userRanking.time}
          </Column>
          <Column style={textColor}>
            <HitsIcon color={textColor.color} width={24} height={16} style={{ marginBottom: -4 }} />
            {`${userRanking.score}/${tournament.questionAmount}`}
          </Column>
          <Column>
            {userRanking.prize > 0 && <PrizeCard value={userRanking.prize} isTransparent />}
          </Column>
        </Line>
      ))}
    </Table>
  );
};

export default TableRanking;
