import React from 'react';
import { History } from 'history';
import LastPlay from '../../components/card/tournamentCard/lastPlay/LastPlay';
import { MenuUrl } from '../../enums/menuUrl';
import { ModalData } from '../../modals/geral/geral';
import { Tournament } from '../../modals/tournament/Tournament';
import { t } from '../../../i18n';

export const calcRemainingTime = (contest: Tournament, timePassed = 0) => {
  // time remaining format vars
  let seconds;
  let minutes;
  let hours;
  let day;
  let month;
  let secondsCounted;

  // time remaining text formatting
  if (contest.endSeconds) {
    secondsCounted = contest.endSeconds - timePassed;
    secondsCounted = secondsCounted <= 0 ? 0 : secondsCounted;
    seconds = secondsCounted % 60;
    hours = Math.floor((secondsCounted - seconds) / 60 / 60);
    minutes = Math.floor((secondsCounted - hours * 60 * 60 - seconds) / 60);

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  }

  if (contest.endDate) {
    const date = new Date(contest.endDate);
    day = date.getDate();
    month = date.getMonth() + 1;
    hours = date.getHours();
    minutes = date.getMinutes();

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  }

  let remainingTime = '';
  if (contest.endDate) {
    remainingTime = `${hours}:${minutes} ${day}/${month}`;
  } else if (hours === '00' && minutes === '00' && seconds === '00') {
    remainingTime = 'Finalizado';
  } else {
    remainingTime = `${hours}:${minutes}:${seconds}`;
  }

  return {
    remainingTime,
    seconds,
    minutes,
    hours,
    day,
    month,
    secondsCounted,
  };
};

export const handleStartContest = (
  contest: Tournament,
  exitToPath: string,
  history: History,
  closeModal: () => void,
  setModalData: (x: ModalData) => void,
) => {
  const { secondsCounted } = calcRemainingTime(contest);

  const playContest = () => {
    closeModal();
    if ((secondsCounted && secondsCounted > 0) || contest.endDate) {
      history.push(`${MenuUrl.tournament}/${contest.idTourney}`, { exitToPath });
    }
  };

  if (contest.entryLimit - 1 === contest.amountPlayed) {
    setModalData({
      show: true,
      children: <LastPlay playTourney={playContest} closeModal={closeModal} />,
      height: '280px',
      title: t('attention'),
    });
  } else {
    playContest();
  }
};

export const handleStartContestPresentations = (
  contest: Tournament,
  exitToPath: string,
  history: History,
) => {
  history.push(MenuUrl.presentation, {
    gameable: contest,
    exitToPath,
  });
};

export const handleJourneyStartContest = (
  contest: Tournament,
  exitToPath: string,
  history: History,
  closeModal: () => void,
  setModalData: (x: ModalData) => void,
) => {
  if (contest.presentations.length) {
    closeModal();
    handleStartContestPresentations(contest, exitToPath, history);
  } else {
    handleStartContest(contest, exitToPath, history, closeModal, setModalData);
  }
};
