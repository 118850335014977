import { Reducer } from 'redux';

import { GeralState, GeralTypes } from './types';

const INITIAL_STATE: GeralState = {
  snackbarData: {
    message: '',
    show: false,
    type: 'success',
  },
  blockScreen: false,
  modalData: {
    show: false,
    children: null,
    title: '',
  },
  fullScreen: undefined,
  coinsEarned: 0,
  highlightElement: undefined,
  canPurchaseSomethingNew: false,
};

const reducer: Reducer<GeralState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GeralTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbarData: action.payload,
      };
    case GeralTypes.SET_BLOCK_SCREEN:
      return {
        ...state,
        blockScreen: action.payload,
      };
    case GeralTypes.SET_MODAL:
      return {
        ...state,
        modalData: action.payload,
      };
    case GeralTypes.CLOSE_MODAL:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          show: false,
        },
      };
    case GeralTypes.SET_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      };
    case GeralTypes.SET_COINS_EARNED:
      return {
        ...state,
        coinsEarned: action.payload,
      };
    case GeralTypes.SET_HIGHLIGHT_ELEMENT:
      return {
        ...state,
        highlightElement: action.payload,
      };
    case GeralTypes.SET_SHOW_NOTIFICATION:
      return {
        ...state,
        canPurchaseSomethingNew: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
