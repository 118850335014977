import { ConnectApiPUT, urls } from '../functions/connection';
import { MilestoneInterface, StepLeftInterface } from '../modals/user/user';

type StepCompletedInterface = {
  stepConcluded: StepLeftInterface;
};

type MilestoneReachedInterface = {
  milestoneReached: MilestoneInterface;
};

export const stepDone = async (body: StepCompletedInterface) => {
  const respostaServico = await ConnectApiPUT(urls.TUTORIAL_URL, body);
  return respostaServico.data;
};

export const milestoneReached = async (body: MilestoneReachedInterface) => {
  const respostaServico = await ConnectApiPUT(urls.TUTORIAL_URL, body);
  return respostaServico.data;
};
