import React from 'react';

import { t } from '../../i18n';

const NotFound = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 32,
    }}
  >
    <img
      alt=""
      src="/images/logoTuTo.png"
      style={{
        maxWidth: '50%',
        maxHeight: 104,
      }}
    />
    <h1 style={{ color: 'white', marginTop: 64 }}>{t('productNotFound')}</h1>
    <p style={{ color: 'white' }}>
      {t('moreInfo')}
      <span>
        {' '}
        <a href="mailto:contato@tutodigital.com.br" style={{ color: 'white', fontWeight: 100 }}>
          contato@tutodigital.com.br
        </a>
      </span>
      .
    </p>
  </div>
);

export default NotFound;
