import { History } from 'history';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { actions as geralActions } from '../../../store/reducers/geral';

import { Container, Description } from './styles';
import { t } from '../../../i18n';

interface PLeaveProfile {
  history: History;
}

const LeaveProfile = ({ history }: PLeaveProfile) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    history.push(MenuUrl.home);
    dispatch(geralActions.closeModal());
  };

  return (
    <Container>
      <Description dangerouslySetInnerHTML={{ __html: t('modalConfirmationClose') }} />
      <Button onClick={closeModal}>{t('yesContinue')}</Button>
    </Container>
  );
};

export default LeaveProfile;
