import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

export const Container = styled.div`
  position: relative;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const BoxPagination = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  background-color: ${colors.white};
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(38, 40, 36, 0.24);
  width: 220px;
  border-radius: 4px;
`;

export const Text = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;
