import { Dispatch } from 'redux';
import { MatchResponseData, QuestionData } from '../../../shared/modals/socket/Socket';

import * as serviceTournament from '../../../shared/services/serviceTournament';
import * as serviceMatch from '../../../shared/services/serviceMatch';

import * as tournamentActions from './actions';
import { ERROR_MAINTENANCE } from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';

export const getTournament = (idTourney: string) => async (dispatch: Dispatch) => {
  try {
    const tournament = await serviceTournament.getTournament(idTourney);
    dispatch(tournamentActions.setTournament(tournament));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const createMatch = (idTourney: string) => async (dispatch: Dispatch) => {
  try {
    const matchData: MatchResponseData = await serviceMatch.createMatch(idTourney);
    dispatch(tournamentActions.setQuestion(matchData.question));
    dispatch(tournamentActions.setMatch(matchData.match));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const verifyAnswer = (
  idQuestion: string,
  idAlternative: string,
  idMatch: string,
  currentIndex: number,
  idTourney: string,
) => async (dispatch: Dispatch) => {
  try {
    const question: QuestionData = await serviceMatch.verifyAnswer(
      idQuestion,
      idAlternative,
      idMatch,
      currentIndex,
      true,
      idTourney,
    );
    dispatch(tournamentActions.setQuestion(question));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      throw error;
    }
  }
};

export const getLitTournament = () => async (dispatch: Dispatch) => {
  try {
    const listTournament = await serviceTournament.getAllTournament();
    dispatch(tournamentActions.setListTournament(listTournament));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
    dispatch(tournamentActions.setListTournament([]));
  }
};
