import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';

interface PImage {
  source: string;
}

export const Container = styled.div`
  position: relative;
  background-color: ${colors.black};
  border-radius: 4px;
  width: 100%;
  max-width: 372px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
`;

export const TopData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
`;

export const LogoData = styled.div<PImage>`
  background-color: ${colors.black};
  ${props => (props.source !== '' ? `background-image: url('${props.source}');` : '')}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  height: 151px;
  width: 100%;
  max-width: 372px;
  border-radius: 24px 24px 0 0;
`;

export const Name = styled.h2`
  text-align: center;
  color: ${colors.white};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;
