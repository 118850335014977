import { Reducer } from 'redux';

import { StatisticsState, StatisticsTypes } from './statisticsTypes';

const INITIAL_STATE: StatisticsState = {
  statistics: undefined,
  bestTourneys: [],
  worseTourneys: [],
};

const reducer: Reducer<StatisticsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StatisticsTypes.SET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case StatisticsTypes.SET_BEST_TOURNEYS:
      return {
        ...state,
        bestTourneys: action.payload,
      };
    case StatisticsTypes.SET_WORSE_TOURNEYS:
      return {
        ...state,
        worseTourneys: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
