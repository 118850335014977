import { Dispatch } from 'redux';
import { RankingData } from '../../../shared/modals/socket/Socket';

import * as rankingActions from './rankingActions';
import * as leagueActions from '../league/actions';
import * as serviceMatch from '../../../shared/services/serviceMatch';
import { ERROR_MAINTENANCE } from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setCoinsEarned } from '../geral/actions';

export const getRankingAfterMatch = (idMatch: string, idTourney: string) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(rankingActions.setRanking([]));
    const rankingData: RankingData = await serviceMatch.endMatch(idMatch, idTourney);
    dispatch(rankingActions.setRanking(rankingData.ranking));
    dispatch(rankingActions.setCountdown(rankingData.endSeconds));
    dispatch(rankingActions.setCurrentTourney(rankingData.tourney));
    dispatch(rankingActions.setResult(rankingData.result));
    dispatch(leagueActions.setRanking(rankingData.seasonConfig));
    if (rankingData.coinsEarned) dispatch(setCoinsEarned(rankingData.coinsEarned));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
    dispatch(rankingActions.setRanking([]));
  }
};

export const getRanking = (idTourney: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(rankingActions.setRanking([]));
    const rankingData: RankingData = await serviceMatch.getRanking(idTourney);
    dispatch(rankingActions.setRanking(rankingData.ranking));
    dispatch(rankingActions.setCountdown(rankingData.endSeconds));
    dispatch(rankingActions.setCurrentTourney(rankingData.tourney));
  } catch (error) {
    if (error instanceof Error && error.message === ERROR_MAINTENANCE) {
      doLogout();
    }
    dispatch(rankingActions.setRanking([]));
  }
};
