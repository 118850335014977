import * as LocalStorageProxy from './connection/localStorageProxy';
import { STYLES_COMPANY_KEY, WHATSAPP_COMPANY_KEY } from '../constants/keysLocalStorage';

export const setStylesCompany = (value: string) => {
  if (value) LocalStorageProxy.setItem(STYLES_COMPANY_KEY, value);
};

export const setShowWhatsappIcon = (value: string) => {
  if (value) LocalStorageProxy.setItem(WHATSAPP_COMPANY_KEY, value);
};
