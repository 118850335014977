import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface POutlinedFlag {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const OutlinedFlag = ({ width, height, color, style }: POutlinedFlag) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M17.0523 7.99943L16.1997 6.28235C15.9984 5.87972 15.5839 5.63104 15.1339 5.63104H7.57873C6.92742 5.63104 6.39453 6.16393 6.39453 6.81524V24.5782C6.39453 25.2295 6.92742 25.7624 7.57873 25.7624C8.23003 25.7624 8.76292 25.2295 8.76292 24.5782V17.473H14.6839L15.5365 19.1901C15.7378 19.5927 16.1523 19.8414 16.5905 19.8414H22.9733C23.6246 19.8414 24.1575 19.3085 24.1575 18.6572V9.18363C24.1575 8.53232 23.6246 7.99943 22.9733 7.99943H17.0523ZM21.7891 17.473H17.0523L15.8681 15.1046H8.76292V7.99943H14.6839L15.8681 10.3678H21.7891V17.473Z"
        fill={color}
      />
    </svg>
  );
};

OutlinedFlag.defaultProps = {
  width: 29,
  height: 29,
  color: colors.white,
  style: {},
};

export default OutlinedFlag;
