import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import axios, { CancelTokenSource } from 'axios';
import { uploadImage } from '../../services/serviceFile';
import {
  Container,
  InputFile as Input,
  Title,
  SubTitle,
  ContainerInput,
  BoxInput,
  InfoLocalFile,
} from './styles';
import ClipsIcon from '../../icons/ClipsIcon';
import TrashIcon from '../../icons/TrashIcon';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import BarProgress from '../bars/barProgress/BarProgress';
import { t } from '../../../i18n';

const INITIAL_MESSAGE_NOT_FILE = t('noFileSelected');

interface PInputFileImage {
  setUrlImage: (x: string) => void;
  setIsLoading: (x: boolean) => void;
  title: string;
  subTitle?: string;
  style?: CSSProperties;
}

const InputFile = ({ title, subTitle, style, setUrlImage, setIsLoading }: PInputFileImage) => {
  const [valueInput, setValueInput] = useState('');
  const [progress, setProgress] = useState(0);
  const axiosSource = useRef<CancelTokenSource>();
  const newCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, []);

  const handleDeleteImage = () => {
    axiosSource.current?.cancel('Upload canceled by user.');
    setValueInput('');
    setUrlImage('');
    setProgress(0);
    setIsLoading(false);
  };

  const onUploadProgress = (data: { loaded: number; total: number }) => {
    //Set the progress value to show the progress bar
    setProgress(Math.round((100 * data.loaded) / data.total));
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueInput(event.target.value);
    const { files } = event.target;
    const uploadPromises = [];

    if (files?.length) {
      setIsLoading(true);

      for (let index = 0; index < files.length; index += 1) {
        const file = files[index];
        uploadPromises.push(uploadImage(file, { onUploadProgress, cancelToken: newCancelToken() }));
      }
    }

    let urls = '';
    Promise.all(uploadPromises).then(returnUploadImages => {
      for (let index = 0; index < returnUploadImages.length; index += 1) {
        const upload = returnUploadImages[index];
        urls += urls === '' ? upload.locationFile : `, ${upload.locationFile}`;
      }

      setUrlImage(urls);
      setIsLoading(false);
    });
  };

  return (
    <Container style={style}>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <ContainerInput>
        <BoxInput>
          <InfoLocalFile>{valueInput || INITIAL_MESSAGE_NOT_FILE}</InfoLocalFile>
          <ClipsIcon />
          <Input type="file" multiple onChange={handleChangeFile} value={valueInput} />
        </BoxInput>
        <ButtonIcon onClick={handleDeleteImage}>
          <TrashIcon />
        </ButtonIcon>
        {!!progress && (
          <>
            <div style={{ width: 'calc(100% - 40px)', marginTop: 4 }}>
              <BarProgress width={progress} />
            </div>
            {`${progress}%`}
          </>
        )}
      </ContainerInput>
    </Container>
  );
};

InputFile.defaultProps = {
  subTitle: '',
  style: {},
};

export default InputFile;
