import { Award } from '../../../shared/modals/award/award';

export enum AwardTypes {
  SET_ACQUIRED_AWARD = '@award/SET_ACQUIRED_AWARD',
  SET_AVAILABLE_AWARD = '@award/SET_AVAILABLE_AWARD',
  SET_AWARD = '@award/SET_AWARD',
}

export interface AwardState {
  readonly acquiredAward: Award[];
  readonly availableAward: Award[];
  readonly award?: Award;
}
