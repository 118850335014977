import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import { updateProfileVisibility } from '../../shared/services/leagues';
import { Container } from './styles';
import * as leagueActions from '../../store/reducers/league/actions';
import { t } from '../../i18n';

const EnterLeague = () => {
  const dispatch = useDispatch();
  const location = useLocation<never>();
  const { leanLayout } = location.state || {};

  const handleEnableLeague = async () => {
    const data = await updateProfileVisibility(true);
    if (data.seasonConfig) dispatch(leagueActions.setSeason(data.seasonConfig));
  };

  return (
    <Container leanLayout={leanLayout} style={{ height: '100%' }}>
      <div
        style={{ height: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center' }}
      >
        <div>{t('welcomeAgain')}</div>
        <div style={{ margin: '8px 0' }}>{t('joinLeaguesText')}</div>
        <Button onClick={() => handleEnableLeague()}>{t('joinLeagues')}</Button>
      </div>
    </Container>
  );
};

export default EnterLeague;
