import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { merge, set } from 'lodash';

export const handleChangeFieldValue = <type>(
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  setState: Dispatch<SetStateAction<type>>,
) => {
  const { type, name, value, checked } = event.target as HTMLInputElement;
  const overrides = set({}, name, type === 'checkbox' ? checked : value);

  // If we use the splat operator here ({ ...previousState, ...overrides }),
  // our `fields` property would always be overridden instead of adding new
  // properties to the object.
  //
  // Example: if we had { ..., fields: { whatsapp: true } } and then selected
  // the email field, it would become { ..., fields: { email: true } } instead
  // of { ..., fields: { whatsapp: true, email: true } }.
  //
  // Lodash.merge fixes this issue by merging objects deeply. However it uses
  // the first object as the base. I.e., it goes adding new properties to the
  // object. It's problematic for React because we should not change the state
  // variable directly. That's why we then use the splat operator.
  setState(previousState => ({ ...merge(previousState, overrides) }));
};
