import { isEmpty } from 'lodash';
import { PlayableChallenge } from '../constants/types';
import * as stepsService from '../services/steps';
import { Tournament } from '../modals/tournament/Tournament';

interface BaseStepInterface {
  id: string;
  title: string;
  description: string;
  status: 'disabled' | 'waiting' | 'enabled' | 'expired' | 'unlocked';
}

type StepInterface = BaseStepInterface &
  (
    | {
        type: 'Contest';
        target: Tournament;
      }
    | {
        type: 'Challenge';
        target: PlayableChallenge;
      }
  );

export const unifyAvailablePresentations = async (journeyId: string) => {
  const steps: StepInterface[] = await stepsService.getAll(journeyId);

  return steps
    .filter(({ target }) => !isEmpty(target.presentations))
    .flatMap(({ target }) => target.presentations);
};
