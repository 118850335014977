import * as React from 'react';
import { colors } from '../functions/colors';

interface PPlayIcon {
  color?: string;
}

const PlayIcon = ({ color }: PPlayIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} viewBox="0 0 13 12" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 .972v10.056c0 .767 1.115 1.233 1.973.815l10.431-5.028c.795-.378.795-1.252 0-1.64L1.974.157C1.113-.261 0 .205 0 .972z"
      fill="#171716"
    />
  </svg>
);

PlayIcon.defaultProps = {
  color: colors.white,
};

export default PlayIcon;
