import styled from 'styled-components';
import { colors } from '../../../../shared/functions/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  border-radius: 4px;
  background: linear-gradient(180deg, ${colors.purpleRegular} 0%, ${colors.green} 100%);
  height: 26px;
  border-radius: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  border-radius: 4px;
  background-color: ${colors.gray};
  height: 18px;
  border-radius: 100%;
`;

export const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  border-radius: 4px;
  background-color: ${colors.green};
  height: 14px;
  border-radius: 100%;
`;
