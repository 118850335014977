import React, { CSSProperties } from 'react';
import { colors } from '../functions/colors';

interface PDoneIcon {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

const DoneIcon = ({ width, height, color, style }: PDoneIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <mask id="prefix__DoneIcon" maskUnits="userSpaceOnUse" x={2} y={27} width={117} height={68}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.2 27.95l-52.9 52.9L37.4 60l-7.05 7.05L58.3 95l60-60-7.1-7.05zM90 35l-7.05-7.05-31.7 31.7 7.05 7.05L90 35zM30 95L2.05 67.05 9.15 60l27.9 27.95L30 95z"
        fill={colors.white}
      />
    </mask>
    <g mask="url(#prefix__DoneIcon)">
      <path fill={color} d="M0 0h120v120H0z" />
    </g>
  </svg>
);

DoneIcon.defaultProps = {
  width: 120,
  height: 120,
  color: colors.green,
  style: {},
};

export default DoneIcon;
