import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  width: 418px;
  background: ${colors.black};
  border-radius: 4px;
  border-left: 4px solid ${colors.purpleRegular};
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
`;

export const Title = styled.h1`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.white};
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 96px;
`;

export const Text = styled.p`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  @media only screen and (max-width: 360px) {
    align-self: center;
  }
`;
