import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { random, times } from 'lodash';
import { SnackbarData } from '../../shared/modals/geral/geral';
import { actions as geralAction } from '../../store/reducers/geral';
import { Box } from '../../shared/components/styles/styledBox';
import { Container, ImageFlagContainer } from '../login/styles';
import { actionAnalitycs } from '../../shared/functions/analytics';
import { ACTIONS_SELF_SIGN_UP, CATEGORIES } from '../../shared/constants/constantsAnalytics';
import { isLogged } from '../../shared/functions/connection/auth';
import * as selfSignUpService from '../../shared/services/selfSignUp';
import { ERROR_USER_BLOCKED } from '../../shared/constants/messages';
import * as LocalStorageProxy from '../../shared/functions/connection/localStorageProxy';
import UserCampaignForm, {
  newUser,
  SelfSignUpCampaignInterface,
  User as UserForm,
} from './UserCampaignForm';
import { User } from '../../shared/modals/user/user';
import { colors } from '../../shared/functions/colors';
import GoogleSSO from '../sso/GoogleSSO';
import Button from '../../shared/components/buttons/Button';
import { fonts } from '../../shared/functions/fonts';
import { isProvidedDataEnough } from '../../shared/functions/ssu_sso';
import { BodyUserLogin } from '../../shared/modals/user/bodies';
import { operations as operationsUser } from '../../store/reducers/user';
import { RootStateGlobal } from '../../store/reducer';
import { MenuUrl } from '../../shared/enums/menuUrl';
import Spinner from '../../shared/components/loadings/Spinner';
import NotFound from './NotFound';
import * as serviceUser from '../../shared/services/serviceUser';
import NewAssociationModal from './NewAssociationModal';
import TutoText from '../../shared/components/other/tutoText/TutoText';
import { t } from '../../i18n';
import { LanguageSelector } from '../../shared/components/languageSelector/LanguageSelector';

type SIGNUP_METHOD = 'choosing' | 'fillingPartially' | 'fillingFully';

export interface SSODataInterface {
  name: string;
  email: string;
  image?: string;
  googleTokenId: string;
}

// TODO: Hard coded asked by Gustavo Amaral for Escola de Pessoas
const escolaDePessoasProductionId = '9901ccb9-9eaa-4892-bee2-a2e8901452ee';
// TODO: Ugly hotfix solution since we dont have today a better approach o server. Continental Pneus Id: 6d17892f-430c-4b94-9d74-2624740be7e8
// Some Partners don't have slug, so we need to check the hostname. See backend for more details.
const continentalPneusHostName = 'contiacademy.com.br';
const continentalPneusId = '6d17892f-430c-4b94-9d74-2624740be7e8';

const mustHideGoogle = (currentCompanyId: string, targetCompanyId: string, hostname?: string) => {
  return (
    currentCompanyId === targetCompanyId ||
    (hostname && window.location.hostname.includes(hostname))
  );
};

export const passiveAcceptancesRenderer = (campaign: SelfSignUpCampaignInterface) => (
  <div style={{ fontSize: 12, marginTop: 16 }}>
    <div style={{ color: colors.white, opacity: '50%' }}>
      <span>{t('terms')}</span>
      {campaign.fields
        .filter(field => field.fieldType === 'acceptance')
        .map((field, index, acceptances) => {
          return (
            <>
              <a
                href={field.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: colors.white,
                  textDecoration: 'none',
                  fontFamily: fonts.bold,
                }}
              >
                {field.label}
              </a>
              {(index === acceptances.length - 1 && '.') ||
                (index === acceptances.length - 2 && ' e ') ||
                ', '}
            </>
          );
        })}
    </div>
    <TutoText style={{ marginTop: '30px' }} />
  </div>
);

const SelfSignUp = () => {
  const history = useHistory();
  const location = useLocation();
  const { hash } = useParams<{ hash: string }>();
  const dispatch = useDispatch();
  const reqLogin = async (x: BodyUserLogin, companyId: string) =>
    dispatch(operationsUser.reqLogin(x, companyId));
  const { companyStyles } = useSelector(
    (state: RootStateGlobal) => state.companyPreferenceStylesReducer,
  );
  const setSnackBar = (x: SnackbarData) => dispatch(geralAction.setSnackbarData(x));
  const { userData } = useSelector((state: RootStateGlobal) => state.userReducer);
  const [selfSignUpCampaign, setSelfSignUpCampaign] = useState<SelfSignUpCampaignInterface>();
  const [user, setUser] = useState<UserForm>(newUser);
  const [error, setError] = useState<Partial<UserForm>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<SIGNUP_METHOD>('choosing');
  const password = times(20, () => random(35).toString(36)).join('');
  const { i18n } = useTranslation();

  useEffect(() => {
    actionAnalitycs(CATEGORIES.SELF_SIGN_UP, ACTIONS_SELF_SIGN_UP.OPEN, window.location.pathname);
  }, []);

  const showAssociationModal = (user: User, companyName: string, ssuHash: string) => {
    dispatch(
      geralAction.setModal({
        show: true,
        height: '320px',
        children: (
          <NewAssociationModal
            userData={user}
            companyName={companyName}
            ssuHash={ssuHash}
            location={location}
          />
        ),
        title: t('newAssociation'),
      }),
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      // isLogged() checks whether the user's browser has the JWT
      if (isLogged()) {
        try {
          const data = await serviceUser.getUserData(hash);

          if (data.newAssociationToCompany?.length) {
            showAssociationModal(data.user, data.newAssociationToCompany, hash);
          } else {
            history.push('/home');
            return;
          }
        } catch (e) {
          if (e instanceof Error && e.message === ERROR_USER_BLOCKED) {
            LocalStorageProxy.clear();
          } else {
            throw e;
          }
        }
      }

      const campaign = await selfSignUpService.get(hash);
      setSelfSignUpCampaign(campaign);

      setLoading(false);

      if (!campaign) return;

      const { areas } = campaign;
      if (areas.length === 1) {
        setUser(user => ({ ...user, areaIds: campaign.areas[0].id }));
      }
    })();
  }, []);

  useEffect(() => {
    user.referrerId = queryString.parse(location.search).uId as string;
  }, []);

  useEffect(() => {
    const { search } = location;
    const status = ((queryString.parse(search).status || 'choosing') as unknown) as SIGNUP_METHOD;
    setStatus(status);
  }, [location.search]);

  useEffect(() => {
    if (status === 'fillingPartially')
      setSnackBar({
        message: t('completeFormText'),
        show: true,
        type: 'success',
      });
  }, [status]);

  useEffect(() => {
    if (!userData) return;

    if (userData.frozenAt || userData.updateRequired) history.push(MenuUrl.updateInfo);
    else history.push(MenuUrl.home);
  }, [userData]);

  const loginOrCompleteForm = async (ssoData: SSODataInterface, companyId: string) => {
    setLoading(true);

    try {
      // Tries to login the user in case it already exists.
      await reqLogin({ email: '', password: '', googleTokenId: ssoData.googleTokenId }, companyId);
    } catch (e) {
      history.push({ search: '?status=fillingPartially' });
      setUser(u => ({ ...u, ...ssoData, password }));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      (companyStyles.live?.home && !companyStyles.live?.home?.language) ||
      companyStyles.live?.home?.language?.display !== 'block'
    ) {
      // TODO: A ugly solution to deal quiclky with a partner, see more: https://trello.com/c/jKFs857p
      // i18n.changeLanguage('pt'); <- Old code
      console.log('language', i18n.language);
    }
  }, [companyStyles.live?.home]);

  // If the Google's OAuth token has changed, it means the user has just
  // decided to use the SSO method/button to signup. Do notice that the
  // `googleTokenId` attribute is set by the `ssoCallback` method below - which
  // is called from `GoogleSSO` component.
  useEffect(() => {
    if (user.googleTokenId?.length && status === 'choosing') handleSignUp();
  }, [user.googleTokenId]);

  // This method submits the signup request in case the data retrieved from SSO
  // is enough (i.e., the SSU config doesn't ask for CPF, Whatsapp or has
  // multiple areas to choose from).
  // Otherwise, pre-fills the form with what was retrieved from SSO and gives
  // the user the control to finish filling and submitting.
  const ssoCallback = (ssoData: SSODataInterface) => {
    if (!selfSignUpCampaign) return;

    if (isProvidedDataEnough(selfSignUpCampaign)) {
      // Triggers data submission
      setUser(u => ({ ...u, ...ssoData, password }));
    } else {
      // Tries to login the user in case he is already signed-up. Otherwise
      // presents form for completion.
      loginOrCompleteForm(ssoData, selfSignUpCampaign.companyId);
    }
  };

  const handleSignUp = async (event?: React.ChangeEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!user) return;

    try {
      setLoading(true);
      await dispatch(selfSignUpService.create(hash, user));
      history.push('/home');
    } catch (e) {
      if (e instanceof Error) {
        try {
          const parse = JSON.parse(e.message);
          setError(parse);

          if (parse['users.base']) {
            setSnackBar({
              message: parse['users.base'],
              show: true,
              type: 'error',
            });
          }
        } catch {
          setSnackBar({
            message: e.message,
            show: true,
            type: 'error',
          });
        }
      }
    }
    setLoading(false);
  };

  const renderNotFound = () => {
    if (loading) return <Spinner />;

    return <NotFound />;
  };

  const renderSignUpMethods = (campaign: SelfSignUpCampaignInterface) => {
    return (
      <>
        <ImageFlagContainer
          style={{ position: 'absolute', display: window.innerWidth <= 650 ? 'none' : 'flex' }}
        >
          <LanguageSelector />
        </ImageFlagContainer>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            style={{ fontSize: 14, fontFamily: fonts.regular, marginBottom: 16, maxWidth: '480px' }}
            onClick={() => history.push({ search: '?status=fillingFully' })}
            loading={loading}
          >
            {t('registerWithForm')}
          </Button>

          {selfSignUpCampaign?.companyId &&
            !mustHideGoogle(selfSignUpCampaign?.companyId, escolaDePessoasProductionId) &&
            !mustHideGoogle(
              selfSignUpCampaign.companyId,
              continentalPneusId,
              continentalPneusHostName,
            ) && (
              <div style={{ marginBottom: 24 }}>
                <GoogleSSO text="signup_with" callback={ssoCallback} loading={loading} />
              </div>
            )}
          <div>
            <div style={{ color: colors.white, fontSize: 14 }}>{t('haveUser')}</div>
            <Link to="/" style={{ color: colors.orange, fontSize: 14 }}>
              {t('clickToAccess')}
            </Link>
          </div>
        </div>
        {passiveAcceptancesRenderer(campaign)}
      </>
    );
  };

  const renderSelfSignUp = (campaign: SelfSignUpCampaignInterface) => {
    return (
      <>
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: 30,
              margin: -32,
              minHeight: 32,
              marginBottom: 'auto',
              backgroundColor: colors.white,
              maxWidth: 'fit-content',
            }}
          >
            <img alt="" src={campaign.imageUrl} style={{ maxWidth: '100%', height: '150px' }} />
          </div>
          <div
            style={{ display: window.innerWidth > 650 ? 'none' : 'flex', justifyContent: 'right' }}
          >
            <LanguageSelector />
          </div>

          <h1 style={{ color: colors.white, fontSize: 16, marginTop: 8 }}>
            {campaign.companyName}
          </h1>
          <h1
            style={{
              marginBottom: 24,
              color: colors.white,
              fontSize: 14,
              opacity: 0.58,
            }}
          >
            {campaign.name}
          </h1>
        </div>

        {(status === 'choosing' && renderSignUpMethods(campaign)) || (
          <UserCampaignForm
            handleSubmission={handleSignUp}
            ignoreFields={status === 'fillingPartially' ? ['name', 'email', 'password'] : []}
            campaign={campaign}
            error={error}
            setUser={setUser}
            loading={loading}
            user={user}
            submitBtnText={t('finalizeRegistration')}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <Box>{(selfSignUpCampaign && renderSelfSignUp(selfSignUpCampaign)) || renderNotFound()}</Box>
    </Container>
  );
};

export default SelfSignUp;
