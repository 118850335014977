import React from 'react';
import { AwardManagerTypes } from '../../../shared/enums/awardManagerTypes';
import { colors } from '../../../shared/functions/colors';
import { dateTimeStampToStringDate } from '../../../shared/functions/date';
import { useCustomStyle } from '../../../shared/functions/hooks';
import { Award } from '../../../shared/modals/award/award';

import { Container, ContainerInfo, Title, Text } from './styles';
import { t } from '../../../i18n';

interface PBoxAwardAcquired {
  award: Award;
}

const BoxAwardAcquired = ({ award }: PBoxAwardAcquired) => {
  const { textColor, backgroundSecondary, primaryColor } = useCustomStyle();

  const getColorStatus = () => {
    switch (award.status) {
      case AwardManagerTypes.approved:
        return colors.green;
      case AwardManagerTypes.pending:
        return colors.orange;
      case AwardManagerTypes.cancelled:
        return colors.red;
      default:
        return '';
    }
  };

  const renderStatus = () => {
    switch (award.status) {
      case AwardManagerTypes.approved:
        return t('approved');
      case AwardManagerTypes.pending:
        return t('review');
      case AwardManagerTypes.cancelled:
      default:
        return t('rejected');
    }
  };

  return (
    <Container
      style={{
        borderLeft: `4px solid ${primaryColor.color || colors.purpleRegular}`,
        ...backgroundSecondary,
      }}
    >
      <ContainerInfo>
        <Title style={textColor}>{award.name}</Title>
        <Text style={{ color: textColor.color || colors.white, alignSelf: 'flex-start' }}>
          {award.dateAcquired &&
            t('purchaseDate', { dateAcquired: dateTimeStampToStringDate(award.dateAcquired) })}
        </Text>

        {award.productUrl.length > 0 && (
          <Text style={{ color: textColor.color || colors.white }}>
            <span>{t('accessLink')}</span>
            <a
              href={award.productUrl}
              target="_blank"
              style={{ color: textColor.color || colors.white }}
              rel="noreferrer"
            >
              {t('accessReward')}
            </a>
          </Text>
        )}
        <Text style={{ color: textColor.color || getColorStatus(), alignSelf: 'flex-start' }}>
          {`Status: ${renderStatus()}`}
        </Text>
      </ContainerInfo>
    </Container>
  );
};

export default BoxAwardAcquired;
