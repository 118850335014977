import * as React from 'react';
import { colors } from '../../functions/colors';

interface PCleanArrowLeft {
  width?: number;
  height?: number;
  color?: string;
}

const CleanArrowLeft = ({ width, height, color }: PCleanArrowLeft) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={color}>
    <title>clean-arrow-left</title>
    <mask
      id="clean-arrow-left"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="6"
      width="8"
      height="12"
    >
      <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill={color} />
    </mask>
    <g mask="url(#clean-arrow-left)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

CleanArrowLeft.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
};

export default CleanArrowLeft;
