import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../store/reducers/user/operations';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { setBlockScreen } from '../../store/reducers/geral/actions';

interface LoginCompanyState {
  token: string;
}

const LoginCompany = () => {
  const { token } = useParams<LoginCompanyState>();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const getInfoUser = async () => {
      try {
        dispatch(setBlockScreen(true));
        await getUserData({ token })(dispatch);
        dispatch(setBlockScreen(false));
        history.push(MenuUrl.home);
      } catch (_) {
        history.push('/');
      }
    };
    getInfoUser();
  }, []);

  return null;
};

export default LoginCompany;
